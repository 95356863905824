/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Input from "../../../../components/Input";
import {
  calculateLeavePerMonth,
  generateUniqueId,
  getDaysInYear,
  getFullName,
  isValidDays,
  isValidEncashDays,
  titleCase,
} from "../../../../utils";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl } from "@mui/base";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import useAuthentication from "../../../../hook/useAuthentication";
import URLS from "../../../../routes/urls";
import { useNavigate } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import BackgroundLetterAvatars from "../../../../components/BackgroundLetterAvatars";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import * as Muicon from "@mui/icons-material";
import ICONS from "../../../../constants/icons";
import EmptyPage from "../../../../components/EmptyPage";
import { styled } from "@mui/styles";

const LeaveTypeForm = ({
  work_type_settings,
  formData,
  setFormData,
  constantType,
  setErrors,
  errors,
  handleOpenChangeActivity,
  currentWorkTypeActivity,
  setAllActivity
}) => {
  const initialParams = {
    role: "all",
    page: 1,
    limit: 20
  };
  const findIndexWorkType = formData?.work_type_settings?.findIndex(
    (type) => type?.name === work_type_settings?.name
  );
  const workTypeData = formData?.work_type_settings[findIndexWorkType];
  const navigate = useNavigate();
  const { getCurrentUser } = useAuthentication();
  const currentUser = getCurrentUser();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [isRoleChanged, setIsRoleChanged] = useState(false);
  const [userListByRoleData, setUserListByRoleData] = useState([]);
  const [totalUsersCount, setTotalUsersCount] = useState({
    count: 0,
    isSet: false,
  });
  const [params, setParams] = useState(initialParams);
  const [isLoading, setIsLoading] = useState({
    users: false,
    isDelete: false
  })
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deletabelActivity, setDeletabelActivity] = useState({});

  useEffect(() => {
    getUserRolesData();
  }, []);

  useEffect(() => {
    if (workTypeData?.id) {
      getUserListByRole();
    }
  }, [params]);

  useEffect(() => {
    if (!drawerOpen) {
      setParams(initialParams);
    };
  }, [drawerOpen]);

  const getUserRolesData = async () => {
    try {
      const response = await axiosInstance.get(
        API.organisationUserRoles(currentUser?.organization_id)
      );
      if (response.status === 200) {
        setUserRoles(response?.data?.data);
      };
    } catch (error) {
      console.error(error);
    }
  };

  const getUserListByRole = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, users: true }));
      let urlEndpoint = `${API.organisationUsersListByRoles(currentUser?.organization_id, workTypeData?.id)}?page=${params.page}&limit=${params.limit}&filter=${params.role}`;
      const response = await axiosInstance.get(urlEndpoint);
      if (response.status === 200) {
        const uniqueById = (data) => Object.values(data.reduce((uniqueData, item) => ({
          ...uniqueData,
          [item.id]: item
        }), {}));
        if (!totalUsersCount?.isSet) {
          setTotalUsersCount({ count: response?.data?.totalCount, isSet: true });
        }
        setUserListByRoleData((prev) => isRoleChanged ? response?.data?.result : uniqueById([...prev, ...response?.data?.result]));
        setIsRoleChanged(false);
        setIsLoading((prev) => ({ ...prev, users: false }));
      }
      else {
        setUserListByRoleData([]);
        setIsRoleChanged(false);
        if (!totalUsersCount?.isSet) {
          setTotalUsersCount({ count: 0, isSet: true });
        }
        setIsLoading((prev) => ({ ...prev, users: false }));
      }
    } catch (error) {
      console.error(error);
      setUserListByRoleData([]);
      setIsRoleChanged(false);
      if (!totalUsersCount?.isSet) {
        setTotalUsersCount({ count: 0, isSet: true });
      }
      setIsLoading((prev) => ({ ...prev, users: false }));
    }
  };

  const toggleDrawer = (newOpen) => () => {
    setDrawerOpen(newOpen);
  };

  let maxDays = getDaysInYear();
  let minDays = 0;

  let initialLeaveTypeData = {
    id: null,
    leave_type_id: null,
    monthly_days: null,
    advance_days: null,
    max_leave_encashment: null,
    is_visible: true,
    unique_id: null,
  };

  const findIndexWithUid = (array, unique_id) => {
    return array.findIndex((data) => data?.unique_id === unique_id);
  };

  const validateLeaveTypes = (event, leaveIndex, unique_id) => {
    const { name, value } = event.target;
    let updatedErrors = [...errors];
    let existingObject = updatedErrors[findIndexWorkType][
      "leave_type_settings"
    ]?.find((value) => value?.unique_id === unique_id);
    if (!existingObject) {
      updatedErrors[findIndexWorkType]["leave_type_settings"].push({
        ...initialLeaveTypeData,
        unique_id,
      });
    }
    if (
      name === "monthly_days" &&
      formData?.leave_frequency === "monthly"
    ) {
      updatedErrors[findIndexWorkType]["leave_type_settings"][leaveIndex][
        name
      ] = !Boolean(parseInt(value))
          ? "Required"
          : !isValidDays(parseInt(value))
            ? "Please enter valid days which is divisible by 12 or 6"
            : "";
    } else if (
      name === "monthly_days" &&
      formData?.leave_frequency !== "monthly"
    ) {
      updatedErrors[findIndexWorkType]["leave_type_settings"][leaveIndex][
        name
      ] = (!Boolean(parseInt(value)) && parseInt(value) !== 0) ? "Required" : "";
    } else if (name === "max_leave_encashment") {
      updatedErrors[findIndexWorkType]["leave_type_settings"][leaveIndex][
        name
      ] = (!Boolean(parseInt(value)) && parseInt(value) !== 0)
          ? "Required"
          : !isValidEncashDays(
            formData,
            findIndexWorkType,
            parseInt(value),
            unique_id
          )
            ? "Please enter valid encash days which is less than or equal to yearly leave"
            : "";
    } else {
      updatedErrors[findIndexWorkType]["leave_type_settings"][leaveIndex][
        name
      ] = (!Boolean(parseInt(value)) && parseInt(value) !== 0) ? "Required" : "";
    }
    setErrors(updatedErrors);
  };

  const handleChangeLeaveTypes = (event, leaveIndex, unique_id) => {
    validateLeaveTypes(event, leaveIndex, unique_id);

    const { name, value } = event.target;
    const leaveTypeSettings = workTypeData?.leave_type_settings;
    leaveTypeSettings[leaveIndex] = {
      ...leaveTypeSettings[leaveIndex],
      [name]: value,
      is_changed: true,
    };

    if (
      value !== "" &&
      leaveTypeSettings.length < constantType?.leaveTypes?.length &&
      leaveIndex === leaveTypeSettings.length - 1
    ) {
      leaveTypeSettings.push({
        ...initialLeaveTypeData,
        unique_id: generateUniqueId(),
        is_visible: true,
        is_changed: false
      });
    }

    let updatedFormData = { ...formData };
    updatedFormData["work_type_settings"][findIndexWorkType][
      "leave_type_settings"
    ] = leaveTypeSettings;
    setFormData(updatedFormData);
  };

  const handleChangeSpecialRequest = (event, requestIndex) => {
    const { name, value: Value } = event.target;
    let nextIndex = requestIndex + 1;

    setFormData((prev) => {
      const updatedRequestType = [...prev.work_type_settings];
      let lengthOfLeaveTypeArray = updatedRequestType[findIndexWorkType].special_requests?.filter((value) => !!value?.is_visible)?.length;
      updatedRequestType[findIndexWorkType].special_requests[requestIndex] = {
        ...updatedRequestType[findIndexWorkType].special_requests[requestIndex],
        [name]: Value,
        is_changed: true,
        is_initial: false
      };

      if (nextIndex < lengthOfLeaveTypeArray) {
        updatedRequestType[findIndexWorkType].special_requests[nextIndex] = {
          ...updatedRequestType[findIndexWorkType].special_requests[nextIndex],
          is_visible: true,
          is_changed: false,
        };
      }

      if (lengthOfLeaveTypeArray < constantType?.specialRequestTypes?.length) {
        let lastData = updatedRequestType[findIndexWorkType]?.special_requests[updatedRequestType[findIndexWorkType]?.special_requests?.length - 1];
        if (!!lastData?.allotment && !!lastData?.name) {
          updatedRequestType[findIndexWorkType]?.special_requests?.push({
            id: null,
            name: '',
            allotment: "",
            is_visible: true,
            unique_id: generateUniqueId(),
            is_initial: true,
            is_changed: false,
          })
        }
      }

      return {
        ...prev,
        work_type_settings: updatedRequestType,
      };
    });
    validateSpacialRequest(event, requestIndex);
  };

  const validateSpacialRequest = (event, requestIndex) => {
    const { name, value } = event.target;
    let updatedErrors = [...errors];
    let isExists = updatedErrors[findIndexWorkType]["special_requests"][requestIndex];
    if (!!isExists) {
      updatedErrors[findIndexWorkType]["special_requests"][requestIndex][name] = !value ? name === "allotment" && value === 0 ? "" : "Required" : "";
    }
    setErrors(updatedErrors);
  }

  const handleDeleteChangeRequestTypes = (unique_id) => {
    let copyOfFormData = { ...formData };
    let specialRequests = [...copyOfFormData?.work_type_settings[findIndexWorkType]?.special_requests];
    let updatedSpecialRequests = specialRequests?.map((value, index) => {
      if (value?.unique_id === unique_id) {
        if (!!value?.id) {
          return {
            ...value,
            is_deleted: true,
            is_visible: false
          }
        } else {
          return null;
        }
      }
      else {
        return value;
      }
    });
    let filteredData = updatedSpecialRequests?.filter(value => !!value);
    let deletedList = filteredData?.filter(value => value?.id && value?.is_deleted);
    let lastEle = filteredData[filteredData?.length - 1];
    if ((filteredData?.length - deletedList?.length) < constantType?.specialRequestTypes?.length && lastEle?.name !== "" && lastEle?.allotment !== null) {
      let initialData = {
        name: null,
        allotment: null,
        is_visible: true,
        is_changed: false,
        id: null,
        is_initial: true,
        unique_id: generateUniqueId(),
      };
      filteredData.push(initialData);
    }
    let updatedWorkTypes = formData?.work_type_settings?.map((value, index) => {
      if (index === findIndexWorkType) {
        return {
          ...value,
          special_requests: filteredData,
        };
      }
      else {
        return value;
      }
    });
    setFormData((prev) => ({ ...prev, work_type_settings: updatedWorkTypes }));
  };

  const handleDeleteChangeLeaveTypes = (unique_id) => {
    setFormData((prev) => {
      const updatedWorkTypes = prev.work_type_settings.map(
        (workType, index) => {
          if (index === findIndexWorkType) {
            const updatedLeaveTypes = workType.leave_type_settings?.filter(
              (value) => value?.unique_id !== unique_id
            );
            let lastEle = updatedLeaveTypes[updatedLeaveTypes?.length - 1];
            if (updatedLeaveTypes?.length < constantType?.leaveTypes?.length && lastEle?.leave_type_id !== null && lastEle?.advance_days !== null && lastEle?.max_leave_encashment !== null && lastEle?.monthly_days !== null) {
              let initialData = {
                advance_days: null,
                id: null,
                is_changed: false,
                is_visible: true,
                leave_type_id: null,
                max_leave_encashment: null,
                monthly_days: null,
                unique_id: generateUniqueId(),
                is_initial: true,
              };
              updatedLeaveTypes.push(initialData);
            }
            let filteredData = updatedLeaveTypes?.filter(value => !!value);
            return { ...workType, leave_type_settings: filteredData };
          }
          return workType;
        }
      );

      return { ...prev, work_type_settings: updatedWorkTypes };
    });
  };

  const handleBlurWorkTypeSetting = (event) => {
    let updatedErrors = [...errors];
    const { name, value } = event.target;
    updatedErrors[findIndexWorkType][name] = !!value ? "" : "Required";
    setErrors(updatedErrors);
  };

  const handleChangeWorkTypeSetting = (event) => {
    const { name, value } = event.target;
    const updateFormDataValue = (prev) => {
      const findIndex = prev.work_type_settings.findIndex(
        (type) => type?.name === work_type_settings?.name
      );

      const updatedWorkTypes = [...prev.work_type_settings];
      updatedWorkTypes[findIndex] = {
        ...updatedWorkTypes[findIndex],
        [name]: parseInt(value),
      };

      return { ...prev, work_type_settings: updatedWorkTypes };
    };

    let updatedErrors = [...errors];
    let data = updateFormDataValue(formData);
    updatedErrors[findIndexWorkType][name] = (!!value || value === 0) ? "" : "Required";
    setErrors(updatedErrors);
    setFormData(data);
  };

  let totalLeaves = workTypeData?.leave_type_settings?.reduce(
    (accumulator, currentValue) =>
      parseInt(accumulator) + parseInt(currentValue?.monthly_days || 0),
    0
  );

  const DrawerList = (
    <Box sx={{ width: 250 }} display={"flex"} flexDirection={"column"} overflow={"hidden"} role="presentation">
      <Grid Item p={1.5} display="flex" justifyContent="space-between" alignItems="center" sx={{ top: '0', zIndex: '1' }}>
        <Grid display={"flex"} flexDirection={"row"}>
          <Typography color="secondary" fontSize={15} fontWeight={600} sx={{ lineHeight: '22px', mr: '10px' }}>Users</Typography>
          <Typography color="secondary" bgcolor="dark.100" fontSize={12} fontWeight={600} sx={{ lineHeight: '22px', padding: '0px 5px', borderRadius: '5px' }}>{userListByRoleData?.length || 0}</Typography>
        </Grid>
        <Grid>
          <IconButton
            aria-label="delete"
            size="small"
            className="edit-btn"
            onClick={toggleDrawer(false)}

          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid Item py={1} px={1.5} sx={{ 'div': { width: '100%' } }} position="sticky" borderBottom="1px solid #E2E4EC">
        <FormControl variant="standard" sx={{ width: 100 }}>
          <Input
            value={params?.role}
            onChange={(event) => {
              setParams(prev => ({
                ...prev,
                role: event.target.value
              }))
              setIsRoleChanged(true);
            }}
            name="Role"
            id="Role"
            sx={{ width: "100%" }}
            type="select"
            select
            label="Role"
            variant="standard"
          >
            <MenuItem value="all">
              All
            </MenuItem>
            {userRoles?.map((role, index) => (
              <MenuItem key={index} value={role?.name}>
                {titleCase(role?.name)}
              </MenuItem>
            ))}
          </Input>
        </FormControl>
      </Grid>
      <Box sx={{ height: "100%", visibility: "visible" }} flex={1} overflow={"auto"}>
        <Stack spacing={3}>
          <div>
            <InfiniteScroll
              dataLength={userListByRoleData?.length || 0}
              next={getUserListByRole}
              hasMore={true}
              loader={isLoading?.users ? <h4>Loading...</h4> : null}
            >
              {userListByRoleData?.length > 0 ?
                userListByRoleData?.map((userData, index) => (
                  <List sx={{ p: 0 }} key={index}>
                    <ListItem sx={{
                      padding: '8px 12px',
                      '&:hover': { background: 'rgba(0,0,0,0.04)' },
                      '&:hover .MuiIconButton-root': {
                        opacity: '1',
                      }
                    }}>
                      <BackgroundLetterAvatars
                        user={userData}
                        sx={{ width: 30, height: 30, mr: 1, fontSize: "16px", }}
                      />
                      <Typography color="secondary" fontSize={14} fontWeight={500} sx={{ lineHeight: '22px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} flex={1}>{getFullName(userData)}</Typography>
                      <Tooltip arrow title="Leave Settings" placement="left" bgcolor="secondary" sx={{ '.MuiTooltip-tooltip': { background: 'rgba(63, 92, 118, 0.9)' } }}>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          color="secondary"
                          sx={{ opacity: '0' }}
                          onClick={() => navigate(`${URLS.UserDetails}/${userData?.id}?tabValue=2`)}
                        >
                          <SettingsIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                  </List>)) :
                <>
                  {!isLoading?.users ? <Box height="500px">
                    <EmptyPage isButtonShow={false} title="No users found" />
                  </Box> : null}
                </>}
            </InfiniteScroll>
          </div>
        </Stack>
      </Box>
    </Box>
  );

  const handleDeleteActivity = (activityData) => {
    setIsOpenDelete(true);
    setDeletabelActivity(activityData);
  }

  const handleSubmitDeleteActivity = async (deletabelActivityData, workTypeId) => {
    try {
      setIsLoading((prev) => ({ ...prev, isDelete: true }));
      let updatedActivityData = { ...deletabelActivityData };
      updatedActivityData['org_work_type_ids'] = deletabelActivityData['org_work_type_ids']?.filter((id) => id !== workTypeId)
      let response = await axiosInstance.put(API.updateActivity, updatedActivityData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        setFormData((prev) => ({
          ...prev,
          work_type_settings: prev?.work_type_settings?.map((data) => {
            if (data?.id === workTypeId) {
              return { ...data, 'activities': data?.activities?.filter((activity) => activity?.id !== deletabelActivityData?.id) }
            }
            return data;
          })
        }));
        setAllActivity((prev) => {
          return prev?.map((data) => {
            if (data?.id === deletabelActivityData?.id) {
              return { ...data, 'org_work_type_ids': data?.org_work_type_ids?.filter((typeIds) => typeIds !== workTypeId) }
            }
            return data;
          })
        });
      } else {
        toast.error(response?.data?.message);
      }
      setIsLoading((prev) => ({ ...prev, isDelete: false }))
      setIsOpenDelete(false);
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, isDelete: false }))
    }
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false);
    setDeletabelActivity({});
  }


  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#3F5C76',
      color: '#ffffff',
      maxWidth: 310,
      padding: 12,
    },
  }));

  const ActionCard = ({ icon, text, disabled, isAdd = false, activityData }) => {
    const Icon = Muicon?.[icon];
    return (
      <Card sx={{
        boxShadow: "none !important",
        width: 155,
        height: 120,
        position: "relative",
        border: '1px solid #E0E0E0'
      }}>
        {isAdd ?
          <CardActionArea sx={{
            width: '100%', height: '100%', textAlign: "center", padding: '0px 20px 0px 20px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
            '&:hover': {
              background: 'rgba(63, 92, 118, 0.1)',
            }
          }} disabled={disabled}>
            <>
              <i
                style={{
                  height: "48px",
                  width: "48px",
                  display: "flex",
                  alignItems: 'center',
                  justifyContent: 'center',
                  'svg': {
                    maxWidth: '24px',
                    flex: '24px',
                    height: '24px',
                  }
                }}>{ICONS.AddIcon}</i>
              <Typography color={"secondary"} fontSize={14} fontWeight={600} lineHeight={"21px"} letterSpacing={"0.15px"} mt={1.5}>Add</Typography>
            </>
          </CardActionArea> :
          <Box sx={{
            width: '100%', height: '100%', textAlign: "center", padding: '0px 20px 0px 20px', cursor: 'default', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
            '&:hover': {
              background: 'rgba(63, 92, 118, 0.1)',
              '.delete-btn': {
                opacity: 1,
                visibility: 'visible'
              },
            },
          }} disabled={disabled}>
            {Icon ?
              <Box
                sx={{
                  height: "48px",
                  width: "48px",
                  display: "flex",
                  alignItems: 'center',
                  justifyContent: 'center',
                  'svg': {
                    maxWidth: '30px',
                    flex: '30px',
                    height: '30px',
                    padding: 0,
                  }
                }}>
                <Icon color={"secondary"} sx={{ pb: 1, fontSize: 30 }} /> </Box> : null}
            {/* <i
                style={{
                  height: "48px",
                  width: "48px",
                  display: "flex",
                  alignItems: 'center',
                  justifyContent: 'center',
                  'svg':{
                    maxWidth: '24px',
                    flex: '24px',
                    height: '24px',
                  }
                }}>{ICONS.LeaveChairIcon}</i> */}
            <Typography color={"secondary"} fontSize={14} fontWeight={600} lineHeight={"21px"} letterSpacing={"0.15px"} mt={1.5}>{titleCase(text)}</Typography>
            <IconButton
              aria-label="delete"
              size="small"
              color="secondary"
              className="delete-btn"
              sx={{
                position: "absolute",
                zIndex: 2,
                top: "5px",
                right: "5px",
                transition: "all 0.25s ease-in-out",
                opacity: 0,
                visibility: "hidden",
              }}
              onClick={() =>
                handleDeleteActivity(activityData)
              }
            >
              <DeleteIcon color="secondary.main" fontSize="small" sx={{ color: 'secondary.main' }} />
            </IconButton>
          </Box>
        }

      </Card>
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          color="#014361"
          fontWeight={500}
          sx={{
            textTransform: "capitalize",
            lineHeight: "31px",
            letterSpacing: "0.15px",
          }}
          fontSize={21}
          mr={0.5}
        >
          {totalUsersCount?.count || 0} Users
        </Typography>
        <Typography
          variant="body1"
          color="#014361"
          fontSize={16}
          fontWeight={500}
          display="inline-block"
          sx={{ lineHeight: "24px", letterSpacing: "0.15px" }}
        >
          are associated to this work type.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }} mb={4}>
        <Typography color="primary" mr={0.5} borderBottom="1px solid" borderColor="primary" onClick={toggleDrawer(true)} sx={{ cursor: 'pointer', '&:hover': { opacity: '0.75' } }}>Click here</Typography>
        <Drawer open={drawerOpen} anchor='right' onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
        <Typography
          variant="body1"
          color="#014361"
          fontSize={14}
          fontWeight={400}
          sx={{ lineHeight: "20px", letterSpacing: "0.17px" }}
        >
          to see all the users
        </Typography>
      </Box>
      <Typography color="dark.800" fontSize={20} mb={1}>
        Leave Types
      </Typography>
      <Box p={3} bgcolor={"#F8F8F8"} mb={2}>
        <Stack spacing={4}>
          {workTypeData?.leave_type_settings?.map(
            (leaveType, parentIndex, leaveArray) => {
              let leaveIndex = findIndexWithUid(
                leaveArray,
                leaveType?.unique_id
              );
              return (
                <React.Fragment key={parentIndex}>
                  {leaveType?.is_visible ? (
                    <Grid container>
                      <Grid Item display={"flex"} flexWrap={{ xs: 'wrap', md: 'nowrap' }} flex={1}>
                        <Grid Item pr={3} mb={{ xs: 3, md: 0 }} flex={{ xs: '0 0 50%', md: 1 }}>
                          <Input
                            id="name-type"
                            select
                            type="select"
                            label="Select Type"
                            name="leave_type_id"
                            variant="standard"
                            sx={{ width: "100%" }}
                            onChange={(e) =>
                              handleChangeLeaveTypes(
                                e,
                                leaveIndex,
                                leaveType?.unique_id
                              )
                            }
                            onBlur={(e) =>
                              validateLeaveTypes(
                                e,
                                leaveIndex,
                                leaveType?.unique_id
                              )
                            }
                            value={leaveType?.leave_type_id}
                            error={
                              !!errors[findIndexWorkType]["leave_type_settings"][
                                parentIndex
                              ]?.leave_type_id
                            }
                            helperText={
                              errors[findIndexWorkType]["leave_type_settings"][
                                parentIndex
                              ]?.leave_type_id
                            }
                            required={
                              leaveType?.leave_type_id ||
                              leaveType?.advance_days ||
                              leaveType?.monthly_days ||
                              leaveType?.max_leave_encashment
                            }
                          >
                            {constantType?.leaveTypes?.map(
                              ({ id, name }, index) => (
                                <MenuItem
                                  key={index}
                                  value={id}
                                  disabled={leaveArray?.some(
                                    (data) => data?.leave_type_id === id
                                  )}
                                >
                                  {name}
                                </MenuItem>
                              )
                            )}
                          </Input>
                        </Grid>
                        {!!leaveType?.leave_type_id || parentIndex === 0 ?
                          <>
                            <Grid Item pr={3} mb={{ xs: 3, md: 0 }} flex={{ xs: '0 0 50%', md: 1 }}
                              sx={{
                                '.MuiTypography-body1': {
                                  fontSize: '12px',
                                  lineHeight: '19px',
                                  marginTop: '3px',
                                  color: 'dark.800'
                                }
                              }}
                            >
                              <Input
                                id="yearly-leave"
                                label={`Yearly Leave (Days)`}
                                type="number"
                                inputProps={{ min: minDays, max: maxDays }}
                                min={minDays}
                                max={maxDays}
                                isDays={true}
                                variant="standard"
                                name="monthly_days"
                                sx={{ width: "100%" }}
                                onChange={(e) =>
                                  handleChangeLeaveTypes(
                                    e,
                                    leaveIndex,
                                    leaveType?.unique_id
                                  )
                                }
                                onBlur={(e) =>
                                  validateLeaveTypes(
                                    e,
                                    leaveIndex,
                                    leaveType?.unique_id
                                  )
                                }
                                InputLabelProps={{ shrink: Boolean(leaveType?.monthly_days) || leaveType?.monthly_days === 0 }}
                                value={Boolean(leaveType?.is_initial) && !Boolean(leaveType?.monthly_days) ? "" : leaveType?.monthly_days}
                                error={
                                  !!errors[findIndexWorkType]["leave_type_settings"][
                                    parentIndex
                                  ]?.monthly_days
                                }
                                helperText={
                                  errors[findIndexWorkType]["leave_type_settings"][
                                    parentIndex
                                  ]?.monthly_days
                                }
                                required={
                                  leaveType?.leave_type_id ||
                                  leaveType?.advance_days ||
                                  leaveType?.monthly_days ||
                                  leaveType?.max_leave_encashment
                                }
                              />
                              {leaveType?.monthly_days > 0 &&
                                formData?.leave_frequency === "monthly" ? (
                                <Typography>
                                  ={calculateLeavePerMonth(leaveType?.monthly_days)}{" "}
                                  Leaves / Month
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid Item pr={3} mb={{ xs: 3, md: 0 }} flex={{ xs: '0 0 50%', md: 1 }}>
                              <Input
                                id="notice-period"
                                label="Notice Period (Days)"
                                type="number"
                                inputProps={{ min: minDays, max: maxDays }}
                                min={minDays}
                                max={maxDays}
                                isDays={true}
                                variant="standard"
                                sx={{ width: "100%" }}
                                name="advance_days"
                                onChange={(e) =>
                                  handleChangeLeaveTypes(
                                    e,
                                    leaveIndex,
                                    leaveType?.unique_id
                                  )
                                }
                                onBlur={(e) =>
                                  validateLeaveTypes(
                                    e,
                                    leaveIndex,
                                    leaveType?.unique_id
                                  )
                                }
                                InputLabelProps={{ shrink: Boolean(leaveType?.advance_days) || leaveType?.advance_days === 0 }}
                                value={Boolean(leaveType?.is_initial) && !Boolean(leaveType?.advance_days) ? "" : leaveType?.advance_days}
                                error={
                                  !!errors[findIndexWorkType]["leave_type_settings"][
                                    parentIndex
                                  ]?.advance_days
                                }
                                helperText={
                                  errors[findIndexWorkType]["leave_type_settings"][
                                    parentIndex
                                  ]?.advance_days
                                }
                                required={
                                  leaveType?.leave_type_id ||
                                  leaveType?.advance_days ||
                                  leaveType?.monthly_days ||
                                  leaveType?.max_leave_encashment
                                }
                              />
                            </Grid>
                            <Grid Item pr={3} mb={{ xs: 3, md: 0 }} flex={{ xs: '0 0 50%', md: 1 }}>
                              <Input
                                id="encash"
                                label="Max. Encash (Days)"
                                type="number"
                                inputProps={{ min: minDays, max: maxDays }}
                                min={minDays}
                                max={maxDays}
                                isDays={true}
                                variant="standard"
                                name="max_leave_encashment"
                                sx={{ width: "100%" }}
                                onChange={(e) =>
                                  handleChangeLeaveTypes(
                                    e,
                                    leaveIndex,
                                    leaveType?.unique_id
                                  )
                                }
                                onBlur={(e) =>
                                  validateLeaveTypes(
                                    e,
                                    leaveIndex,
                                    leaveType?.unique_id
                                  )
                                }
                                InputLabelProps={{ shrink: Boolean(leaveType?.max_leave_encashment) || leaveType?.max_leave_encashment === 0 }}
                                value={Boolean(leaveType?.is_initial) && !Boolean(leaveType?.max_leave_encashment) ? "" : leaveType?.max_leave_encashment}
                                error={
                                  !!errors[findIndexWorkType]["leave_type_settings"][
                                    parentIndex
                                  ]?.max_leave_encashment
                                }
                                helperText={
                                  errors[findIndexWorkType]["leave_type_settings"][
                                    parentIndex
                                  ]?.max_leave_encashment
                                }
                                required={
                                  leaveType?.leave_type_id ||
                                  leaveType?.advance_days ||
                                  leaveType?.monthly_days ||
                                  leaveType?.max_leave_encashment
                                }
                              />
                            </Grid>
                          </>
                          :
                          <>
                            <Grid Item pr={3} mb={{ xs: 3, md: 0 }} flex={{ xs: '0 0 50%', md: 1 }}></Grid>
                            <Grid Item pr={3} mb={{ xs: 3, md: 0 }} flex={{ xs: '0 0 50%', md: 1 }}></Grid>
                            <Grid Item pr={3} mb={{ xs: 3, md: 0 }} flex={{ xs: '0 0 50%', md: 1 }}></Grid>
                          </>}
                      </Grid>
                      <Grid Grid Item mt={1} minWidth={40}>
                        {leaveType?.leave_type_id ?
                          <Box sx={{ minWidth: "40px" }}>
                            {leaveArray?.filter((data) => data?.is_visible)
                              ?.length > 1 ? (
                              <IconButton
                                size="small"
                                sx={{ width: "40px", height: "40px" }}
                                onClick={() =>
                                  handleDeleteChangeLeaveTypes(
                                    leaveType?.unique_id
                                  )
                                }
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                          </Box> : null}
                      </Grid>
                    </Grid>
                  ) : null}
                </React.Fragment>
              );
            }
          )}
        </Stack>
      </Box>
      {Boolean(totalLeaves) ? (
        <Box>
          <Typography color="dark.800" fontSize={20}>
            Total Yearly Paid Leaves <b>{totalLeaves}</b>
          </Typography>
          <Typography color="dark.600" fontSize={12} mb={3}>
            Above Total Yearly Paid Leaves are the sum of all the Yearly leaves
            for all leave types.
          </Typography>
        </Box>
      ) : null}
      <Grid container mb={4}>
        <Grid item mr={4} width={565}>
          <Input
            id="standard-helperText"
            label="Monthly Max Leaves for Dedicated Developer (Days)"
            variant="standard"
            name="max_monthly_leave_for_dd"
            sx={{ width: "100%" }}
            type="number"
            inputProps={{ min: minDays, max: maxDays }}
            min={minDays}
            max={maxDays}
            isDays={true}
            onChange={handleChangeWorkTypeSetting}
            onBlur={handleBlurWorkTypeSetting}
            value={workTypeData?.max_monthly_leave_for_dd}
            error={!!errors[findIndexWorkType]["max_monthly_leave_for_dd"]}
            helperText={errors[findIndexWorkType]["max_monthly_leave_for_dd"]}
          />
          <Box>
            <HtmlTooltip
              arrow
              title={
                <React.Fragment>
                  <Typography sx={{ fontSize: '13px !important' }} textAlign={"center"}>Individuals working on project in dedicated mode needs higher avalability to work</Typography>
                </React.Fragment>
              }
            >
              <Typography
                display="inline-block"
                color="primary"
                fontSize={12}
                sx={{ cursor: "pointer" }}
              >
                Help
              </Typography>
            </HtmlTooltip>
          </Box>
        </Grid>
      </Grid>
      {!!formData?.allow_special_credits ? (
        <>
          <Typography color="dark.800" fontSize={20} mb={1}>
            Special Credits
          </Typography>
          <Box p={3} bgcolor={"#F8F8F8"}>
            <Stack spacing={4}>
              {workTypeData?.special_requests?.map(
                (requestData, requestIndex, requestArray) => (
                  <React.Fragment key={requestIndex}>
                    {requestData?.is_visible && !requestData?.is_deleted ? (
                      <Grid container >
                        <Grid Item mr={3} flex={1} sx={{ maxWidth: "325px" }}>
                          <Input
                            id="request"
                            select
                            type="select"
                            label="Request"
                            variant="standard"
                            name="name"
                            sx={{ width: "100%" }}
                            value={Boolean(requestData?.is_initial) && !Boolean(requestData?.name) ? "" : requestData?.name}
                            onChange={(e) => handleChangeSpecialRequest(e, requestIndex)}
                            onBlur={(e) => validateSpacialRequest(e, requestIndex)}
                            // disabled={Boolean(requestData?.name)}
                            error={
                              !!errors[findIndexWorkType]?.special_requests[
                                requestIndex
                              ]?.name
                            }
                            helperText={
                              errors[findIndexWorkType]?.special_requests[
                                requestIndex
                              ]?.name
                            }
                          >
                            {constantType?.specialRequestTypes?.map(
                              ({ name, is_visible }, index) => (
                                <MenuItem
                                  key={index}
                                  value={name}
                                  disabled={requestArray?.some(
                                    (data) => data?.name === name && data?.is_visible
                                  )}
                                  sx={{
                                    "&:hover": {
                                      background: "rgba(4, 127, 224, 0.1)",
                                    },
                                  }}
                                >
                                  {titleCase(name)}
                                </MenuItem>
                              )
                            )}
                          </Input>
                        </Grid>
                        <Grid Item mr={3} flex={130} maxWidth={130}>
                          {!!requestData?.name || requestIndex === 0 ?
                            <Input
                              id="yearly-leave"
                              label="Credit(s)"
                              variant="standard"
                              name="allotment"
                              sx={{ width: "100%" }}
                              onChange={(e) => handleChangeSpecialRequest(e, requestIndex)}
                              onBlur={(e) => validateSpacialRequest(e, requestIndex)}
                              value={Boolean(requestData?.is_initial) && !Boolean(requestData?.allotment) ? "" : requestData?.allotment}
                              type="number"
                              error={
                                !!errors[findIndexWorkType]?.special_requests[
                                  requestIndex
                                ]?.allotment
                              }
                              helperText={
                                errors[findIndexWorkType]?.special_requests[
                                  requestIndex
                                ]?.allotment
                              }
                              disabled={!formData?.allow_special_credits}
                            /> : null}
                        </Grid>
                        {(constantType?.specialRequestTypes?.length === requestArray?.length || requestArray?.length - 1 !== requestIndex) ? <Grid Item mt={1}>
                          <Box sx={{ minWidth: "40px" }}>
                            {requestArray?.filter((data) => data?.is_visible)
                              ?.length > 1 ? (
                              <IconButton
                                size="small"
                                sx={{ width: "40px", height: "40px" }}
                                onClick={() =>
                                  handleDeleteChangeRequestTypes(requestData?.unique_id)
                                }
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                          </Box>
                        </Grid> : null}
                      </Grid>
                    ) : null}
                  </React.Fragment>
                )
              )}
            </Stack>
          </Box>
        </>
      ) : null}
      <Typography color="dark.800" fontSize={20} mt={3} mb={1}>
        Activity
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={3} mb={1} p={3} bgcolor={"#F8F8F8"}>
        {
          <>
            {currentWorkTypeActivity?.length ?
              currentWorkTypeActivity?.map((activityData, index) => (
                <Box key={index}>
                  <ActionCard icon={activityData?.activity_logo} text={activityData?.title} activityData={activityData} />
                </Box>
              ))
              : null}
            <Box onClick={() => handleOpenChangeActivity()} >
              <ActionCard icon={''} text={''} isAdd={true} activityData={''} />
            </Box>
          </>
        }
      </Box>
      <ConfirmationModal
        isOpen={isOpenDelete}
        title={'Activity'}
        deletableDataName={titleCase(deletabelActivity?.title)}
        dataContentName={'Activity'}
        handleClose={() => setIsOpenDelete(false)}
        onConfirm={() => handleSubmitDeleteActivity(deletabelActivity, workTypeData?.id)}
        onCancel={() => handleCloseDelete()}
        isButtonLoading={isLoading?.isDelete}
        subList={<span>This will remove <b>{titleCase(deletabelActivity?.title)}</b> from your organization's <b>{titleCase(workTypeData?.name)}</b> activity list.</span>}
      />
    </>
  );
};

export default LeaveTypeForm;
