import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  tooltipClasses,
  Menu,
  MenuItem,
  Box,
  Chip,
  TableHead,
} from "@mui/material";
import ICONS from "../../../../../constants/icons";
import { styled } from "@mui/styles";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { getApplicationNumber, getApplyDateTime, getDayName, getFirstLetterOfString, getFormattedDate, getLeaveDateArray, getLeaveDates, getLeaveStatus, sortArrayByKey, titleCase } from "../../../../../utils";
import EmptyPage from "../../../../../components/EmptyPage";
import { useNavigate } from "react-router-dom";
import URLS from "../../../../../routes/urls";
import { LEAVE_STATUS } from "../../../../../constants/default-values";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "16px",
    borderRadius: "5px",
    background: "#3F5C76",
    maxWidth: "500px"
  },
}));

const LeaveListTable = ({ leaveDataList, leaveStatus, handleCancelLeaveModal, handleDeleteLeaveModal, handleViewLeave, handleEditLeave, handleClose, handleClick, anchorEl, openElem, open, openAccordianList, setOpenAccordianList, optionalLeaveData }) => {
  const navigate = useNavigate();
  function calculateLeaveCount(leaveDates) {
    let totalCount = 0;

    leaveDates.forEach((leave) => {
      if (leave.leave_duration === "full") {
        totalCount += 1;
      } else if (
        leave.leave_duration === "first_half" ||
        leave.leave_duration === "second_half"
      ) {
        totalCount += 0.5;
      }
    });

    return totalCount;
  }
  function calculateTotalCredits(leaveDates) {
    let totalCredits = 0;

    leaveDates.forEach((leave) => {
      totalCredits += leave.credit_used || 0;
    });

    return totalCredits;
  }

  const hasAttachmentInList = () => {
    if (optionalLeaveData?.length) {
      return optionalLeaveData?.some(leave => leave?.attachment_references > 0);
    }
  }

  const checkApplicationEnable = (leaveData) => {
    const minLeaveDate = leaveData?.leave_days.reduce((min, leave) => {
      const leaveDate = new Date(leave.leave_date);
      return leaveDate < min ? leaveDate : min;
    }, new Date(leaveData?.leave_days[0].leave_date));

    const today = new Date();
    // today.setHours(0, 0, 0, 0);

    const result = today <= minLeaveDate;
    return result && [LEAVE_STATUS.Approved, LEAVE_STATUS.Rejected, LEAVE_STATUS.Pending]?.includes(leaveData?.status);
  }

  return (
    <Grid container>
      <Grid Item sx={{ width: "100%" }}>
        {!!leaveDataList?.length || Object?.keys(leaveDataList)?.length
          ? Object?.entries(leaveDataList)?.map((leaveData, index) => (
            <Accordion
              expanded={openAccordianList?.includes(leaveData[0])}
              sx={{
                width: "100%",
                background: "#EBEFF3",
                border: "1px solid rgba(0, 0, 0, 0.08)",
                boxShadow: "none",
                borderRadius: "5px",
                margin: "8px 0px 0px 0px !important",
                "&:first-child": {
                  margin: "0px !important",
                },
                "&:before": {
                  display: "none",
                },
                ".MuiAccordionSummary-root": {
                  minHeight: "42px",
                  flexDirection: "row-reverse",
                },
                ".MuiAccordionSummary-root.Mui-expanded": {
                  minHeight: "42px !important",
                },
                ".MuiAccordionSummary-content": {
                  margin: "13px 0px 13px 8px",
                },
                ".MuiAccordionSummary-content.Mui-expanded": {
                  margin: "8px 0px 0px 8px",
                },
                ".MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  marginTop: "8px",
                },
                ".MuiAccordionDetails-root": {
                  paddingTop: "0px",
                },
                ".MuiAccordion-region> div": {
                  paddingBottom: "8px",
                },
                '& .MuiAccordionSummary-expandIconWrapper': {
                  transform: 'rotate(-90deg)',
                },
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(0deg)',
                }
              }}
              elevation={0}
              key={index}
            >
              <AccordionSummary
                expandIcon={
                  <i
                    style={{
                      height: "20px",
                      width: "20px",
                      '& .MuiAccordionSummary-expandIconWrapper': {
                        transform: 'rotate(-90deg)',
                      },
                      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: 'rotate(0deg)',
                      }
                    }}
                  >
                    {ICONS.ChevronLineSmall}
                  </i>
                }
                aria-controls="panel1-content"
                id="panel1-header"
                onClick={() => setOpenAccordianList((prev) => prev?.includes(leaveData[0]) ? prev?.filter(list => list !== leaveData[0]) : [...prev, leaveData[0]])}
              >
                <Typography
                  color="dark.800"
                  fontSize={16}
                  lineHeight="24px"
                  fontWeight={500}
                >
                  {titleCase(leaveData[0])}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table
                    aria-label="simple table"
                    sx={{
                      borderCollapse: "separate",
                      borderSpacing: "0px 8px",
                    }}
                  >
                    <TableBody>
                      {(!!leaveData?.length && leaveData[1]?.length) ? leaveData[1]?.map((leaveDetail, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            td: {
                              border: 0,
                              bgcolor: "white",
                              padding: "8px 16px",
                              cursor: "pointer",
                            },
                            'td:first-child': {
                              borderRadius: "4px 0px 0px 4px",
                            },
                            'td:last-child': {
                              borderRadius: "0px 4px 4px 0px",
                            },
                          }}
                        >
                          <TableCell
                            sx={{
                              width: "150px",
                              minWidth: "150px",
                              maxWidth: "150px",
                            }}
                            onClick={() => handleViewLeave(leaveDetail)}>
                            <Typography
                              variant="body2"
                              color="dark.800"
                              lineHeight="14px"
                              letterSpacing="0.17px"
                              fontWeight={500}
                              mb="6px"
                              whiteSpace="nowrap"
                            >
                              {getApplicationNumber(leaveDetail?.application_num)}
                              <Chip
                                label={getFirstLetterOfString(leaveDetail?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name)}
                                color="secondary"
                                size="small"
                                variant="filled"
                                sx={{ marginLeft: '4px' }}
                              />
                            </Typography>
                            <Typography
                              color="dark.500"
                              fontSize={10}
                              lineHeight="15px"
                              letterSpacing="0.17px"
                              fontWeight={500}
                            >
                              {leaveDetail?.days || 1} Days
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              minWidth: "240px",
                            }}
                            onClick={() => handleViewLeave(leaveDetail)}>
                            <Typography
                              color="dark.500"
                              fontSize={10}
                              lineHeight="15px"
                              letterSpacing="0.17px"
                              fontWeight={500}
                              mb="6px"
                            >
                              Date
                            </Typography>
                            <Grid Item display="flex" alignItems="center">
                              <Typography
                                variant="body2"
                                color="dark.800"
                                lineHeight="14px"
                                letterSpacing="0.17px"
                                fontWeight={500}
                                mr="6px"
                                whiteSpace="nowrap"
                              >
                                {getLeaveDates(getLeaveDateArray(leaveDetail?.leave_days, 'leave_date'))}
                              </Typography>
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <Typography color={"white"} fontSize={16} lineHeight={"17px"} fontWeight={500} mb={1.5}>{calculateLeaveCount(leaveDetail?.leave_days)} Leaves on</Typography>
                                    <Table>
                                      <TableHead>
                                        <TableRow
                                          sx={{
                                            'th': {
                                              padding: '10px 4px',
                                              color: 'white',
                                              fontSize: 12,
                                              lineHeight: '19px',
                                              fontWeight: 700,
                                              background: 'rgba(255,255,255,0.1)',
                                              border: 'none',
                                              '&:first-child': {
                                                padding: '10px',
                                              }
                                            }
                                          }}
                                        >
                                          <TableCell align="left" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Date</TableCell>
                                          <TableCell align="left" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Days</TableCell>
                                          <TableCell align="left" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Full / Half</TableCell>
                                          <TableCell align="left" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Provision</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody
                                        sx={{
                                          'td': {
                                            padding: '4px 10px',
                                            color: 'white',
                                            fontSize: 14,
                                            lineHeight: '19px',
                                            fontWeight: 500,
                                            border: 'none',
                                          },
                                          'tr:first-child td': {
                                            paddingTop: '10px',
                                          }
                                        }}
                                      >
                                        {sortArrayByKey(leaveDetail?.leave_days, 'asc', 'leave_date')?.map((leaveDate, index) => (
                                          <TableRow>
                                            <TableCell sx={{ padding: '10px 4px !important' }}>{getFormattedDate(leaveDate?.leave_date)}</TableCell>
                                            <TableCell sx={{ padding: '10px 4px !important' }}>({getDayName(getFormattedDate(leaveDate?.leave_date))})</TableCell>
                                            <TableCell sx={{ padding: '10px 4px !important' }}>{`${leaveDate?.leave_duration ===
                                              "first_half"
                                              ? "1st Half"
                                              : leaveDate?.leave_duration ===
                                                "second_half"
                                                ? "2nd Half"
                                                : "Full-day"
                                              }`}</TableCell>
                                            <TableCell sx={{ padding: '10px 4px !important' }}>{leaveDetail?.status === LEAVE_STATUS.Pending ? 'Yes' : '-'}</TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </React.Fragment>
                                }
                              >
                                <i
                                  style={{
                                    height: 18,
                                    maxWidth: 18,
                                    flex: "0 0 18px",
                                  }}
                                >
                                  <InfoRoundedIcon
                                    color="secondary"
                                    sx={{ fontSize: 18 }}
                                  />
                                </i>
                              </HtmlTooltip>
                            </Grid>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "200px",
                              minWidth: "200px",
                              maxWidth: "200px",
                            }}
                            onClick={() => handleViewLeave(leaveDetail)}>
                            <Typography
                              color="dark.500"
                              fontSize={10}
                              lineHeight="15px"
                              letterSpacing="0.17px"
                              fontWeight={500}
                              mb="6px"
                            >
                              Applied on
                            </Typography>
                            <Typography
                              variant="body2"
                              color="dark.800"
                              lineHeight="14px"
                              letterSpacing="0.17px"
                              fontWeight={500}
                              whiteSpace="nowrap"
                            >
                              {getApplyDateTime(leaveDetail?.createdAt || leaveDetail?.apply_date)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "170px",
                              minWidth: "170px",
                              maxWidth: "170px",
                            }}
                            onClick={() => handleViewLeave(leaveDetail)}>
                            <Typography
                              variant="body2"
                              color="dark.800"
                              lineHeight="14px"
                              letterSpacing="0.17px"
                              fontWeight={500}
                              whiteSpace="nowrap"
                            >
                              {calculateTotalCredits(leaveDetail?.leave_days)}{" "}
                              Credits Used
                            </Typography>
                          </TableCell>
                          {hasAttachmentInList() ?
                            <TableCell
                              sx={{
                                width: "70px",
                                minWidth: "70px",
                                maxWidth: "70px",
                              }}
                            >
                              {leaveDetail?.attachment_references > 0 ? <Box
                                sx={{
                                  alignItems: 'center',
                                  color: "secondary",
                                  display: "flex",
                                }}
                              >
                                <Box
                                  sx={{ alignItems: 'center' }}
                                >
                                  <Typography
                                    variant="body1"
                                    color="secondary"
                                    fontSize={14}
                                    fontWeight={500}
                                    sx={{
                                      lineHeight: "24px",
                                      letterSpacing: "0.17px",
                                      marginBottom: "2px",
                                      display: "block",
                                      width: "100%",
                                    }}
                                  >
                                    <i
                                      style={{
                                        height: "18px",
                                        width: "18px",
                                        display: "flex",
                                      }}
                                    >
                                      {ICONS.Attachment}
                                    </i>
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{ alignItems: 'center' }}
                                >
                                  <Typography
                                    variant="body1"
                                    color="secondary"
                                    fontSize={14}
                                    fontWeight={500}
                                    sx={{
                                      lineHeight: "24px",
                                      letterSpacing: "0.17px",
                                      marginBottom: "2px",
                                      display: "block",
                                      width: "100%",
                                    }}
                                  >
                                    {leaveDetail?.attachment_references}
                                  </Typography>
                                </Box>
                              </Box> : null}
                            </TableCell>
                            : null}
                          <TableCell
                            sx={{
                              width: "120px",
                              minWidth: "120px",
                              maxWidth: "120px",
                              padding: "10px 16px"
                            }}
                            onClick={() => handleViewLeave(leaveDetail)}
                          >
                            {getLeaveStatus(leaveDetail?.status, "chip")}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "65px",
                              minWidth: "65px",
                              maxWidth: "65px",
                            }}>
                            <IconButton
                              aria-label="more"
                              aria-haspopup="true"
                              onClick={handleClick(leaveDetail)}
                              sx={{
                                height: "30px",
                                width: "30px",
                                padding: "0px",
                                borderRadius: "4px",
                              }}
                              id="basic-button"
                              aria-controls={open ? 'basic-menu' : ""}
                              aria-expanded={open ? 'true' : "false"}
                            >
                              <i
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  display: "flex",
                                }}
                              >
                                {ICONS.DotsHorizontal}
                              </i>
                            </IconButton>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open && openElem?.id === leaveDetail?.id}
                              onClose={handleClose}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                            >
                              <MenuItem onClick={() => handleViewLeave(openElem)}>View</MenuItem>
                              <MenuItem disabled={!checkApplicationEnable(leaveDetail) || leaveDetail?.status !== "pending"} onClick={() => handleEditLeave(openElem)}>Edit</MenuItem>
                              <MenuItem disabled={!checkApplicationEnable(leaveDetail)} onClick={() => handleCancelLeaveModal(openElem)}>Cancel Leave</MenuItem>
                              {/* {leaveDetail?.status !== LEAVE_STATUS.Cancelled ? <MenuItem disabled={!checkApplicationEnable(leaveDetail)} onClick={() => handleDeleteLeaveModal(openElem)}>Delete Leave</MenuItem> : null} */}
                            </Menu>
                          </TableCell>
                        </TableRow>
                      )) : <TableRow
                        key={index}
                        sx={{
                          td: {
                            border: 0,
                            bgcolor: "white",
                            textAlign: 'center',
                            padding: { xs: "8px 8px", md: "8px 16px" },
                          },
                          'td:first-child': {
                            borderRadius: "4px 4px 4px 4px",
                          },
                          cursor: 'default'
                        }}
                      >
                        <TableCell sx={{ height: '60px' }}>
                          <Typography
                            color="dark.800"
                            fontSize={14}
                            lineHeight="24px"
                            fontWeight={500}
                          >
                            No Leaves Available
                          </Typography>
                        </TableCell>
                      </TableRow>}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))
          :
          <EmptyPage
            onClick={() => navigate(URLS.ApplyLeave)}
            buttontext="Apply Leave"
            title="No leaves available"
            buttonColor="secondary"
          />
        }
      </Grid>
    </Grid>
  );
};

export default LeaveListTable;
