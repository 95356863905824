/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import Button from "../../../../components/Button";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import useAuthentication from "../../../../hook/useAuthentication";
import CircularLoader from "../../../../components/CircularLoader";
import Input from "../../../../components/Input";
import ICONS from "../../../../constants/icons";
import { useAlert } from "../../../../hook/useAlert";
import Switch from "../../../../components/Switch";
import { usePageTitle } from "../../../../hook/useTitle";
import { sortArrayByKey, titleCase } from "../../../../utils";
import ConfirmationModal from "../../../../components/ConfirmationModal";

const AdministratorProjectRoles = () => {
  let initialEditableRole = {
    id: null,
    name: "",
    status: false,
  };
  const { getCurrentUser } = useAuthentication();
  const { setPageTitle } = usePageTitle();
  const currentUser = getCurrentUser();
  const showAlert = useAlert();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState({
    pageLoading: true,
    deleteButton: false,
    editButton: false,
    addButton: false,
  });
  const [userRolesData, setUserRolesData] = useState([]);
  const [optionalUserRolesData, setOptionalUserRolesData] = useState([]);
  const [tempUserRolesData, setTempUserRolesData] = useState([]);
  const [editableRole, setEditableRole] = useState(initialEditableRole);
  const [newRole, setNewRole] = useState(initialEditableRole);
  const [isOpen, setIsOpen] = useState(false);
  const [deletableRole, setDeletableRole] = useState({});
  const [errors, setErrors] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [isShowEmptyRow, setIsShowEmptyRow] = useState(false);

  setPageTitle("Roles");

  useEffect(() => {
    getUserRolesData();
  }, []);

  useEffect(() => {
    getDataAccordingPagination();
  }, [page, rowsPerPage]);

  const getDataAccordingPagination = (optionalUserRolesListData = optionalUserRolesData) => {
    let dataToPaginate = [...optionalUserRolesListData];

    if (dataToPaginate && dataToPaginate.length) {
      const startIdx = page * rowsPerPage;
      const endIdx = Math.min(startIdx + rowsPerPage, dataToPaginate?.length);
      const updatedPageData = dataToPaginate.slice(startIdx, endIdx);
      setUserRolesData(updatedPageData);
      setTempUserRolesData(updatedPageData)
    } else {
      setUserRolesData([]);
      setTempUserRolesData([])
    }
  };

  const getUserRolesData = async () => {
    try {
      const response = await axiosInstance.get(
        API.organisationUserRoles(currentUser?.organization_id)
      );
      if (response.status === 200) {
        let sortedData = sortArrayByKey(response?.data?.data, "asc", 'name');
        // setUserRolesData(sortedData);
        setOptionalUserRolesData(sortedData);
        setTempUserRolesData(sortedData);
        getDataAccordingPagination(sortedData)
      }
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditRole = (name, roleId, is_received_mail) => {
    setUserRolesData(tempUserRolesData);
    setEditableRole({
      id: roleId,
      name: name,
      status: is_received_mail
    });
  };

  const handleCloseDelete = () => {
    setIsOpen(false);
    setDeletableRole({});
    setIsLoading((prev) => ({ ...prev, deleteButton: false }));
  };

  const handleDeletableRole = (roleData) => {
    setDeletableRole(roleData);
  };

  const handleDeleteRole = async (roleId) => {
    try {
      setIsLoading((prev) => ({ ...prev, deleteButton: true }));
      let updateData = userRolesData?.map((data) => {
        return data?.id === roleId ? { ...data, is_deleted: true } : data;
      });
      const response = await axiosInstance.put(
        API.organisationUserRoles(currentUser?.organization_id),
        { org_user_roles: updateData }
      );
      if (response.status === 200) {
        setOptionalUserRolesData(prev => prev?.filter((roleData) => roleData?.id !== roleId))
        getDataAccordingPagination(optionalUserRolesData?.filter((roleData) => roleData?.id !== roleId))
        showAlert("User role delete successfully.")
        handleCloseDelete();
      } else {
        handleCloseDelete();
      }
    } catch (error) {
      console.error(error);
      handleCloseDelete();
    }
  };

  const handleChange = (e, is_switch = false) => {
    setEditableRole((prev) => ({
      ...prev,
      [e.target.name]: is_switch ? e.target.checked : e.target.value,
    }));
    setErrors((prev) => ({ ...prev, name: "" }));
  };

  const handleChangeNewName = (e, is_switch = false) => {
    setNewRole((prev) => ({
      ...prev,
      [e.target.name]: is_switch ? e.target.checked : e.target.value,
    }));
    setErrors((prev) => ({ ...prev, newName: "" }));
  }

  const handleCancel = () => {
    setUserRolesData(tempUserRolesData);
    setEditableRole(initialEditableRole);
    setErrors((prev) => ({ ...prev, name: "" }));
  };

  const handleCancelAddRole = () => {
    setIsShowEmptyRow(false);
    setUserRolesData(tempUserRolesData);
    setNewRole(initialEditableRole)
    setErrors((prev) => ({ ...prev, newName: "" }));
  }

  const handleAddRole = async () => {
    try {
      if (isValidate(userRolesData)) {
        setIsLoading((prev) => ({ ...prev, addButton: true }));
        let role = {
          ...newRole,
        }
        delete role?.uniqueId;
        delete role?.id;
        let updateData = [...tempUserRolesData, role];

        const response = await axiosInstance.put(API.organisationUserRoles(currentUser?.organization_id), { org_user_roles: updateData });
        if (response.status === 200) {
          getUserRolesData();
          // setEditableRole(initialEditableRole);
          setNewRole(initialEditableRole)
          setIsShowEmptyRow(false);
          showAlert(response?.data?.message);
          setIsLoading((prev) => ({ ...prev, addButton: false }));
        } else {
          handleCancelAddRole();
          showAlert(response?.data?.message || "Something went wrong while adding role", "error")
          setIsLoading((prev) => ({ ...prev, addButton: false }));
        }
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, addButton: false }));
    }
  }

  const validateUniqueRoles = (userRolesData, type) => {
    const uniqueRoleNames = userRolesData?.filter((role) => (type === "add" ? newRole.id : editableRole.id) !== role?.id && role.name?.toString()?.toLowerCase() === (type === "add" ? newRole?.name?.toLocaleLowerCase() : editableRole?.name?.toLocaleLowerCase()));
    if (type === "add" && uniqueRoleNames.length > 0) {
      return false;
    } else if (type === "update" && uniqueRoleNames.length === 1) {
      if (editableRole?.name?.toLowerCase() === uniqueRoleNames[0]?.name?.toLowerCase() && editableRole?.id === uniqueRoleNames[0]?.id)
        return true;
      else
        return false;
    } else if (type === "update" && uniqueRoleNames.length > 1) {
      return false;
    }
    return true;
  };

  const isValidate = (array, type = "add") => {
    if (!editableRole?.name?.length && type !== 'add') {
      setErrors((prev) => ({ ...prev, name: "Please enter role name" }));
      return false;
    } else if (type === "add" && !newRole?.name?.length) {
      setErrors((prev) => ({ ...prev, newName: "Please enter role name" }));
      return false;
    }
    if (!validateUniqueRoles(array, type)) {
      if (type === "add")
        setErrors({ newName: "Role names should be unique" });
      else
        setErrors({ name: "Role names should be unique" });
      return false;
    } else {
      setErrors({ name: "", newName: "" });
    }
    return true;
  };

  const handleUpdateRole = async () => {
    try {
      if (isValidate(userRolesData, "update")) {
        setIsLoading((prev) => ({ ...prev, editButton: true }));
        let updateData = userRolesData?.map((data) => {
          return data?.id === editableRole?.id
            ? {
              ...data,
              name: editableRole?.name,
              status: editableRole?.status,
            } : data;
        });
        const response = await axiosInstance.put(
          API.organisationUserRoles(currentUser?.organization_id),
          { org_user_roles: updateData }
        );
        if (response.status === 200) {
          setTempUserRolesData(updateData);
          setUserRolesData(updateData);
          setOptionalUserRolesData(prev => prev?.map((data) => {
            return data?.id === editableRole?.id
              ? {
                ...data,
                name: editableRole?.name,
                is_received_leave_mail: editableRole?.is_received_leave_mail,
              } : data;
          }))
          setEditableRole(initialEditableRole);
          showAlert(response?.data?.message);
          setIsLoading((prev) => ({ ...prev, editButton: false }));
        } else {
          setUserRolesData(updateData);
          setOptionalUserRolesData(prev => prev?.map((data) => {
            return data?.id === editableRole?.id
              ? {
                ...data,
                name: editableRole?.name,
                is_received_leave_mail: editableRole?.is_received_leave_mail,
              } : data;
          }))
          setEditableRole(initialEditableRole);
          showAlert(response?.data?.message);
          setIsLoading((prev) => ({ ...prev, editButton: false }));
        }
      } else {
        setIsLoading((prev) => ({ ...prev, editButton: false }));
      }
    } catch (error) {
      console.error(error)
      showAlert(error?.response?.data?.message);
      setEditableRole(initialEditableRole);
      setIsLoading((prev) => ({ ...prev, editButton: false }));
    }
  }


  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null)
  };

  const handleCreateRole = () => {
    setIsShowEmptyRow(true);
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userRolesData?.length) : 0;

  return (
    <>
      <Box display="flex" flexDirection="column" sx={{ width: "100%" }} p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} overflow="auto">
        <Grid container mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Grid item>
            <Typography color="dark.800" fontSize={14} fontWeight={500} lineHeight="24px" letterSpacing="0.17px">
              {userRolesData?.length || 0} Project Roles
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="medium"
              fontWeight="500"
              sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
              onClick={() => handleCreateRole()}
              isButtonLoading={false}
              disabled={isShowEmptyRow}
            >
              Add Project Role
            </Button>
          </Grid>
        </Grid>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            boxShadow: "none",
            border: "1px solid #E0E0E0",
            borderRadius: "3px",
          }}
        >
          <TableContainer>
            <Table
              sx={{ width: '100%' }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow
                  sx={{
                    'th': {
                      color: "dark.800",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "24px",
                      letterSpacing: "0.17px",
                      padding: "6px 16px",
                      background: "#F7F7F7",
                    },
                  }}
                >
                  <TableCell>Name</TableCell>
                  <TableCell>
                    <Box sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: 'center',
                    }}>
                      <Typography color="dark.800" fontSize={14} fontWeight={500} lineHeight="24px" letterSpacing="0.17px">
                        Status
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right" sx={{ paddingRight: '38px !important' }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isShowEmptyRow ?
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      td: {
                        color: "rgba(0, 0, 0, 0.87)",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.17px",
                        padding: "6px 16px",
                      },
                    }}
                  >
                    <TableCell sx={{
                      // width: "185px !important"
                      width: 'calc(100%/3)'
                    }}>
                      <Input
                        id="role-name"
                        name="name"
                        variant="standard"
                        type="text"
                        onChange={(e) => handleChangeNewName(e)}
                        value={titleCase(newRole?.name)}
                        error={!!errors["newName"]}
                        helperText={errors["newName"]}
                        sx={{ width: "100%", maxWidth: '300px' }}
                        // isOnlyCapitalFirstLatter={true}
                        isOnlyAlphabetAllowed={true}
                      />
                    </TableCell>
                    <TableCell align={'center'} >
                      <Switch
                        name="status"
                        id="status"
                        size="small"
                        checked={newRole?.status}
                        onChange={(e) => handleChangeNewName(e, true)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{ padding: '5px 0px' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          fontSize={13}
                          size="small"
                          sx={{ textTransform: "none", color: 'white', boxShadow: 'none !important' }}
                          onClick={() => handleAddRole()}
                          isButtonLoading={isLoading?.addButton}
                        >
                          Add
                        </Button>
                        <Button
                          component="label"
                          variant="text"
                          color="secondary"
                          fontSize={13}
                          size="small"
                          sx={{ textTransform: "none" }}
                          onClick={() => handleCancelAddRole()}
                          disabled={isLoading?.addButton}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow> : null}
                {isLoading?.pageLoading ? (
                  <CircularLoader
                    variant="table"
                    rows={userRolesData?.length}
                    cols={4}
                  />
                ) : userRolesData.length ? (
                  userRolesData.map((row, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          sx={{
                            td: {
                              color: "rgba(0, 0, 0, 0.87)",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              letterSpacing: "0.17px",
                              padding: "6px 16px",
                            },
                          }}
                        >
                          <TableCell align="left" sx={{
                            // width: "185px !important"
                            width: 'calc(100%/3)'
                          }}>
                            {Boolean(row?.id) && (row?.id === editableRole?.id || row?.id === editableRole?.id === editableRole?.uniqueId) && !['administrator'].includes(row?.name?.toLowerCase()) ? (
                              <Input
                                id="role-name"
                                name="name"
                                variant="standard"
                                type="text"
                                onChange={(e) => handleChange(e)}
                                value={titleCase(editableRole?.name)}
                                error={!!errors["name"]}
                                helperText={errors["name"]}
                                sx={{ width: "100%", maxWidth: '300px' }}
                                // isOnlyCapitalFirstLatter={true}
                                disabled={!row?.canDelete}
                                isOnlyAlphabetAllowed={true}
                              />
                            ) : (
                              row?.name
                            )}
                            <span></span>
                          </TableCell>
                          <TableCell align={'center'}>
                            <Switch
                              name="status"
                              id="status"
                              size="small"
                              checked={Boolean(row?.id) && (row?.id === editableRole?.id || row?.id === editableRole?.id === editableRole?.uniqueId) ? editableRole?.status : row?.status}
                              onChange={(e) => handleChange(e, true)}
                              disabled={row?.id !== editableRole?.id}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {Boolean(row?.id) && (row?.id === editableRole?.id || row?.id === editableRole?.id === editableRole?.uniqueId) ? (
                              <Box sx={{ padding: '5px 0px' }}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fontSize={13}
                                  size="small"
                                  sx={{ textTransform: "none", color: 'white', boxShadow: 'none !important' }}
                                  onClick={() => handleUpdateRole()}
                                  isButtonLoading={isLoading?.editButton}
                                >
                                  Update
                                </Button>
                                <Button
                                  component="label"
                                  variant="text"
                                  color="secondary"
                                  fontSize={13}
                                  size="small"
                                  sx={{ textTransform: "none" }}
                                  onClick={() => handleCancel(row?.id)}
                                  disabled={isLoading?.editButton}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            ) : (
                              <div style={{ width: '98px', textAlign: 'center', display: 'inline-block' }}>
                                <IconButton
                                  sx={{
                                    height: "30px",
                                    width: "30px",
                                    padding: "0px",
                                    borderRadius: "4px",
                                    color: "secondary.main",
                                  }}
                                  id={`basic-button-${index}`}
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={(e) => handleClick(e, row?.id)}
                                >
                                  <i
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      display: "flex",
                                    }}
                                  >
                                    {ICONS.DotsHorizontal}
                                  </i>
                                </IconButton>
                                <Menu
                                  id={`basic-menu-${index}`}
                                  anchorEl={anchorEl}
                                  open={open && openMenuIndex === row?.id}
                                  onClose={() => handleClose()}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                  sx={{
                                    '.MuiPaper-root': {
                                      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
                                      width: '180px',
                                    },
                                    '.MuiMenuItem-root': {
                                      color: 'dark.800',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      lineHeight: '24px',
                                      letterSpacing: '0.15px',
                                    },
                                    '.MuiMenuItem-root:hover': {
                                      background: 'rgba(33, 150, 243, 0.04)'
                                    }
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      handleEditRole(row?.name, row?.id, row?.status);
                                      handleClose();
                                    }}
                                    // disabled={!row?.canDelete}
                                    disabled={['administrator'].includes(row?.name?.toLowerCase()) && !row?.canDelete}
                                  >
                                    Rename
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      handleDeletableRole(row);
                                      setIsOpen(true);
                                    }}
                                  // disabled={!row?.canDelete}
                                  >
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })
                ) : (
                  emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={optionalUserRolesData?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <ConfirmationModal
          isOpen={isOpen}
          title={'Role'}
          deletableDataName={titleCase(deletableRole?.name)}
          dataContentName={'Role'}
          handleClose={() => setIsOpen(false)}
          onConfirm={() => handleDeleteRole(deletableRole?.id)}
          onCancel={() => handleCloseDelete()}
          isButtonLoading={isLoading?.deleteButton}
          subList={<span>This will remove <b>{titleCase(deletableRole?.name)}</b> from your organization's common Roles list.</span>}
        />
      </Box>
    </>
  );
}

export default AdministratorProjectRoles