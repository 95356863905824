import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from "react-secure-storage";
import { ONBOARDING_STEP_TO_STATUS } from '../../constants/default-values';

const getUserFromLocalStorage = () => {
    const user = secureLocalStorage.getItem('currentUser');
    return user?.length >= 0 ? JSON.parse(user) : null;
};
const getTokenFromLocalStorage = () => {
    const token = secureLocalStorage.getItem('token');
    return token?.length >= 0 ? token : null;
};
const getOrganizationFromLocalStorage = () => {
    const organization = secureLocalStorage.getItem('organization');
    return organization?.length >= 0 ? JSON.parse(organization) : null;
};

const initialState = {
    user: getUserFromLocalStorage(),
    token: getTokenFromLocalStorage(),
    organization: getOrganizationFromLocalStorage()
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserDetails: (state, action) => {
            let payload = action.payload;
            if (!!payload?.user) {
                state.user = payload?.user;
                secureLocalStorage.setItem("currentUser", JSON.stringify(payload?.user));
            }
            if (!!payload?.token) {
                state.token = payload?.token;
                secureLocalStorage.setItem("token", payload?.token);
            }
            if (!!payload?.organization) {
                state.organization = payload?.organization;
                secureLocalStorage.setItem("organization", JSON.stringify(payload?.organization));
            }
        },
        clearStorage: (state) => {
            secureLocalStorage.removeItem("currentUser");
            secureLocalStorage.removeItem("token");
            secureLocalStorage.removeItem("organization");
            state.user = {};
            state.token = null;
            state.organization = {};
        },
        setUserOnBoardingStep: (state, action) => {
            let step = action.payload;
            const currentUser = state.user;
            const status = ONBOARDING_STEP_TO_STATUS[step];
            let updatedUserData = {
                ...currentUser,
                onboarding_status: status
            }
            state.user = updatedUserData;
            secureLocalStorage.setItem("currentUser", JSON.stringify(updatedUserData));
        }
    },
});

export const { setUserDetails, clearStorage, setUserOnBoardingStep } = userSlice.actions;
export default userSlice.reducer;
