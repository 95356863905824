/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MultiDatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { IconButton, TextField } from "@mui/material";
import { getFormattedDate } from "../../utils";
import moment from "moment";
import ClearIcon from '@mui/icons-material/Clear';
import { WEEKDAYS } from "../../constants/default-values";
import { useSelector } from "react-redux";
import useAuthentication from "../../hook/useAuthentication";

const MultipleDatePicker = ({
  name,
  value,
  onChange,
  ignoreDates = [],
  onConfirm,
  onCancel,
  onClose = () => { },
  onBlur,
  isAutoDeleted = false,
  setIsAutoDeleted,
  extraDisableDates = [],
  ...restDateProps
}) => {
  const { getCurrentUser } = useAuthentication();
  const { ignoreLeaveDates } = useSelector((state) => state?.leaveData);
  const currentUser = getCurrentUser();

  const [selectedDates, setSelectedDates] = useState(value || []);

  useEffect(() => {
    if (isAutoDeleted) {
      setSelectedDates(value);
      setIsAutoDeleted(false);
    }
  }, [isAutoDeleted])

  const handleDateChange = (newDates) => {
    setSelectedDates(newDates);
    onChange(newDates);
  };

  const getIgnoreDatesArray = () => {
    let array = [];
    if (ignoreDates?.length) {
      array = ignoreDates;
    } else {
      array = ignoreLeaveDates;
    }
    if (extraDisableDates?.length) {
      array = Array.from(new Set([...extraDisableDates, ...ignoreLeaveDates]));
    }
    return array;
  }

  const handleDelete = (deletedIndex) => () => {
    const newDates = selectedDates?.filter((date, index) => index !== deletedIndex);
    handleDateChange(newDates);
    // onChange(newDates);
  };

  const handleClear = () => {
    const newDates = [];
    handleDateChange(newDates);
    // onChange(newDates);
  };

  const handleOnClose = (...rest) => {
    if (selectedDates?.length === 0) {
      let ignoreDatesArray = getIgnoreDatesArray();
      if (ignoreDatesArray?.length) {
        let isExists = ignoreDatesArray?.some((ignoreDate) => {
          let date1 = moment(ignoreDate, 'DD/MM/YYYY').format("DD/MM/YYYY");
          let date2 = moment(new DateObject()).format("DD/MM/YYYY");
          return date1 === date2;
        });
        if (!isExists) {
          setSelectedDates([new DateObject()]);
          onChange([new DateObject()]);
        }
      } else {
        setSelectedDates([new DateObject()]);
        onChange([new DateObject()]);
      }
      onClose(...rest);
    } else {
      onClose(...rest);
    }
  };

  return (
    <MultiDatePicker
      onClose={handleOnClose}
      value={selectedDates}
      onChange={handleDateChange}
      plugins={[<DatePanel markFocused />]}
      sort
      highlightToday
      weekDays={WEEKDAYS}
      headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
      format="DD/MM/YYYY"
      mapDays={({ date }) => {
        // let isPastDate = moment(new Date(date)).add(1, 'd').isBefore(new Date());
        let isPastDate = moment(new Date(date)).isBefore(currentUser?.organization?.createdAt || "01/01/2024");
        let isIgnoredDate = getIgnoreDatesArray()?.some((ignoreDate) => {
          let date1 = moment(ignoreDate, 'DD/MM/YYYY').format("DD/MM/YYYY");
          let date2 = moment(new Date(date)).format("DD/MM/YYYY");
          return date1 === date2;
        })
        if (isPastDate || isIgnoredDate) {
          return {
            disabled: true,
            style: { color: "#ccc" },
          };
        }
      }}
      render={
        <TextField
          autoComplete="off"
          onKeyDown={(event) => {
            event.preventDefault();
          }}
          name={name}
          id="tags-standard"
          variant="standard"
          onBlur={onBlur}
          sx={{
            minHeight: "30px",
            width: "100%",
            ".MuiInputBase-formControl": {
              minHeight: "30px",
              flexWrap: "wrap",
              paddingTop: "0px",
              marginTop: '8px'
            },
            ".MuiInputBase-input": {
              opacity: 0,
              height: "22px",
              minWidth: "150px",
              flex: '1',
              border: "none",
              top: 0,
              bottom: "1px",
              background: "transparent",
            },
            ".rmdp-input:focus": {
              border: "none",
              boxShadow: "none",
            },
            ".MuiChip-sizeSmall": {
              marginLeft: '0px !important',
              marginRight: '8px'
            }
          }}
          InputProps={{
            startAdornment: (
              <Stack spacing={1} direction="row" flexWrap="wrap">
                {selectedDates?.map((date, index) => (
                  <Chip
                    key={date}
                    label={typeof date === "string" ? date : getFormattedDate(new Date(date))}
                    onDelete={handleDelete(index)}
                    color="secondary"
                    sx={{ marginBottom: "3px !important" }}
                    size="small"
                  />
                ))}
              </Stack>
            ),
            endAdornment: (
              !!selectedDates?.length ?
                <IconButton
                  size="small"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleClear}
                >
                  <i
                    style={{
                      height: "20px",
                      width: "20px",
                      transition: 'all 0.25s ease-in-out',
                    }}><ClearIcon /></i>
                </IconButton>
                : null),
            readOnly: true
          }}
        />
      }

      {...restDateProps}
    >
      {/* <Grid
        Item
        pb={2}
        justifyContent="flex-end"
        display="flex"
        sx={{ paddingRight: "12px" }}
      >
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Apply</Button>
      </Grid> */}
    </MultiDatePicker>
  );
};

export default MultipleDatePicker;
