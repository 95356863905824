/* eslint-disable react-hooks/exhaustive-deps */
import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip, tooltipClasses, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { useNavigate } from 'react-router-dom'
import URLS from '../../../routes/urls'
import { convertDateFromFormat, convertTime, getDatesArray, getDisplayValue, getFormattedDate, getStartDateBaseOnOnboarding, sortArrayByKey, titleCase } from '../../../utils'
import axiosInstance from '../../../axios'
import API from '../../../axios/api'
import TableRowSkeleton from '../../../components/TableRowSkeleton'
import useAuthentication from '../../../hook/useAuthentication'
import Button from '../../../components/Button'
import { DateObject, getAllDatesInRange } from 'react-multi-date-picker'
import CircularLoader from '../../../components/CircularLoader'
import moment from 'moment'
import { useAlert } from '../../../hook/useAlert'
import { styled } from '@mui/styles'
import ReactApexChart from 'react-apexcharts'
import EmptyTable from '../../../components/EmptyTable'
import { CHART_COLOR } from '../../../constants/default-values'
import MenuDateRangePicker from '../../../components/DateRangePicker/MenuDateRangePicker'

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: "16px",
        borderRadius: "5px",
        background: "#3F5C76",
    },
}));

const InOutReport = () => {
    let navigate = useNavigate();
    const showAlert = useAlert();
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const [isLoading, setIsLoading] = useState({
        users: true,
        report: false,
    });
    let initialSummaryData = {
        startDate: null,
        endDate: null,
        results: [],
        user: null,
        working_days: 0,
        non_working_days: 0,
        cl_count: 0,
        sl_count: 0,
    };
    let onboardingDate = new Date(currentUser?.organization?.createdAt);
    let initialStartDate = getStartDateBaseOnOnboarding(new Date(new DateObject().toFirstOfMonth()), onboardingDate);
    const [summary, setSummary] = useState(initialSummaryData);
    const [sortType, setSortType] = useState("asc");
    const [orderBy, setOrderBy] = useState("date");
    const [dateRange, setDateRange] = useState([
        initialStartDate,
        new DateObject().subtract(1, "days")
    ]);
    const [dateLimits, setDateLimits] = useState({
        minDate: new DateObject().toFirstOfYear(),
        maxDate: new DateObject().subtract(1, 'days'),
    });
    const [queryParams, setQueryParams] = useState({
        startDate: initialStartDate.format("YYYY-MM-DD"),
        endDate: new DateObject().subtract(1, "days").format("YYYY-MM-DD"),
        user_id: "",
        page: 0,
        perPage: 10,
    });
    const [errors, setErrors] = useState({});
    const [organizationUserList, setOrganizationUserList] = useState([]);

    const compareDates = (date1Str, date2Str) => {
        function parseDate(dateStr) {
            const [day, month] = dateStr.split('/').map(Number);
            return new Date(new Date().getFullYear(), month - 1, day);
        }

        const date1 = parseDate(date1Str);
        const date2 = parseDate(date2Str);

        return date1 < date2;
    }

    const handleChartXAxisLabel = (...rest) => {
        const seriesElements = document.querySelectorAll('.apexcharts-xaxis-label');
        if (seriesElements?.length > 0) {
            let allLabels = [];
            seriesElements.forEach((element) => {
                allLabels.push({
                    id: element?.id,
                    text: element?.textContent?.slice(0, 5)
                });
            })
            let dateStrings = rest?.[0]?.config?.xaxis?.categories;
            if (dateStrings?.length > 0) {
                const dates = dateStrings?.map(dateStr => new Date(dateStr));
                const maxDate = new Date(Math.max(...dates));
                allLabels?.forEach(label => {
                    const result = compareDates(moment(maxDate).format("DD/MM"), label?.text);
                    if (result) {
                        let _label = document.querySelector(`#${label?.id}`);
                        _label.setAttribute("style", "display: none");
                    }
                })
            }
        }
    }

    const handleChartMounted = () => {
        const bars = document.querySelectorAll('.apexcharts-bar-area');
        bars.forEach(bar => {
            bar.style.borderRadius = '0';
            bar.style.clipPath = 'none';
        });
    }

    const [series, setSeries] = useState([]);

    const [options, setOptions] = useState({
        chart: {
            id: "my-stack-chart",
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true,
            },
            animations: {
                enabled: true,
                easing: 'linear',
                speed: 300,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    // when chart is update
                    // enabled: false,
                }
            },
            events: {
                updated: (chartContext, ...config) => {
                    handleChartXAxisLabel(...config);
                },
                mounted: () => { handleChartMounted() }
            },
        },
        toolbar: {
            autoSelected: 'zoom',
            export: {
                csv: {
                    filename: "in-out-report",
                    columnDelimiter: ',',
                    headerCategory: 'category',
                    headerValue: 'value',
                    categoryFormatter(x) {
                        return new Date(x).toDateString()
                    },
                    valueFormatter(y) {
                        return decimalToTime(y)
                    }
                },
                svg: {
                    filename: "in-out-report",
                },
                png: {
                    filename: "in-out-report",
                }
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        colors: CHART_COLOR,
        grid: {
            padding: {
                left: 15,
                right: 15
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                hideZeroBarsWhenGrouped: true,
                columnWidth: '22px',
                borderRadius: "0px",
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'last',
                dataLabels: {
                    total: {
                        enabled: false,
                    }
                }
            },
        },
        xaxis: {
            type: 'datetime',
            categories: [],
            tickPlacement: 'on',
            labels: {
                format: 'dd/MM',
                showDuplicates: false,
                trim: true,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                formatter: function (val, index) {
                    return val > 0 ? `${val?.toString()?.padStart(2, "0")} h` : 0;
                }
            },
        },
        fill: {
            opacity: 1
        },
        noData: {
            text: "No data found.",
            align: 'center',
            verticalAlign: 'top',
            offsetX: 10,
            offsetY: 125,
            style: {
                color: CHART_COLOR[1],
                fontSize: '22px',
            }
        },
        tooltip: {
            inverseOrder: true,
            followCursor: true,
            shared: false,
            x: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return moment(value)?.format("DD/MM")
                }
            },
            y: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return decimalToTime(value);
                }
            }
        },
    })

    useEffect(() => {
        getUserSettingData();
    }, []);

    useEffect(() => {
        handleChartXAxisLabel();
    }, [options])

    const getUserSettingData = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, users: true }))
            const response = await axiosInstance.get(
                `${API.organisationUserSetting(currentUser?.organization_id)}`
            );
            if (response.status === 200) {
                let sortedNames = sortArrayByKey(response?.data?.data)
                setOrganizationUserList(sortedNames);
                setQueryParams((prev) => ({
                    ...prev,
                    user_id: currentUser?.id,
                }));
                setIsLoading((prev) => ({ ...prev, users: false }))
            } else {
                setIsLoading((prev) => ({ ...prev, users: false }))
            }
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, users: false }))
        }
    };

    let getUser = (id) => {
        return organizationUserList?.find((value) => value?.id === id)
    };

    let getDates = () => {
        if (summary?.startDate === summary?.endDate)
            return <span style={{ fontWeight: "bold" }}>{getFormattedDate(summary?.startDate)}</span>
        else
            return <span style={{ fontWeight: "bold" }}>{getFormattedDate(summary?.startDate)} - {getFormattedDate(summary?.endDate)}</span>
    }

    const isShowDot = (value) => {
        if (value?.work_mode_label) {
            if (['casual leave', 'sick leave'].includes(value?.work_mode_label?.toLowerCase())) {
                return {
                    isShow: [true, true],
                    isMultiple: true
                }
            } else {
                return {
                    isShow: [true, false],
                    isMultiple: false
                }
            }
        } else {
            if (value?.is_holiday) {
                return {
                    isShow: [false, true],
                    isMultiple: false
                }
            }
            else if (value?.is_leave) {
                return {
                    isShow: value?.leave_duration === "full" ? [false, true] : [true, true],
                    isMultiple: value?.leave_duration !== "full"
                }
            }
        }
    }

    const convertLatestUserData = async (latestUserDailyTrackerData) => {
        try {
            let updatedTrackingData = [...latestUserDailyTrackerData];
            const convertSecondsToTime = async (given_seconds) => {
                let hours = Math.floor(given_seconds / 3600);
                let minutes = Math.floor((given_seconds - hours * 3600) / 60);
                let seconds = given_seconds - hours * 3600 - minutes * 60;

                let timeString =
                    hours.toString().padStart(2, "0") +
                    ":" +
                    minutes.toString().padStart(2, "0") +
                    ":" +
                    seconds.toString().padStart(2, "0");

                return timeString;
            };

            for (const item of updatedTrackingData) {
                if (typeof item.total === "number") {
                    item.total = await convertSecondsToTime(item.total);
                    item.working_hrs = await convertSecondsToTime(item.working_hrs);
                    item.non_working_hrs = await convertSecondsToTime(item.non_working_hrs);

                    const loginTime = item.user_activities?.filter(
                        (key) => key?.log_type === "login"
                    );
                    const logoutTime = item.user_activities?.filter(
                        (key) => key?.log_type === "logout"
                    );

                    if (loginTime.length > 0) {
                        item.loginInDate = moment(loginTime[0]?.createdAt).format("DD/MM/YYYY");
                        item.loginInTime = moment(loginTime[0]?.createdAt).format("hh:mm A");
                    }

                    if (logoutTime.length > 0) {
                        item.loginOutDate = moment(logoutTime[logoutTime.length - 1]?.createdAt).format("DD/MM/YYYY");
                        item.logoutTime = moment(logoutTime[logoutTime.length - 1]?.createdAt).format("hh:mm A");
                    } else {
                        item.loginOutDate = moment(item.date).format("DD/MM/YYYY");
                    }
                    item.date = moment(item.date).format("DD/MM/YYYY");
                } else {
                    item.date = moment(item.date).format("DD/MM/YYYY");
                    item.loginInDate = item.date;
                    item.loginOutDate = item.date;
                }
            }

            return updatedTrackingData;
        } catch (error) {
            console.error("Error converting latestUserDailyTrackerData", error);
        }
    };

    const getSummary = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, report: true }));
            let body = {
                startDate: queryParams?.startDate,
                endDate: queryParams?.endDate,
                user_id: queryParams?.user_id,
            }
            const response = await axiosInstance.post(API.getInOutReport, body);
            if (response.status === 200) {
                let nonWorking = response.data?.data?.nonWorking;
                let leaveInfo = response.data?.data?.leaveInfo;

                let userDailyTrackerData = await convertLatestUserData(response.data?.data?.userDailyTrackerData);
                let dates = getDatesArray(queryParams.startDate, queryParams.endDate);
                let workingDays = dates?.filter((value) => !nonWorking.includes(value));

                let sortedData = sortArrayByKey([...userDailyTrackerData] || [], "asc", "date");

                const getLeaveCount = (type = "Casual Leave") => {
                    let totalCount = leaveInfo?.filter((value) => value?.type?.toLowerCase() === type?.toLowerCase())?.reduce((previousValue, currentValue) => {
                        return previousValue + currentValue?.days
                    }, 0);
                    return totalCount;
                };

                setSummary({
                    results: sortedData,
                    user: getUser(queryParams?.user_id),
                    startDate: queryParams.startDate,
                    endDate: queryParams.endDate,
                    working_days: workingDays?.length || 0,
                    non_working_days: nonWorking?.length || 0,
                    cl_count: getLeaveCount("Casual Leave"),
                    sl_count: getLeaveCount("Sick Leave"),
                });

                const transformedData = sortedData?.map(item => ({
                    date: item.date,
                    working_hrs: timeToDecimal(item?.working_hrs),
                    non_working_hrs: timeToDecimal(item?.non_working_hrs),
                    wfh: item?.wfh
                }));

                const xAxisData = transformedData?.map(item => {
                    const [day, month, year] = item?.date?.split('/').map(Number);
                    const date = new Date(Date.UTC(year, month - 1, day));
                    const formattedDate = `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()} GMT`;
                    return formattedDate;
                });
                const workingHoursData = transformedData?.map(item => item?.working_hrs);
                const nonWorkingHoursData = transformedData?.map(item => item?.non_working_hrs);
                const wfhDates = transformedData?.map(item => !!item?.wfh ? item?.date : null)?.filter((value) => !!value);

                setSeries([
                    {
                        name: 'Working hours',
                        data: workingHoursData,
                    }, {
                        name: 'Non-working',
                        data: nonWorkingHoursData,
                    }
                ]);
                let generatePoints = (wfhDates) => {
                    return wfhDates?.map((date, index) => {
                        let [DD, MM, YYYY] = date?.split("/");
                        const _date = new Date(Date.UTC(YYYY, MM - 1, DD));
                        return {
                            x: _date.getTime(),
                            marker: {
                                size: 4,
                                fillColor: CHART_COLOR[0],
                                strokeColor: CHART_COLOR[0],
                                radius: 2,
                            }
                        };
                    })
                }
                setOptions((prev) => ({
                    ...prev,
                    xaxis: { ...prev.xaxis, categories: xAxisData },
                    annotations: {
                        points: generatePoints(wfhDates)
                    }
                }))
                setQueryParams((prev) => ({
                    ...prev,
                    page: 0,
                    perPage: 10,
                }))
                setIsLoading((prev) => ({ ...prev, report: false }));
            } else {
                setSummary(initialSummaryData)
                setIsLoading((prev) => ({ ...prev, report: false }));
                showAlert(response.data.message, "error");
            }
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, report: false }));
            // showAlert(error.response.data.message, "error");
            setSummary(initialSummaryData);
        }
    };

    const handleSortClick = (sort_by) => {
        setOrderBy(sort_by);
        setSortType((prev) => (prev === "asc" ? "desc" : "asc"));
    };

    // const handleChangePage = (event, newPage) => {
    //     setQueryParams((prev) => ({
    //         ...prev,
    //         page: newPage,
    //     }));
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setQueryParams((prev) => ({
    //         ...prev,
    //         perPage: event.target.value,
    //         page: 0,
    //     }));
    // };

    const handleChangeDatePicker = (dateObjects) => {
        let allDates = getAllDatesInRange(dateObjects);
        let start_date = convertDateFromFormat(new Date(allDates[0]));
        let end_date = convertDateFromFormat(new Date(allDates[allDates?.length - 1]));
        setQueryParams((prev) => ({
            ...prev,
            startDate: start_date,
            endDate: end_date,
        }));
        setDateRange(dateObjects);

        let startDate = new Date(dateObjects[0]);
        let endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 89);
        if (endDate >= new Date()) {
            endDate = new Date();
            endDate.setDate(new Date().getDate() - 1);
        }
        setDateLimits({
            minDate: startDate,
            maxDate: endDate,
        });
    }

    const validateDate = (object) => {
        let errors = []
        if (!object?.user_id) {
            errors['user_id'] = "Required";
        } else {
            errors['user_id'] = "";
        }
        if (!object?.startDate) {
            errors['startDate'] = "Date is required";
        } else {
            errors['startDate'] = "";
        }

        setErrors((prev) => ({ ...prev, ...errors }));
        return Object.values(errors)?.filter((value) => !!value)?.length > 0;
    };

    const handleBlur = (name, value) => {
        if (!value) {
            setErrors((prev) => ({ ...prev, [name]: "Required" }));
        } else {
            setErrors((prev) => ({ ...prev, [name]: "" }));
        }
    }

    const handleSearch = () => {
        setIsLoading((prev) => ({ ...prev, report: true }));
        if (!validateDate(queryParams)) {
            getSummary();
        } else {
            setIsLoading((prev) => ({ ...prev, report: false }));
        }
    };

    function timeToDecimal(timeString) {
        let [hours, minutes, seconds] = timeString.split(':').map(parseFloat);
        let decimalHours = hours + minutes / 60 + seconds / 3600;
        return decimalHours > 0 ? decimalHours.toFixed(2) : null;
    }

    function decimalToTime(decimalHours) {
        let hours = Math.floor(decimalHours);
        let minutes = Math.round((decimalHours - hours) * 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

    const visibleRows = sortArrayByKey(summary?.results || [], sortType, orderBy);

    useEffect(() => {
        const seriesElements = document.querySelectorAll('.apexcharts-series');
        const seriesDataLength = seriesElements[0]?.querySelectorAll('.apexcharts-bar-area')?.length;

        for (let i = 0; i < seriesDataLength; i++) {
            const lastElements = [];

            seriesElements.forEach((series) => {
                const bar = series.querySelectorAll('.apexcharts-bar-area')[i];
                if (bar && bar.getAttribute('barHeight') !== '0') {
                    lastElements.push(bar);
                }
            })

            const lastElement = lastElements[lastElements.length - 1];

            if (lastElement) {
                lastElement.setAttribute('clip-path', 'inset(0% 0% -100% 0% round 10px)');
            }
        }
    }, [series])

    return (isLoading?.users ?
        <CircularLoader height='500px' /> :
        <React.Fragment>
            <Box width="100%" bgcolor="white" display="flex" flexDirection="column"
                sx={{
                    '> div:first-child': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        background: 'white',
                    }
                }}
            >
                <Breadcrumb isBack={true} pageTitle={"In-Out Working Report"} title={"reports"} onBackClick={() => navigate(URLS.Reports)} />
                <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} overflow="auto" flex={1}>
                    <Box display="flex" flexWrap={"wrap"} justifyContent="space-between" alignItems="center" columnGap={1.5} mb={1.5}>
                        <Grid mb={1.5} sx={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-start",
                            gap: 2,
                        }}>
                            <Grid item sx={{ width: { xs: 180, lg: 264 } }}>
                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                    <InputLabel id="range_label">Select User</InputLabel>
                                    <Select
                                        labelId="range_label"
                                        value={queryParams?.user_id || ""}
                                        onChange={(event, ...rest) => {
                                            setQueryParams((prev) => ({ ...prev, user_id: event?.target?.value }))
                                        }}
                                        name="user_id"
                                        id="user_id"
                                        label="Select User"
                                        sx={{
                                            ".MuiSelect-select": {
                                                fontSize: 14,
                                                fontWeight: 400,
                                                color: "dark.800",
                                            },
                                        }}
                                        onBlur={(e) => handleBlur("user_id", e.target.value)}
                                    >
                                        {organizationUserList?.map((item, index) => (
                                            <MenuItem key={item?.id} value={item?.id}>
                                                {titleCase(item?.name)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {!!errors?.user_id ? <Typography sx={{
                                        color: "red",
                                        fontSize: "12px"
                                    }}>{errors?.user_id}</Typography> : null}
                                </FormControl>
                            </Grid>
                            <Grid className='date-picker-with-filter' item sx={{
                                width: { xs: 220, lg: 264 },
                            }}>
                                <MenuDateRangePicker
                                    dateRange={dateRange}
                                    onChange={(dateRange) => handleChangeDatePicker(dateRange)}
                                    error={errors.startDate}
                                    maxDate={dateLimits?.maxDate}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    isButtonLoading={isLoading?.report}
                                    sx={{ color: "white", boxShadow: 'none' }}
                                    onClick={handleSearch}
                                    disabled={Object.values(errors)?.filter((value) => !!value)?.length > 0}
                                >
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid mb={1.5} item>
                            {!isLoading?.report ?
                                <Box sx={{ gap: 1, textAlign: "right" }}>
                                    <Typography color="dark.800" display="inline-flex" fontSize={13} fontWeight={400} lineHeight="18px" letterSpacing="0.17px">
                                        Working<span style={{ fontWeight: 'bold', paddingLeft: '4px' }}>{summary?.working_days} Days</span>, Non-working <span style={{ fontWeight: 'bold', padding: '0px 4px' }}>{summary?.non_working_days} Days</span>
                                    </Typography>
                                    <Grid display="inline-flex" color="dark.800" fontSize={13} fontWeight={400} lineHeight="18px" letterSpacing="0.17px">
                                        ( Including
                                        <HtmlTooltip
                                            title={
                                                <Typography fontSize={{ xs: 12, lg: 14 }}>Casual Leave</Typography>
                                            }>
                                            <Typography variant='span' borderBottomColor="dark.800" sx={{ borderBottom: '1px dashed', display: 'inline-block', ml: 0.5 }}>{summary?.cl_count} CL,</Typography>
                                        </HtmlTooltip>
                                        <HtmlTooltip
                                            title={
                                                <Typography fontSize={{ xs: 12, lg: 14 }}>Sick Leave</Typography>
                                            }>
                                            <Typography variant='span' borderBottomColor="dark.800" sx={{ borderBottom: '1px dashed', textDecorationStyle: 'dashed', display: 'inline-block', ml: 0.5 }}>{summary?.sl_count} SL </Typography>
                                        </HtmlTooltip>
                                        )
                                    </Grid>
                                </Box> : null}
                        </Grid>
                    </Box>
                    <Box>
                        <Paper sx={{ width: '100%', boxShadow: 'none', }}>
                            <Box>
                                <Paper
                                    sx={{
                                        border: "1px solid #E0E0E0",
                                        borderRadius: "3px",
                                        boxShadow: 'none',
                                    }}>
                                    <TableContainer>
                                        <Table
                                            sx={{ minWidth: 750 }}
                                            aria-labelledby="tableTitle"
                                            size={'medium'}
                                        >
                                            <TableHead>
                                                <TableRow sx={{
                                                    whiteSpace: 'nowrap',
                                                    'th': {
                                                        bgcolor: "bgColor",
                                                        color: "dark.800",
                                                        fontWeight: 500,
                                                        fontSize: 14,
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.17px",
                                                        padding: "5px 16px",
                                                        textAlign: "left",
                                                        background: "rgba(245, 248, 250, 1)",
                                                    }
                                                }}>
                                                    <TableCell>
                                                        <TableSortLabel
                                                            active={orderBy === "date"}
                                                            direction={orderBy === "date" ? sortType : "asc"}
                                                            onClick={() => handleSortClick("date")}
                                                        >
                                                            Date
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell>Day</TableCell>
                                                    <TableCell width={"150px"}>
                                                        <TableSortLabel
                                                            active={orderBy === "loginInTime"}
                                                            direction={orderBy === "loginInTime" ? sortType : "asc"}
                                                            onClick={() => handleSortClick("loginInTime")}
                                                        >
                                                            Login Time
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TableSortLabel
                                                            active={orderBy === "logoutTime"}
                                                            direction={orderBy === "logoutTime" ? sortType : "asc"}
                                                            onClick={() => handleSortClick("logoutTime")}
                                                        >
                                                            Logout Time
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TableSortLabel
                                                            active={orderBy === "working_hrs"}
                                                            direction={orderBy === "working_hrs" ? sortType : "asc"}
                                                            onClick={() => handleSortClick("working_hrs")}
                                                        >
                                                            <HtmlTooltip
                                                                title={
                                                                    <Typography fontSize={{ xs: 12, lg: 14 }}>Total Working Hours</Typography>
                                                                }>
                                                                <Typography variant='span' sx={{ textDecoration: 'underline', textDecorationStyle: 'dashed' }}>Total Working Hours</Typography>
                                                            </HtmlTooltip>
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TableSortLabel
                                                            active={orderBy === "non_working_hrs"}
                                                            direction={orderBy === "non_working_hrs" ? sortType : "asc"}
                                                            onClick={() => handleSortClick("non_working_hrs")}
                                                        >
                                                            <HtmlTooltip
                                                                title={
                                                                    <Typography fontSize={{ xs: 12, lg: 14 }}>Total Non-working Hours</Typography>
                                                                }>
                                                                <Typography variant='span' sx={{ textDecoration: 'underline', textDecorationStyle: 'dashed' }}>Total Non-working Hours</Typography>
                                                            </HtmlTooltip>
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TableSortLabel
                                                            active={orderBy === "total"}
                                                            direction={orderBy === "total" ? sortType : "asc"}
                                                            onClick={() => handleSortClick("total")}
                                                        >
                                                            <HtmlTooltip
                                                                title={
                                                                    <Typography fontSize={{ xs: 12, lg: 14 }}>Total Duration</Typography>
                                                                }>
                                                                <Typography variant='span' sx={{ textDecoration: 'underline', textDecorationStyle: 'dashed' }}>Total Duration</Typography>
                                                            </HtmlTooltip>
                                                        </TableSortLabel>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody
                                                sx={{
                                                    "tr:hover": {
                                                        background: "rgba(247, 247, 247, 1)",
                                                    },
                                                    td: {
                                                        color: "dark.800",
                                                        fontWeight: 400,
                                                        fontSize: 14,
                                                        lineHeight: "20px",
                                                        letterSpacing: "0.17px",
                                                        padding: "7px 16px",
                                                        textAlign: "left",
                                                        height: "45px",
                                                    },
                                                }}
                                            >
                                                {isLoading?.report ?
                                                    <TableRowSkeleton cols={5} rows={5} /> :
                                                    visibleRows?.length ? visibleRows?.map((report, index) => {
                                                        let dot = isShowDot(report);
                                                        return (<TableRow hover key={index} >
                                                            <TableCell width={130}>
                                                                <Stack sx={{ width: 130 }} direction="row" alignItems="center" justifyContent={"space-between"} gap={1}>
                                                                    <Typography>
                                                                        {report?.date}
                                                                    </Typography>
                                                                    <Box sx={{
                                                                        width: "10px",
                                                                        height: "10px",
                                                                    }}>
                                                                        <Box sx={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            borderRadius: "50%",
                                                                            bgcolor: dot?.isShow[1] ? CHART_COLOR[1] : "transparent",
                                                                        }}></Box>
                                                                    </Box>
                                                                    <Box sx={{
                                                                        width: "10px",
                                                                        height: "10px"
                                                                    }}>
                                                                        <Box sx={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            borderRadius: "50%",
                                                                            bgcolor: dot?.isShow[0] ? CHART_COLOR[0] : "transparent",
                                                                        }}></Box>
                                                                    </Box>
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(report?.date, "DD/MM/YYYY").format("dddd")}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: "4px",
                                                                }}>
                                                                    <Typography>
                                                                        {getDisplayValue(report?.loginInTime)}
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        color: "rgba(0, 0, 0, 0.6)",
                                                                        fontSize: "15px",
                                                                        fontWeight: "400",
                                                                    }}>
                                                                        {report?.loginInDate !== report?.date ? `(${moment(report?.loginInDate, "DD/MM/YYYY").format("DD/MM")})` : null}
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: "4px",
                                                                }}>
                                                                    <Typography>
                                                                        {getDisplayValue(report?.logoutTime)}
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        color: "rgba(0, 0, 0, 0.6)",
                                                                        fontSize: "15px",
                                                                        fontWeight: "400",
                                                                    }}>
                                                                        {report?.loginOutDate !== report?.date ? `(${moment(report?.loginOutDate, "DD/MM/YYYY").format("DD/MM")})` : null}
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: "4px",
                                                                }}>
                                                                    <Typography>
                                                                        {convertTime(report?.working_hrs, report?.total)?.time}
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        color: "rgba(0, 0, 0, 0.6)",
                                                                        fontSize: "15px",
                                                                        fontWeight: "400",
                                                                    }}>
                                                                        ({convertTime(report?.working_hrs, report?.total)?.percentage})
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: "4px",
                                                                }}>
                                                                    <Typography>
                                                                        {convertTime(report?.non_working_hrs, report?.total)?.time}
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        color: "rgba(0, 0, 0, 0.6)",
                                                                        fontSize: "15px",
                                                                        fontWeight: "400",
                                                                    }}>
                                                                        ({convertTime(report?.non_working_hrs, report?.total)?.percentage})
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                {convertTime(report?.total, report?.total)?.time}
                                                            </TableCell>
                                                        </TableRow>)
                                                    }) : <EmptyTable colSpan={7} />}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {/* {summaryDataCount ? <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={summaryDataCount}
                                        rowsPerPage={queryParams.perPage}
                                        page={queryParams.page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    /> : null} */}
                                </Paper>
                            </Box>
                        </Paper>
                    </Box>
                    {!isLoading?.report && series?.length > 0 && series?.[0]?.data?.length > 1 ?
                        <Box mt={3} width={"100%"} border={"1px solid #E0E0E0"} p={3} borderRadius={"4px"}>
                            <Box mb={5}>
                                <Grid sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}>
                                    <Grid item>
                                        <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"} >{titleCase(summary?.user?.name)} activity for {getDates()}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "16px",
                                        }}>
                                            <Grid item>
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "8px",
                                                }}>
                                                    <Box sx={{
                                                        backgroundColor: "#047FE0",
                                                        width: "16px",
                                                        height: "16px",
                                                        borderRadius: "4px",
                                                    }}></Box>
                                                    <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>Working hours</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item>
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "8px",
                                                }}>
                                                    <Box sx={{
                                                        backgroundColor: "#3F5C76",
                                                        width: "16px",
                                                        height: "16px",
                                                        borderRadius: "4px",
                                                    }}></Box>
                                                    <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>Non-working hours</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item>
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "8px",
                                                }}>
                                                    <Box sx={{
                                                        backgroundColor: "#047FE0",
                                                        width: "10px",
                                                        height: "10px",
                                                        borderRadius: "50%",
                                                    }}></Box>
                                                    <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>Work From Home</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <ReactApexChart type='bar' height={375} options={options} series={series} />
                            </Box>
                        </Box> : null}
                </Box>
            </Box>
        </React.Fragment>
    )
};

export default InOutReport;