/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import ICONS from "../../../../../constants/icons";
import TextEditor from "../../../../../components/TextEditor";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import FileDropZone from "../../../../../components/FileDropZone";
import { styled } from "@mui/styles";
import { getDefaultInOutTime, LEAVE_DURATION_TYPE, } from "../../../../../constants/default-values";
import SingleDatePicker from "../../../../../components/SingleDatePicker";
import axiosInstance from "../../../../../axios";
import API from "../../../../../axios/api";
// import TimePicker from "../../../../../components/TimePicker";
import moment from "moment";
import { useAlert } from "../../../../../hook/useAlert";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { getFormattedDate, getFullName, getReportingAuthority, sortArrayByKey, titleCase } from "../../../../../utils";
import Button from "../../../../../components/Button";
import MultipleDatePickerWithButton from "../../../../../components/MultipleDatePickerWithButton";
import { useNavigate } from "react-router-dom";
import URLS from "../../../../../routes/urls";
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import Breadcrumb from "../../../../../components/Breadcrumb";
import TimePickerClock from "../../../../../components/TimePickerClock";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "16px",
    borderRadius: "5px",
    background: "#3F5C76",
    maxWidth: 530,
  },
}));

const ApplyLeaveDetails = ({
  setIsAppliedLeave,
  setLeaveData,
  leaveData,
  userLeaveTypes,
  organizationUserList,
  currentUser,
  leaveSnapshot,
}) => {
  const showAlert = useAlert();
  const navigate = useNavigate();
  const { ignoreLeaveDates } = useSelector((state) => state?.leaveData);
  const RequestedToUserDetails = getReportingAuthority(organizationUserList, leaveData?.informed_authorities[0]);
  let specialCredits = leaveSnapshot?.length > 0 ? leaveSnapshot?.find((snap) => Object.keys(snap).includes("specialCredits")) : null;
  const AppliedLeaveType = userLeaveTypes?.find(
    (leaveType) =>
      leaveType?.user_leave_type_setting_id ===
      leaveData?.user_leave_type_setting_id
  );
  const [tempAddNewLeaves, setTempAddNewLeaves] = useState([]);
  const [isLoading, setIsLoading] = useState({
    formSubmitting: false,
    formButtonDisable: false,
    halfTimeNotEntered: false,
  });
  const [sortType, setSortType] = useState("asc");

  useEffect(() => {
    if (!!leaveData) {
      let leavedays = leaveData?.leave_days;
      let updatedErrors = { ...errors.leave_days };
      leavedays?.map((leaveDetail) => {
        let isHalfDay = Object.keys(LEAVE_DURATION_TYPE)[2] !== leaveDetail?.leave_duration
        let leave_duration_time =
          Object.keys(LEAVE_DURATION_TYPE)[0] === leaveDetail?.leave_duration
            ? "in_time"
            : "out_time"
        let leave_date = leaveDetail?.leave_date;
        if (isHalfDay && (!leaveDetail?.[leave_duration_time])) {
          updatedErrors[`${leave_date}`] = { [`time-${leave_date}`]: `Required` };
          // setIsLoading(prev => ({ ...prev, halfTimeNotEntered: true }));
        }
        return leaveDetail;
      })
      setErrors((prev) => ({ ...prev, leave_days: updatedErrors, }));
    }
  }, [])

  useEffect(() => {
    let sortedArray = sortArrayByKey(leaveData?.leave_days, sortType, 'leave_date');
    setLeaveData((prev) => ({
      ...prev,
      leave_days: sortedArray
    }));
  }, [sortType])

  const [errors, setErrors] = useState({
    leave_days: {},
    description: ''
  });

  const filterLeaveDays = (array) => {
    return array?.filter(value => !!value && Object.keys(value)?.includes('isAutoGenerated') ? !value?.isAutoGenerated : true);
  };

  const handleSubmitLeaveSummary = async (body) => {
    try {
      let payload = {
        ...body,
        leave_days: filterLeaveDays(body?.leave_days),
      };
      const response = await axiosInstance.post(
        API.applyLeaveSummary(currentUser?.id),
        payload
      );
      if (response.status === 200) {
        return response?.data?.result;
      } else {
        showAlert(response?.response?.data?.message, "error");
      }
      return;
    } catch (error) {
      console.error("Error:", error);
      showAlert(error?.response?.data?.message, "error");
      return;
    }
  };

  const handleSubmitLeaveSummaryWithoutCredit = async (body) => {
    try {
      let payload = {
        ...body,
        leave_days: filterLeaveDays(body?.leave_days),
      };
      const response = await axiosInstance.post(
        API.applyLeaveSummaryWithoutCredit(currentUser?.id),
        payload
      );
      if (response.status === 200) {
        return response?.data?.result;
      } else {
        showAlert(response?.response?.data?.message, "error");
      }
      return;
    } catch (error) {
      console.error("Error:", error);
      showAlert(error?.response?.data?.message, "error");
      return;
    }
  }

  const handleChangeAddRemoveLeaves = async (type, leaveIndex, leaveDetail) => {
    if (type === "remove") {
      let updatedLeaveDays = leaveData?.leave_days?.filter((leave, index) => index !== leaveIndex)?.map(item => ({ ...item, leave_date: item?.leave_date }))
      const payload = {
        user_leave_type_setting_id: leaveData?.user_leave_type_setting_id,
        leave_days: updatedLeaveDays,
        club_leave_count: leaveData?.club_leave_count || 0,
      };
      let response;
      if (specialCredits?.allow_special_credits) {
        response = await handleSubmitLeaveSummary(payload);
      }
      else {
        response = await handleSubmitLeaveSummaryWithoutCredit(payload)
      }
      if (!!response) {
        setLeaveData((prev) => ({
          ...prev,
          club_leave_count: response?.club_leave_count || 0,
          leave_days: response?.leave_days,
          remaining_days: response?.remaining_days,
          remaining_credits: response?.remaining_credits,
        }));
      }
    } else {
      try {
        let response;
        const addLeavesData = tempAddNewLeaves?.map((dates) => getFormattedDate(new Date(dates), "YYYY-MM-DD"));
        if (addLeavesData?.length > 0) {
          let updatedLeaveDays = [
            ...leaveData?.leave_days?.map(item => ({ ...item, leave_date: item?.leave_date, paid: item?.future_date ? 0 : item?.paid, unpaid: item?.future_date ? 0 : item?.unpaid })),
            ...addLeavesData?.map((date) => ({
              leave_date: date,
              leave_duration: "full",
              paid: 0,
              unpaid: 0,
            }))];
          const payload = {
            user_leave_type_setting_id: leaveData?.user_leave_type_setting_id,
            leave_days: updatedLeaveDays,
            club_leave_count: leaveData?.club_leave_count || 0,
          };
          if (specialCredits?.allow_special_credits) {
            response = await handleSubmitLeaveSummary(payload);
          }
          else {
            response = await handleSubmitLeaveSummaryWithoutCredit(payload)
          }
          if (!!response) {
            setLeaveData((prev) => ({
              ...prev,
              club_leave_count: response?.club_leave_count || 0,
              leave_days: response?.leave_days,
              remaining_days: response?.remaining_days,
              remaining_credits: response?.remaining_credits,
            }));
            setTempAddNewLeaves([]);
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleChangeSelectedDate = async (
    newSelectedDate,
    index,
    leaveDetail
  ) => {
    try {
      let response;
      let updatedLeaveDays = leaveData?.leave_days?.map((item, i) => i === index ? {
        ...leaveDetail,
        leave_date: getFormattedDate(new Date(newSelectedDate), "YYYY-MM-DD"),
      } : {
        ...item,
        leave_date: item?.leave_date,
      });
      const payload = {
        user_leave_type_setting_id: leaveData?.user_leave_type_setting_id,
        club_leave_count: leaveData?.club_leave_count || 0,
        leave_days: updatedLeaveDays,
      };
      if (specialCredits?.allow_special_credits) {
        response = await handleSubmitLeaveSummary(payload);
      }
      else {
        response = await handleSubmitLeaveSummaryWithoutCredit(payload)
      }
      if (!!response) {
        setLeaveData((prev) => ({
          ...prev,
          club_leave_count: response?.club_leave_count || 0,
          leave_days: response?.leave_days,
          remaining_days: response?.remaining_days,
          remaining_credits: response?.remaining_credits,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getTotalCreditUsedCount = (leave_array) => {
    let totalCreditUsed = 0;
    leave_array?.forEach((leave) => {
      totalCreditUsed += leave?.specialRequest?.length;
    });
    return totalCreditUsed || 0;
  };

  const getIgnoreDates = () => {
    let updatedList = [...leaveData?.leave_days?.map((value) => getFormattedDate(value?.leave_date)), ...ignoreLeaveDates]
    return Array.from(new Set(updatedList));
  }

  const isValidDateFormat = (date) => {
    return new Date(date).toString() !== "Invalid Date";
  };

  const handelTimeChange = (time, index, leaveDetail, leave_date) => {
    let updatedErrors = { ...errors.leave_days };
    if (!time || !isValidDateFormat(time)) {
      updatedErrors[`${leave_date}`] = { [`time-${leave_date}`]: `Required` };
      setIsLoading(prev => ({ ...prev, halfTimeNotEntered: true }));
    } else {
      delete updatedErrors[`${leave_date}`];

      let leavedays = leaveData?.leave_days;
      let isHalfDayTime = false;
      leavedays?.map((leave, leaveIndex) => {
        if (leaveIndex !== index) {
          let isHalfDay = Object.keys(LEAVE_DURATION_TYPE)[2] !== leave?.leave_duration
          let leave_duration_time =
            Object.keys(LEAVE_DURATION_TYPE)[0] === leave?.leave_duration
              ? "in_time"
              : "out_time"

          if (isHalfDay && !leave?.[leave_duration_time]) {
            isHalfDayTime = true;
          }
        }
        return leave;
      });
      setIsLoading(prev => ({ ...prev, halfTimeNotEntered: isHalfDayTime }));
    }
    setErrors((prev) => ({ ...prev, leave_days: updatedErrors, }));
    const updatedLeaveDetails = {
      ...leaveData,
      leave_days: leaveData?.leave_days?.map(
        (item, i) => {
          if (i === index) {
            let object = {
              ...item,
              [leaveDetail
                ?.leave_duration ===
                Object.keys(LEAVE_DURATION_TYPE)[0]
                ? "in_time"
                : "out_time"]:
                !!time && isValidDateFormat(time) ? new Date(time) : null,
            };
            if (object?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[0]) {
              delete object?.out_time;
            }
            else if (object?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[1]) {
              delete object?.in_time;
            } else {
              delete object?.in_time;
              delete object?.out_time;
            }
            return object;
          }
          return item;
        }
      ),
    };
    setLeaveData(updatedLeaveDetails);
  }

  const handleValidateTime = (index, leaveDetail, leave_date) => {
    let leave_duration_time =
      Object.keys(LEAVE_DURATION_TYPE)[0] === leaveDetail?.leave_duration
        ? "in_time"
        : "out_time"
    if (!leaveDetail[`${leave_duration_time}`]) {
      let updatedErrors = { ...errors?.leave_days };
      updatedErrors[`${leave_date}`] = { [`time-${leave_date}`]: `Required` };
      setErrors((prev) => ({ ...prev, leave_days: updatedErrors, }));
    }
  }

  const handleChangeLeaveType = async (event, index, leaveDetail) => {
    let initialValue = new Date();
    initialValue.setHours(14);
    initialValue.setMinutes(0);
    initialValue.setSeconds(0);

    let response;
    // let updatedLeaveDays = leaveData?.leave_days?.map((item, i) => i === index ? {
    //   ...item,
    //   leave_duration: event.target.value,
    //   leave_date: convertDateFromFormat(item?.leave_date)
    // } : { ...item, leave_date: convertDateFromFormat(item?.leave_date) });
    let updatedLeaveDays = leaveData?.leave_days?.map((item, i) => {
      if (i === index) {
        if (item?.leave_duration === "full") {
          if (item?.in_time) {
            delete item?.in_time
          }
          if (item?.out_time) {
            delete item?.out_time
          }
        }
        return {
          ...item,
          leave_duration: event.target.value,
          leave_date: (item?.leave_date)
        }
      } else {
        if (item?.leave_duration !== "full") {
          if (item?.in_time) {
            delete item?.out_time
          }
          if (item?.out_time) {
            delete item?.in_time
          }
        }
        return { ...item, leave_date: (item?.leave_date) }
      }
    });
    const payload = {
      user_leave_type_setting_id: leaveData?.user_leave_type_setting_id,
      leave_days: updatedLeaveDays,
      club_leave_count: leaveData?.club_leave_count || 0,
    };
    if (specialCredits?.allow_special_credits) {
      response = await handleSubmitLeaveSummary(payload);
    }
    else {
      response = await handleSubmitLeaveSummaryWithoutCredit(payload);
    }
    if (response) {
      // let halfTimeAvailable = false;
      let updatedLeaveDays = response?.leave_days?.map((item, i) => {
        if (i === index) {
          let { inTime, outTime } = getDefaultInOutTime();
          if (item?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[0]) {
            // item['in_time'] = moment(initialValue).format("HH:mm");
            item['in_time'] = inTime;
            delete item?.out_time;
            // halfTimeAvailable = true;
          }
          else if (item?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[1]) {
            // item['out_time'] = moment(initialValue).format("HH:mm");
            item['out_time'] = outTime;
            delete item?.in_time;
            // halfTimeAvailable = true;
          } else {
            delete item?.in_time;
            delete item?.out_time;
          }
          return item;
        }
        // if ((Object.keys(item)?.includes('in_time') && item?.in_time) || (Object.keys(item)?.includes('out_time') && item?.out_time)) halfTimeAvailable = true;
        return item;
      });
      // setIsLoading(prev => ({ ...prev, halfTimeNotEntered: halfTimeAvailable }));

      setLeaveData((prev) => ({
        ...prev,
        club_leave_count: response?.club_leave_count || 0,
        leave_days: updatedLeaveDays,
        remaining_days: response?.remaining_days,
        remaining_credits: response?.remaining_credits,
      }));
    }
  };

  const isValidateData = () => {
    let isEmpty = !leaveData?.description || leaveData?.description
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length === '' || errors?.description?.length;

    if (!errors?.description?.length && isEmpty) {
      setErrors((prev) => ({
        ...prev,
        description: 'Reason Required'
      }))
    } else {
      let reasonLength = leaveData?.description?.replace(/<(.|\n)*?>/g, '').trim()?.length
      isEmpty = reasonLength < 50 || reasonLength > 500;
      setErrors((prev) => ({
        ...prev,
        description: reasonLength ? (reasonLength < 50 || reasonLength > 500) ? 'Atleast 50 and up to 500 characters required' : ''
          : 'Reason Required'
      }))
    }
    setIsLoading((prev) => ({ ...prev, formButtonDisable: Object.keys(errors.leave_days)?.length && isEmpty }))
    return !!!Object.keys(errors.leave_days)?.length && !isEmpty;
  }

  const handleSubmitLeaveApplication = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, formSubmitting: true }))
      function convertObjectToFormData(obj) {
        const formData = new FormData();
        obj?.attachments?.filter((value) => !!value?.base64)?.forEach((attachment, index) => {
          formData.append(`attachments[${index}][name]`, attachment?.name);
          formData.append(`attachments[${index}][size]`, attachment?.size);
          formData.append(`attachments[${index}][type]`, attachment?.type);
          formData.append(`attachments[${index}][base64]`, attachment?.base64);
          formData.append(`attachments[${index}][unique_id]`, attachment?.unique_id);
          formData.append(`attachments[${index}][extension]`, `.${attachment?.name?.split('.').pop()}`);
        });
        formData.append(
          "user_leave_type_setting_id",
          obj.user_leave_type_setting_id
        );
        formData.append(
          "club_leave_count",
          obj?.club_leave_count || 0
        );
        formData.append("description", obj.description);
        formData.append("apply_date", getFormattedDate(obj.apply_date, "YYYY-MM-DD"));
        obj.informed_authorities?.forEach((authority, index) => {
          formData.append(`informed_authorities[${index}]`, authority?.id);
        });
        formData.append(
          "org_year_id",
          currentUser?.organization?.work_type_settings?.[0]?.OrgYear?.id
        );
        formData.append(
          "setting_start_date",
          currentUser?.organization?.work_type_settings?.[0]?.OrgYear?.setting_start_date
        );
        formData.append(
          "setting_end_date",
          currentUser?.organization?.work_type_settings?.[0]?.OrgYear?.setting_end_date
        );
        obj?.leave_days?.forEach((leaveDay, index) => {
          formData.append(
            `leave_days[${index}][leave_date]`,
            leaveDay?.leave_date
          );
          formData.append(
            `leave_days[${index}][leave_duration]`,
            leaveDay?.leave_duration
          );
          formData.append(
            `leave_days[${index}][credit_used]`,
            leaveDay?.rules?.length
          );
          formData.append(
            `leave_days[${index}][match]`,
            leaveDay?.match
          );
          formData.append(
            `leave_days[${index}][future_date]`,
            leaveDay?.future_date
          );
          leaveDay.specialRequest.forEach((specialReq, specialReqIndex) => {
            formData.append(
              `leave_days[${index}][special_request][${specialReqIndex}]`,
              // leaveDay?.future_date ? [] : specialReq
              specialReq
            );
          });
          if (leaveDay?.in_time) {
            formData.append(
              `leave_days[${index}][in_time]`,
              moment(leaveDay?.in_time).format("HH:mm")
            );
          }
          if (leaveDay?.out_time) {
            formData.append(
              `leave_days[${index}][out_time]`,
              moment(leaveDay?.out_time).format("HH:mm")
            );
          }
          formData.append(
            `leave_days[${index}][paid]`,
            leaveDay?.future_date ? 0 : (leaveDay?.paid || 0)
          );
          formData.append(
            `leave_days[${index}][unpaid]`,
            leaveDay?.future_date ? 0 : (leaveDay?.unpaid || 0)
          );
          if (!!leaveDay?.isAutoGenerated) {
            formData.append(
              `leave_days[${index}][isAutoGenerated]`,
              leaveDay?.isAutoGenerated || false
            );
          }
        });

        formData.append("remaining_days", obj?.remaining_days || 0);

        return formData;
      }

      if (isValidateData()) {
        const formData = convertObjectToFormData(leaveData);
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        const response = await axiosInstance.post(
          API.createLeaveApplication(currentUser?.id),
          formData,
          config
        );
        if (response.status === 200) {
          showAlert(response?.data.message);
          navigate(`${URLS.Leaves}?view=list&tabValue=1&groupBy=Status&status=active`);
        } else {
          showAlert(response?.response?.data?.message, "error");
        }
      }
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }))
    } catch (error) {
      console.error("Error:", error);
      showAlert(error?.response?.data?.message, "error");
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }))
    }
  };

  const handleChangeClick = () => {
    let updatedLeaveDetails = {
      ...leaveData,
      leave_days: filterLeaveDays(leaveData?.leave_days),
    };
    setLeaveData(updatedLeaveDetails);
    setTimeout(() => {
      setIsAppliedLeave(false);
    }, 0);
  }

  const getRemainingLeaveDaysData = () => {
    return leaveSnapshot?.find((data) => data?.leave_type?.toLowerCase() === AppliedLeaveType?.name?.toLowerCase())?.remaining_days
  }

  const getRemainingCreditsData = () => {
    return leaveSnapshot?.find((data) => Object.keys(data)?.includes('specialCredits'))
  }

  const getTotalRemainingCredits = () => {
    let totalCredits = getRemainingCreditsData()?.specialCreditsList?.reduce((currentData, currentValue) => {
      currentData.remaining += currentValue?.remaining > 0 ? currentValue?.remaining : 0;
      return currentData;
    }, { remaining: 0 });
    return totalCredits?.remaining
  }

  const handleClickBack = () => {
    navigate(-1);
  }

  const currentLeaveTypeData = () => {
    return leaveSnapshot?.find((data) => data?.leave_type?.toLowerCase() === AppliedLeaveType?.name?.toLowerCase());
  }

  const getMatchData = (leaveDetail) => {
    let usedSpecialRequest = [...leaveDetail?.specialRequest];
    const isExistRule = (creditListData) => {
      return usedSpecialRequest?.includes(creditListData?.id)
    }

    return (
      <>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="white" mb="0">
                {/* {leaveDetail?.match === 'fully' ? 'Match' : leaveDetail?.match === "true" ?
                  `${leaveDetail?.specialRequest?.length} credit will be used` : ' Request Status'} */}
                Request Status
              </Typography>
              <List
                sx={{
                  padding: 0,
                  // marginLeft: '25px',
                  li: {
                    listStyle: "decimal",
                    display: "list-item",
                    paddingLeft: '5px',
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '17px',
                    paddingBottom: '0px',
                    marginBottom: '0px'
                  },
                  'li:last-child': {
                    marginBottom: '0px'
                  }
                }}
              >
                {leaveDetail?.match === 'fully' ?
                  getRemainingCreditsData()?.specialCreditsList?.length ?
                    getRemainingCreditsData()?.specialCreditsList?.filter(data => data?.leave_credit)?.map((creditListData) => (
                      <ListItem sx={{ listStyle: 'none !important', paddingLeft: '0 !important', 'svg': { display: 'block', } }}>
                        <i style={{ background: '#ffffff', height: '18px', width: '18px', borderRadius: '50%', marginRight: '10px', display: 'inline-block', verticalAlign: 'Top' }}>
                          {ICONS.CheckIcon}
                        </i>{titleCase(creditListData?.name)}</ListItem>
                    )) : null : getRemainingCreditsData()?.specialCreditsList?.filter(data => data?.leave_credit)?.map((creditListData) => (
                      <ListItem sx={{ listStyle: 'none !important', paddingLeft: '0 !important', 'svg': { display: 'block', } }}>
                        <i style={{ background: '#ffffff', height: '18px', width: '18px', borderRadius: '50%', marginRight: '10px', display: 'inline-block', verticalAlign: 'Top' }}>
                          {isExistRule(creditListData) ? ICONS.CrossIcon : ICONS.CheckIcon}
                        </i>{titleCase(creditListData?.name)}</ListItem>
                    ))}
              </List>
            </React.Fragment>
          }
        >
          <i
            style={{
              height: 20,
              width: 20,
              display: "inline-block",
              verticalAlign: "middle",
            }}
          >
            {leaveDetail?.match === 'fully' ? <CheckCircleRoundedIcon
              color="primary"
              sx={{ fontSize: 20 }}
            /> : <ErrorOutlineRoundedIcon
              color={leaveDetail?.match === "true" ? "primary" : "error"}
              sx={{ fontSize: 20 }}
            />}
          </i>
        </HtmlTooltip>
      </>
    )
  }

  const getUsedCredits = (total, used) => {
    if (used > total) return `${total} + ${used - total}(Extra)`;
    else return used;
  }

  const getUsedLeaves = () => {
    let { used, extra } = currentLeaveTypeData();
    if (extra > 0) return `${used} +${extra} (Extra)`;
    else return used ?? 0;
  }

  const handleChangeDescription = (value) => {
    let tempVal = value;
    const isEmpty = !tempVal || tempVal?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim() === '';

    let reasonLength = value
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length;

    setIsLoading((prev) => ({ ...prev, formButtonDisable: (reasonLength < 50 || reasonLength > 500) }))
    setLeaveData({
      ...leaveData,
      description: value,
    })
    setErrors((prev) => ({
      ...prev,
      description: !isEmpty ? '' : 'Reason Required'
    }))
  }

  const handleBlurDescription = (value) => {
    let reasonLength = leaveData?.description
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length;

    setIsLoading((prev) => ({ ...prev, formButtonDisable: (reasonLength < 50 || reasonLength > 500) }))
    setErrors((prev) => ({
      ...prev,
      description: reasonLength ? (reasonLength < 50 || reasonLength > 500) ? 'Atleast 50 and up to 500 characters required' : ''
        : 'Reason Required'
    }))
  }

  const getReasonLength = () => {
    return leaveData?.description
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length;
  }

  const isAllFutureDateInclude = () => {
    return leaveData?.leave_days?.every(leaves => leaves?.future_date)
  }

  return (
    <>
      <Breadcrumb isBack={true} title={"My Leave"}
        pageTitle={'New Leave'}
        isPathShow={false}
        onBackClick={() => handleClickBack()} />
      <Box sx={{ padding: "26px !important" }}>
        <Box display={"flex"} alignItems={"flex-end"} justifyContent={"space-between"}>
          <Box
            width={475}
            maxWidth="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius={1}
            sx={{
              padding: "16px !important",
              background: "#F7F7F7",
              marginBottom: "16px",
            }}
          >
            <Grid Item>
              <Typography
                color="dark.800"
                fontSize={18}
                fontWeight={600}
                lineHeight="24px"
                letterSpacing="0.15px"
                sx={{ marginBottom: "6px" }}
              >
                {AppliedLeaveType?.name}
              </Typography>
              <Typography
                color="dark.800"
                fontSize={14}
                fontWeight={400}
                lineHeight="24px"
                letterSpacing="0.15px"
              >
                Request to:{" "}
                {`${getFullName(RequestedToUserDetails)} (${RequestedToUserDetails?.email})`}
              </Typography>
            </Grid>
            <Button
              variant="text"
              onClick={handleChangeClick}
              sx={{ textTransform: 'none', }}
              disabled={isLoading?.formSubmitting}
            >Change</Button>
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
            <Box sx={{
              padding: "12px 16px",
              gap: "4px",
              borderRadius: "8px",
              backgroundColor: "#DEF3FF",
              marginBottom: "16px",
            }}>
              <Typography fontSize={12} color="secondary.main" fontWeight={500} sx={{ letterSpacing: '0.15px', lineHeight: '18px' }} mb={0.5}>
                Available {titleCase(AppliedLeaveType?.name)}s
              </Typography>
              <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography fontSize={18} color="secondary.main" fontWeight={600} sx={{ letterSpacing: '0.15px', lineHeight: '24px' }} mr={2}>
                  {getRemainingLeaveDaysData() ?? 0} Days
                </Typography>
                {currentLeaveTypeData()?.extra > 0 ? <Grid display={"flex"} alignItems={"center"}>
                  <Typography color={"secondary"} fontSize={12} fontWeight={500} lineHeight={"20px"} letterSpacing={"0.15px"} borderBottom="1px dashed" borderBottomColor="secondary">
                    {`Extra ${currentLeaveTypeData()?.extra ?? 0} Leaves`}
                  </Typography>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color={"white"} fontSize={16} lineHeight={"17px"} fontWeight={500} mb={1.5}><span style={{ fontWeight: 'bold' }}>{currentLeaveTypeData()?.extra} Extra</span> Leaves taken </Typography>
                        <Typography variant="body1" color={"white"} fontSize={13} lineHeight={"19px"} fontWeight={500} mb={2}>Extra leaves includes all currently applied leaves too. If any leaves are rejected or cancelled, the leave balance will be adjusted accordingly.</Typography>
                        <Table >
                          <TableHead>
                            <TableRow
                              sx={{
                                'th': {
                                  padding: '10px 4px',
                                  color: 'white',
                                  fontSize: 12,
                                  lineHeight: '19px',
                                  fontWeight: 700,
                                  background: 'rgba(255,255,255,0.1)',
                                  border: 'none',
                                  '&:first-child': {
                                    padding: '10px',
                                  }
                                }
                              }}
                            >
                              <TableCell>Leaves</TableCell>
                              <TableCell align="center" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Initial Leaves</TableCell>
                              <TableCell align="center" sx={{ width: '70px', maxWidth: '70px', minWidth: '70px' }}>Used</TableCell>
                              <TableCell align="center" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Remaining</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              'td': {
                                padding: '4px 10px',
                                color: 'white',
                                fontSize: 14,
                                lineHeight: '19px',
                                fontWeight: 500,
                                border: 'none',
                              },
                              'tr:first-child td': {
                                paddingTop: '10px',
                              }
                            }}
                          >
                            <TableRow>
                              <TableCell>{titleCase(currentLeaveTypeData()?.leave_type)}</TableCell>
                              <TableCell align="center">{currentLeaveTypeData()?.alloted_days ?? 0}</TableCell>
                              <TableCell align="center">{getUsedLeaves()}</TableCell>
                              <TableCell align="center">{currentLeaveTypeData()?.remaining_days > 0 ? currentLeaveTypeData()?.remaining_days : 0 ?? 0}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </React.Fragment>
                    }
                  >
                    <InfoIcon color="secondary" sx={{ fontSize: "18px", marginLeft: "4px", marginTop: "2px" }} />
                  </HtmlTooltip>
                </Grid> : null}
              </Grid>
            </Box>

            <Box sx={{
              padding: "12px 16px",
              gap: "4px",
              borderRadius: "8px",
              backgroundColor: "#FFF6DF",
              marginBottom: "16px",
            }}>
              <Typography fontSize={12} color="secondary.main" fontWeight={500} sx={{ letterSpacing: '0.15px', lineHeight: '18px' }} mb={0.5}>
                Available Special Credits
              </Typography>
              <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography fontSize={18} color="secondary.main" fontWeight={600} sx={{ letterSpacing: '0.15px', lineHeight: '27px' }}>
                  {getTotalRemainingCredits() ?? 0}
                </Typography>
                <Grid display={"flex"} alignItems={"center"}>
                  <Typography color={"secondary"} fontSize={12} fontWeight={500} lineHeight={"20px"} letterSpacing={"0.15px"} borderBottom="1px dashed" borderBottomColor="secondary">
                    See details
                  </Typography>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color={"white"} fontSize={16} lineHeight={"17px"} fontWeight={500} mb={1.5}><span style={{ fontWeight: 'bold' }}>{getRemainingCreditsData()?.specialCreditUsed > 0 ? getRemainingCreditsData()?.specialCreditUsed : 0 ?? 0}</span>/{getRemainingCreditsData()?.specialCredits ?? 0} Special Credits used</Typography>
                        <Typography variant="body1" color={"white"} fontSize={13} lineHeight={"19px"} fontWeight={500} mb={2}>Special Credits includes all currently applied leaves. If any leaves are rejected or canceled, the special credit will be adjusted accordingly.</Typography>
                        <Table >
                          <TableHead>
                            <TableRow
                              sx={{
                                'th': {
                                  padding: '10px 4px',
                                  color: 'white',
                                  fontSize: 12,
                                  lineHeight: '19px',
                                  fontWeight: 700,
                                  background: 'rgba(255,255,255,0.1)',
                                  border: 'none',
                                  '&:first-child': {
                                    padding: '10px',
                                  }
                                }
                              }}
                            >
                              <TableCell>Credits</TableCell>
                              <TableCell align="center" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Initial Credit</TableCell>
                              <TableCell align="center" sx={{ width: '70px', maxWidth: '70px', minWidth: '70px' }}>Used</TableCell>
                              <TableCell align="center" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Remaining</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              'td': {
                                padding: '4px 10px',
                                color: 'white',
                                fontSize: 14,
                                lineHeight: '19px',
                                fontWeight: 500,
                                border: 'none',
                              },
                              'tr:first-child td': {
                                paddingTop: '10px',
                              }
                            }}
                          >
                            {getRemainingCreditsData()?.specialCreditsList?.length ?
                              getRemainingCreditsData()?.specialCreditsList?.map((creditData) => (
                                <TableRow>
                                  <TableCell>{titleCase(creditData?.name)}</TableCell>
                                  <TableCell align="center">{creditData?.allotment ?? 0}</TableCell>
                                  <TableCell align="center">{getUsedCredits(creditData?.allotment ?? 0, creditData?.used ?? 0)}</TableCell>
                                  <TableCell align="center">{creditData?.remaining > 0 ? creditData?.remaining : 0 ?? 0}</TableCell>
                                </TableRow>
                              )) : null}
                          </TableBody>
                        </Table>
                      </React.Fragment>
                    }
                  >
                    <InfoIcon color="secondary" sx={{ fontSize: "18px", marginLeft: "4px", marginTop: "2px" }} />
                  </HtmlTooltip>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          sx={{ border: "1px solid #E0E0E0", boxShadow: "none", mb: 3 }}
        >
          <Table aria-label="simple table">
            <TableHead sx={{ background: "rgba(0, 0, 0, 0.01)" }}>
              <TableRow
                sx={{
                  th: {
                    color: "dark.800",
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    padding: "6px 16px",
                    whiteSpace: 'nowrap',
                  },
                }}
              >
                <TableCell>
                  <TableSortLabel
                    active={true}
                    direction={sortType}
                    onClick={() => setSortType((prev) => prev === 'asc' ? 'desc' : 'asc')}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{ width: 115, maxWidth: 115, minWidth: 115 }}
                >Day</TableCell>
                <TableCell>Type</TableCell>
                {/* <TableCell width={160} align="center">
                  <span style={{ width: '50px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>Paid</span>|<span style={{ width: '50px', display: 'inline-block', marginLeft: '4px' }}>Unpaid</span>
                </TableCell> */}
                {specialCredits?.allow_special_credits ?
                  <>
                    <TableCell width={120}>
                      Match
                      {/* <i
                        style={{
                          width: "18px",
                          height: "18px",
                          display: "inline-block",
                          verticalAlign: "middle",
                          marginLeft: "3px",
                          color: "#3F5C76",
                        }}
                      >
                        {ICONS.Info}
                      </i> */}
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography fontSize={16} fontWeight={500} color="white" mb={1} lineHeight={"24px"} letterSpacing={"0.17px"}>
                              Match
                            </Typography>
                            <Typography fontSize={12} fontWeight={500} color="white" lineHeight={"18px"} maxWidth={286}>
                              When submitting your request, please be aware of the conditions that might use your credits for different situation.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <i
                          style={{
                            width: "18px",
                            height: "18px",
                            display: "inline-block",
                            verticalAlign: "middle",
                            marginLeft: "3px",
                            color: "#3F5C76",
                          }}
                        >
                          {ICONS.Info}
                        </i>
                      </HtmlTooltip>
                    </TableCell>
                    <TableCell width={110}>Credit</TableCell>
                  </>
                  : null}
                <TableCell width={90} align="center">
                  Remove
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaveData?.leave_days?.map((leaveDetail, index, leaveArray) => (
                <TableRow
                  key={index}
                  sx={{
                    td: {
                      color: "dark.800",
                      fontWeight: 400,
                      fontSize: 14,
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      padding: "7px 16px",
                      border: 0,
                      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    },
                  }}
                >
                  <TableCell
                    sx={{ width: 160, maxWidth: 160, minWidth: 160 }}
                  >
                    <Grid Item className="calendar-date-picker"
                      sx={{
                        'input': {
                          width: '100%',
                          height: 30,
                          border: 'none !important',
                          boxShadow: 'none !important',
                          borderRadius: '0px',
                          paddingBottom: '6px',
                          paddingLeft: '28px',
                          borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                          margin: '0',
                          position: 'relative',
                          background: 'transparent',
                          color: 'dark.800',
                          fontSize: 14,
                          fontWeight: 400,
                          lineHeight: '24px',
                          letterSpacing: '0.15px'
                        },
                        'input:disabled': {
                          color: 'rgb(170, 170, 170)',
                          borderColor: 'rgba(118, 118, 118, 0.3) !important',
                        },
                        'input:disabled:hover': {
                          color: 'rgb(170, 170, 170)',
                          borderColor: 'rgba(118, 118, 118, 0.3) !important',
                        },
                        'input:hover': {
                          borderBottomColor: '#333333 !important',
                        },
                        'input:focus': {
                          borderBottomColor: '#047FE0 !important',
                        },
                        '.rmdp-container': {
                          minWidth: '100px',
                        },
                      }}
                    >
                      <div style={{ height: 1 }}>
                        <CalendarTodayIcon
                          sx={{
                            fontSize: 20,
                            marginBottom: '-5px',
                            marginLeft: '2px',
                            color: ((Object.keys(leaveDetail)?.includes('isAutoGenerated') && leaveDetail?.isAutoGenerated)) ? "rgb(170, 170, 170) !important" : "#3F5C76",
                          }}

                        />
                      </div>
                      <SingleDatePicker
                        value={getFormattedDate(leaveDetail?.leave_date)}
                        ignoreDates={getIgnoreDates()}
                        onChange={(value) =>
                          handleChangeSelectedDate(value, index, leaveDetail)
                        }
                        isMultiple={false}
                        disabled={((Object.keys(leaveDetail)?.includes('isAutoGenerated') && leaveDetail?.isAutoGenerated) || false)}
                      />
                    </Grid>
                  </TableCell>
                  <TableCell
                    color="dark.800"
                    fontWeight={400}
                    fontSize={14}
                    lineHeight="24px"
                    letterSpacing="0.15px"
                  >
                    {new Date(moment(leaveDetail?.leave_date).format()).toLocaleString(
                      "en-us",
                      { weekday: "long" }
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0px 16px !important"
                    }}
                  >
                    <Grid Item sx={{ display: 'flex' }}>
                      <Grid Item maxWidth={{ xs: 130, xl: 170 }} width={{ xs: 130, xl: 170 }} mr={2}>
                        <FormControl
                          size="small"
                          variant="standard"
                          fullWidth
                          sx={{
                            height: "30px",
                            width: { xs: 130, xl: 170 },
                            ".MuiInputBase-root": {
                              height: "100%",
                            },
                          }}
                        >
                          <Select
                            labelId="select-label"
                            id="select-demo"
                            value={leaveDetail?.leave_duration || ""}
                            onChange={(event) => {
                              handleChangeLeaveType(event, index, leaveDetail)
                            }}
                            height="44px"
                            disabled={((Object.keys(leaveDetail)?.includes('isAutoGenerated') && leaveDetail?.isAutoGenerated) || false)}
                          >
                            {Object.entries(LEAVE_DURATION_TYPE).map(
                              (leaveType, index) => (
                                <MenuItem key={index} value={leaveType[0]}>
                                  {leaveType[1]}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid Item maxWidth={{ xs: 130, xl: 170 }} width={{ xs: 130, xl: 170 }}>
                        {leaveDetail?.leave_duration ===
                          Object.keys(LEAVE_DURATION_TYPE)[0] ||
                          leaveDetail?.leave_duration ===
                          Object.keys(LEAVE_DURATION_TYPE)[1] ? (
                          <Grid Item
                            sx={{
                              '.rmdp-container': {
                                minWidth: { xs: '130px !important', xl: '170px !important' },
                              },
                              'input': {
                                width: '100%',
                                height: 30,
                                border: 'none !important',
                                boxShadow: 'none !important',
                                borderRadius: '0px',
                                paddingBottom: '6px',
                                paddingLeft: '28px',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                                margin: '0',
                                position: 'relative',
                                background: 'transparent',
                                color: 'dark.800',
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.15px'
                              },
                              'input:hover': {
                                borderBottomColor: '#333333 !important',
                              },
                              'input:focus': {
                                borderBottomColor: '#047FE0 !important',
                              },
                            }}
                          >
                            <div style={{ height: 1 }}>
                              <ScheduleIcon
                                sx={{
                                  fontSize: 20,
                                  color: '#3F5C76',
                                  marginBottom: '-9px',
                                  marginLeft: '1px',
                                }}
                              />
                            </div>
                            <TimePickerClock
                              value={leaveDetail?.in_time || leaveDetail?.out_time}
                              placeholder={Object.keys(leaveDetail)?.includes('in_time') ? "Time to Come" : "Time to Leave"}
                              onChange={(time) => {
                                handelTimeChange(time, index, leaveDetail, leaveDetail?.leave_date)
                              }}
                              onClose={() => handleValidateTime(index, leaveDetail, leaveDetail?.leave_date)}
                            />
                            {!!errors?.leave_days?.[`${leaveDetail?.leave_date}`] ? <div style={{ height: '1px', position: 'relative' }}><div style={{ position: 'absolute', top: '100%', right: '0', background: '#E87160', borderRadius: '3px', padding: '2px 5px', marginTop: '2px' }}><span className="error-arrow-top"></span><Typography sx={{ color: "white", fontSize: '11px', fontWeight: 400, }}>{errors?.leave_days?.[`${leaveDetail?.leave_date}`]?.[`time-${leaveDetail?.leave_date}`]}</Typography></div></div> : null}
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  </TableCell>
                  {/* <TableCell width={160} align="center">
                    {!leaveDetail?.future_date ? <Grid display="flex" justifyContent="center">
                      <Typography sx={{ width: '50px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>{leaveDetail?.paid}</Typography> |
                      <Typography
                        color="error.main"
                        fontWeight={700}
                        sx={{ width: '50px', display: 'inline-block', textAlign: 'left', marginLeft: '4px' }}
                      >
                        {leaveDetail?.unpaid}
                      </Typography>
                    </Grid> : null}
                  </TableCell> */}
                  {specialCredits?.allow_special_credits ? <TableCell width={120}>
                    {!leaveDetail?.future_date ? getMatchData(leaveDetail) : null}
                  </TableCell>
                    : null}
                  {specialCredits?.allow_special_credits ? <TableCell
                    width={110}
                    color="dark.800"
                    fontWeight={400}
                    fontSize={14}
                    lineHeight="24px"
                    letterSpacing="0.15px"
                  >
                    {!!leaveDetail?.specialRequest?.length && !leaveDetail?.future_date
                      ? leaveDetail?.specialRequest?.length
                      : null}
                  </TableCell> : null}
                  <TableCell width={90} align="center">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      color="secondary"
                      sx={{ borderRadius: "4px" }}
                      disabled={leaveData?.leave_days?.length <= 1 || leaveDetail?.canDelete === false}
                      onClick={() =>
                        handleChangeAddRemoveLeaves(
                          "remove",
                          index,
                          leaveDetail
                        )
                      }
                    >
                      <DeleteIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow
                sx={{
                  td: {
                    padding: "4px 10px",
                    border: 0,
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <TableCell colSpan={specialCredits?.allow_special_credits ? 7 : 5}>
                  <MultipleDatePickerWithButton
                    ignoreDates={getIgnoreDates()}
                    onChange={(dates) => setTempAddNewLeaves(dates)}
                    onClose={() => handleChangeAddRemoveLeaves("add")}
                    selectedDates={tempAddNewLeaves}
                    setSelectedDates={setTempAddNewLeaves}
                  />
                </TableCell>
              </TableRow>
              {isAllFutureDateInclude() ? null :
                <TableRow
                  sx={{
                    td: {
                      padding: "10px 16px",
                      border: 0,
                      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    },
                  }}
                >
                  <TableCell colSpan={3}></TableCell>
                  {/* <TableCell width={160} align="center">
                    {(Number(totalPaidCount) === 0 && Number(totalUnPaidCount) === 0) ? null :
                      <Grid display="flex" justifyContent="center">
                        <Typography sx={{ width: '50px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>{totalPaidCount || 0} </Typography>
                        |
                        <Typography
                          color="error.main"
                          fontWeight={700}
                          sx={{ width: '50px', display: 'inline-block', textAlign: 'left', marginLeft: "4px" }}
                        >
                          {totalUnPaidCount || 0}
                        </Typography>
                      </Grid>}
                  </TableCell> */}
                  <TableCell></TableCell>
                  {specialCredits?.allow_special_credits ?
                    <TableCell colSpan={2}>
                      <Typography
                        color="error.main"
                        fontWeight={700}
                        sx={{ marginLeft: "0px" }}
                      >
                        {getTotalCreditUsedCount(leaveData?.leave_days)}{" "}
                        Credit Applied
                      </Typography>
                    </TableCell>
                    : null}
                </TableRow>}
            </TableBody>
            {isAllFutureDateInclude() ? null :
              <TableFooter
                sx={{ 'td': { padding: 0, } }}
              >
                <TableRow>
                  <TableCell colSpan={3}></TableCell>
                  {/* <TableCell>
                    <Grid
                      Item
                      width={160}
                      sx={{
                        background: "#DEF3FF",
                        padding: "10px 16px",
                      }}
                    >
                      <Typography
                        color="dark.800"
                        variant="body2"
                        lineHeight="24px"
                        letterSpacing="0.17px"
                        fontWeight={600}
                      >
                        Remaining {AppliedLeaveType?.name?.split(" ")?.map(word => word[0])?.join("")}:{" "}
                        {leaveData?.remaining_days || 0}
                      </Typography>
                    </Grid>
                  </TableCell> */}
                  <TableCell></TableCell>
                  {specialCredits?.allow_special_credits ?
                    <TableCell colSpan={2}>
                      <Grid
                        Item
                        width={200}
                        sx={{ background: "#FFF6DF", padding: "10px 16px" }}
                      >
                        <Typography
                          color="dark.800"
                          variant="body2"
                          lineHeight="24px"
                          letterSpacing="0.17px"
                          fontWeight={600}
                        >
                          Remaining Credits: {leaveData?.remaining_credits > 0 ? leaveData?.remaining_credits : 0}
                        </Typography>
                      </Grid>
                    </TableCell>
                    : null}
                </TableRow>
              </TableFooter>}
          </Table>
        </TableContainer>
        <Box mb={3}>
          <Typography
            variant="body2"
            fontWeight={400}
            sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
          >
            Reason*
          </Typography>
          <TextEditor
            value={leaveData.description}
            onChange={(value) =>
              handleChangeDescription(value)
            }
            onBlur={(value) => handleBlurDescription(value)}
          />
          <div style={{ height: '1px', position: 'relative' }}><div style={{ position: 'absolute', top: '100%', right: '0', background: '#FFF6DF', borderRadius: '3px', padding: '2px 5px', marginTop: '2px' }}><span className=""></span><Typography sx={{ color: "black", fontSize: '11px', fontWeight: 400, }}>{`${getReasonLength() ?? 0} / 500`}</Typography ></div ></div >
          {!!errors?.description?.length ? <Typography
            variant="body2"
            fontWeight={400}
            color={'error'}
            sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
          >
            {errors?.description}
          </Typography> : null}
        </Box>
        <Box mb={3}>
          <Grid Item display="flex" alignItems="center" mb={1}>
            <Typography
              variant="body1"
              color="dark.800"
              fontWeight={400}
              sx={{ lineHeight: "21px", letterSpacing: "0.15px" }}
              mr={1}
            >
              Attachments:
            </Typography>
            <Typography
              color="dark.600"
              fontSize={11}
              fontWeight={400}
              sx={{ lineHeight: "15px", letterSpacing: "0.17px" }}
            >
              (Upto 10MB, upto 5 attachments)
            </Typography>
          </Grid>
          <Box>
            <FileDropZone
              onDrop={(files) =>
                setLeaveData({
                  ...leaveData,
                  attachments: files,
                })
              }
              initialFiles={leaveData?.attachments}
              isConvertToBase64={true}
            />
          </Box>
        </Box>
        <Grid Item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              color: "white",
              textTransform: "none",
              marginRight: "20px",
              boxShadow: "none",
            }}
            type="button"
            onClick={handleSubmitLeaveApplication}
            isButtonLoading={isLoading.formSubmitting}
            disabled={!!Object.keys(errors.leave_days)?.length || isLoading?.formButtonDisable || isLoading?.halfTimeNotEntered}
          >
            Apply
          </Button>
          <Button
            variant="text"
            color="secondary"
            size="large"
            sx={{ textTransform: "none" }}
            onClick={() => navigate(`${URLS.Leaves}?view=list&tabValue=1&groupBy=Status&status=active`)}
            disabled={isLoading.formSubmitting}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default ApplyLeaveDetails;
