import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useAuthentication from '../../hook/useAuthentication';
import axiosInstance from '../../axios';
import API from '../../axios/api';
import CircularLoader from '../../components/CircularLoader';
import BackgroundLetterAvatars from '../../components/BackgroundLetterAvatars';
import EmptyPage from '../../components/EmptyPage';

const Repotees = () => {
  const { getCurrentUser } = useAuthentication();
  const currentUser = getCurrentUser();
  const [repotees, setRepotees] = useState([]);
  const [optionalRepotees, setOptionalRepotees] = useState([]);
  const [isLoading, setIsLoading] = useState({
    pageLoading: true,
  });
  const [sortType, setSortType] = useState("desc");
  const [orderBy, setOrderBy] = useState('');

  useEffect(() => {
    getUsersRepoteesData();
  }, [])

  useEffect(() => {
    handleSortData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType, orderBy])

  const sortArrayByKeyName = () => {
    let Array = [...optionalRepotees];
    return Array.sort((a, b) => {
      const valueA = orderBy === 'user' ? (a?.name || '') : orderBy === 'firstReporting' ? (a.reporting_authorities?.[0]?.name || '') : (a.second_reporting_authorities?.[0]?.name || '');
      const valueB = orderBy === 'user' ? (b?.name || '') : orderBy === 'firstReporting' ? (b.reporting_authorities?.[0]?.name || '') : (b.second_reporting_authorities?.[0]?.name || '');

      return sortType === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA)
    });
  }

  const handleSortData = () => {
    if (orderBy?.length) {
      let sortedArray = sortArrayByKeyName();
      setRepotees(sortedArray);
    }
  }

  const getUsersRepoteesData = async () => {
    try {
      const response = await axiosInstance.get(API.getRepoteesListByUserId)
      if (response?.status === 200) {
        setRepotees(response?.data?.userData);
        setOptionalRepotees(response?.data?.userData)
      }
      setIsLoading((prev) => ({ ...prev, pageLoading: false }))
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, pageLoading: false }))
    }
  }

  const createSortHandler = (property) => {
    const isAsc = (orderBy === property) ? (sortType === 'desc') ? true : false : true;
    setSortType(isAsc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  return (
    isLoading?.pageLoading ? <CircularLoader /> :
      <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
        <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} sx={{ background: '#ffffff' }} flex={1} overflow="hidden" display="flex" flexDirection="column">
          <Typography variant='h2' color={"dark.800"} fontSize={16} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.17px"} mb={2}>My Repotees</Typography>
          <Paper
            sx={{
              border: "1px solid #E0E0E0",
              borderRadius: "3px",
              boxShadow: 'none',
              maxHeight: '100%',
              overflow: 'hidden',
              '> div': {
                overflow: 'auto',
                height: '100%',
              }
            }}>
            <TableContainer>
              <Table>
                <TableHead
                  sx={{
                    position: 'sticky',
                    top: '0px',
                    zIndex: '1',
                    boxShadow: 'inset 0px -1px 0px #e0e0e0',
                    'th': {
                      color: "dark.800",
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: "24px",
                      letterSpacing: "0.17px",
                      padding: "5px 16px",
                      textAlign: "left",
                      background: "rgba(245, 248, 250, 1)",
                    },
                  }}
                >
                  <TableRow
                    sx={{
                      td: {
                        color: "dark.800",
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "20px",
                        letterSpacing: "0.17px",
                        padding: "7px 16px",
                        textAlign: "left",
                        height: "45px",
                        whiteSpace: 'nowrap'
                      },
                    }}
                  >
                    <TableCell>
                      <TableSortLabel
                        active={true}
                        direction={orderBy === 'user' ? sortType : 'desc'}
                        onClick={() => createSortHandler('user')}>
                        User
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={true}
                        direction={orderBy === 'firstReporting' ? sortType : 'desc'}
                        onClick={() => createSortHandler('firstReporting')}>
                        1st Reporting User
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={true}
                        direction={orderBy === 'secondReporting' ? sortType : 'desc'}
                        onClick={() => createSortHandler('secondReporting')}>
                        2nd Reporting User
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {repotees?.length ?
                    repotees?.map((repoteesData) => (
                      <TableRow
                        sx={{
                          "&:hover": {
                            background: "rgba(247, 247, 247, 1)",
                          },
                          'td': {
                            padding: '10px 16px',
                            maxWidth: '0px',
                            width: '30%',
                            minWidth: '30%',
                            '&:first-child': {
                              maxWidth: '0px',
                              width: '40%',
                              minWidth: '40%',
                            }
                          }
                        }}
                      >
                        <TableCell>
                          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
                            <Box width={24} height={24} minWidth={24} maxWidth={24} flex={"0 0 24px"} overflow={"hidden"} borderRadius={50} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                              {/* <img src='./assets/avatar/1.png' style={{ maxWidth: '100%', maxHeight: '100%', height: '100%', width: '100%', objectFit: 'cover', objectPosition: 'center center' }} /> */}
                              <BackgroundLetterAvatars user={repoteesData} />
                            </Box>
                            <Typography fontSize={14} color={"dark.800"} fontWeight={500} lineHeight={'20px'} letterSpacing={'0.17px'} textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={"nowrap"}>{repoteesData?.name}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {repoteesData?.reporting_authorities ? <Box display={"flex"} alignItems={"center"} gap={"6px"}>
                            <Box width={24} height={24} minWidth={24} maxWidth={24} flex={"0 0 24px"} overflow={"hidden"} borderRadius={50} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                              <BackgroundLetterAvatars user={repoteesData?.reporting_authorities?.[0]} />
                            </Box>
                            <Typography fontSize={14} color={"dark.800"} fontWeight={400} lineHeight={'20px'} letterSpacing={'0.17px'} textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={"nowrap"}>{repoteesData?.reporting_authorities?.[0]?.name}</Typography>
                            {currentUser?.id === repoteesData?.reporting_authorities?.[0]?.id ? <Typography fontSize={14} color={"dark.800"} fontWeight={600} lineHeight={'20px'} letterSpacing={'0.17px'}>(You)</Typography> : null}
                          </Box> : null}
                        </TableCell>
                        <TableCell>
                          {repoteesData?.second_reporting_authorities ? <Box display={"flex"} alignItems={"center"} gap={"6px"}>
                            <Box width={24} height={24} minWidth={24} maxWidth={24} flex={"0 0 24px"} overflow={"hidden"} borderRadius={50} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                              <BackgroundLetterAvatars user={repoteesData?.second_reporting_authorities?.[0]} />
                            </Box>
                            <Typography fontSize={14} color={"dark.800"} fontWeight={400} lineHeight={'20px'} letterSpacing={'0.17px'} textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={"nowrap"}>{repoteesData?.second_reporting_authorities?.[0]?.name}</Typography>
                            {currentUser?.id === repoteesData?.second_reporting_authorities?.[0]?.id ? <Typography fontSize={14} color={"dark.800"} fontWeight={600} lineHeight={'20px'} letterSpacing={'0.17px'}>(You)</Typography> : null}
                          </Box> : null}
                        </TableCell>
                      </TableRow>
                    ))
                    :
                    <TableRow>
                      <TableCell colSpan={3} sx={{ border: 'none', padding: '0px' }}>
                        <Box height={"100%"} width={"100%"}>
                          <EmptyPage isButtonShow={false} title="No Repotees found" height={"100%"} width={"100%"} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>
  )
}

export default Repotees