import React from 'react'
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import Toolbar from 'react-multi-date-picker/plugins/toolbar';
import { WEEKDAYS } from '../../constants/default-values';
import moment from 'moment';
import Button from '../Button';
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useSelector } from 'react-redux';
import useAuthentication from '../../hook/useAuthentication';


const MultipleDatePickerWithButton = ({ selectedDates, setSelectedDates, onChange, onClose, ignoreDates, extraDisableDates = [], ...rest }) => {
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();

    const { ignoreLeaveDates } = useSelector((state) => state?.leaveData);

    const getIgnoreDatesArray = () => {
        let array = [];
        if (ignoreDates?.length) {
            array = ignoreDates;
        } else {
            array = ignoreLeaveDates;
        }
        if (extraDisableDates?.length) {
            array = Array.from(new Set([...extraDisableDates, ...ignoreLeaveDates]));
        }
        return array;
    }



    return (
        <DatePicker
            value={selectedDates}
            onChange={(dates) => onChange(dates)}
            onClose={onClose}
            plugins={[
                <DatePanel markFocused />,
                <Toolbar
                    position="bottom"
                    names={{
                        today: "Today",
                        deselect: "Deselect",
                        close: "Select",
                    }}
                />,
            ]}
            format="DD/MM/YYYY"
            weekDays={WEEKDAYS}
            headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
            mapDays={({ date }) => {
                // let isPastDate = moment(new Date(date)).add(1, 'd').isBefore(new Date());
                let isPastDate = moment(new Date(date)).isBefore(currentUser?.organization?.createdAt || "01/01/2024");

                let isIgnoredDate = getIgnoreDatesArray()?.some((ignoreDate) => {
                    let date1 = moment(ignoreDate, 'DD/MM/YYYY').format("DD/MM/YYYY");
                    let date2 = moment(new Date(date)).format("DD/MM/YYYY");
                    return date1 === date2;
                })
                if (isPastDate || isIgnoredDate) {
                    return {
                        disabled: true,
                        style: { color: "#ccc" },
                    };
                }
            }}
            sort
            highlightToday
            render={
                <Button variant="text" startIcon={<AddCircleRoundedIcon />} sx={{ textTransform: 'none', }} >
                    Add New
                </Button>
            }
            {...rest}
        />
    )
}

export default MultipleDatePickerWithButton