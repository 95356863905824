/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    Stack,
    Tab,
    Tabs,
    Typography,
    FormControl,
    FormHelperText,
    Radio,
    RadioGroup,
    Tooltip,
    MenuItem,
    List,
    ListItem,
    Modal,
    DialogContent,
    Dialog,
    tooltipClasses,
} from "@mui/material";
import Button from "../../../../components/Button";
import PropTypes from "prop-types";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import { useAlert } from "../../../../hook/useAlert";
import useAuthentication from "../../../../hook/useAuthentication";
import LeaveTypeForm from "./LeaveTypeForm";
import { generateUniqueId, getFullName, getMissingKeys, isValidDays, sortArrayByKey } from "../../../../utils";
import CircularLoader from "../../../../components/CircularLoader";
import Input from "../../../../components/Input";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { usePageTitle } from "../../../../hook/useTitle";
import BackgroundLetterAvatars from "../../../../components/BackgroundLetterAvatars";
import { toast } from "react-toastify";
import CreateNewActivity from "./CreateNewActivity";
import ChooseWorkTypeActivity from "./ChooseWorkTypeActivity";
import { styled } from "@mui/styles";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        overflowX: 'hidden',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
    },
}));


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
};

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#3F5C76',
        color: '#ffffff',
        maxWidth: 330,
        padding: 12,
    },
}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const constantTypes = {
    work_type_settings: [],
    leaveTypes: [],
    specialRequestTypes: [],
};

const AdministratorLeaves = () => {
    const showAlert = useAlert();
    const { getCurrentUser } = useAuthentication();
    const { setPageTitle } = usePageTitle();
    const currentUser = getCurrentUser();
    const org_year_id = currentUser?.organization?.work_type_settings?.[0]?.org_year_id;
    const [tabValue, setTabValue] = useState(0);
    const [currentOpenTabWorkType, setCurrentOpenTabWorkType] = useState(null);
    const [formData, setFormData] = useState({});
    const [initialData, setInitialData] = useState({});
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: true,
        updateActivity: false,
    });
    const [constantType, setConstantType] = useState(constantTypes);
    const [errors, setErrors] = useState([]);
    const [organizationUserList, setOrganizationUserList] = useState([]);
    let initialModalDetails = {
        isShow: false,
        isApplyForSelectedUser: false,
        user_ids: [],
        errorMsg: "",
        isShowUsersList: false,
        isSelectAllUser: true,
    }
    const [modalDetails, setModalDetails] = useState(initialModalDetails);
    let initialLeaveTypeData = {
        id: null,
        leave_type_id: null,
        monthly_days: null,
        advance_days: null,
        max_leave_encashment: null,
    };
    const [isOpen, setIsOpen] = useState(false);
    const [isNewActivityOpen, setIsNewActivityOpen] = useState(false);
    const [currentWorkTypeData, setCurrentWorkTypeData] = useState({});
    const [allActivity, setAllActivity] = useState([]);
    const [currentWorkTypeActivity, setCurrentWorkTypeActivity] = useState([]);
    const [otherWorkTypeActivity, setOtherWorkTypeActivity] = useState([]);
    const [inputActivityValue, setInputActivityValue] = useState('');
    const [allSelectedActivity, setAllSelectedActivity] = useState([]);

    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    setPageTitle("Leaves Settings");

    useEffect(() => {
        getAllLeaveSettingData();
    }, [currentUser?.organization_id]);

    const getAllLeaveSettingData = () => {
        fetchData();
        getAllActivityData();
    }

    useEffect(() => {
        if (formData?.work_type_settings?.length) {
            let workType = formData?.work_type_settings?.find((workType) => {
                return workType?.name?.toLowerCase() === currentOpenTabWorkType?.name?.toLowerCase()
            })
            setCurrentWorkTypeData(workType || formData?.work_type_settings[0]);
        }
    }, [tabValue, formData?.work_type_settings]);

    useEffect(() => {
        if (allActivity?.length && Object.keys(currentWorkTypeData)?.length) {
            setCurrentWorkTypeActivity(currentWorkTypeData?.activities);
            setOtherWorkTypeActivity(allActivity?.filter((activityData) => !activityData?.org_work_type_ids?.includes(currentWorkTypeData?.id)))
        }
    }, [allActivity, currentWorkTypeData])

    const getAllActivityData = async () => {
        try {
            setLoading((prev) => ({ ...prev, activity: true }));
            const response = await axiosInstance.get(
                API.getAllActivity(currentUser?.organization_id)
            );
            if (response.status === 200) {
                setAllActivity(response?.data?.data);
            } else {
                toast.error(response?.data?.message);
                setAllActivity([]);
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }))
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, pageLoading: false }))
        }
    };

    const getUserSettingData = async () => {
        try {
            setLoading((prev) => ({ ...prev, users: true }));
            const response = await axiosInstance.get(
                `${API.organisationUserSetting(currentUser?.organization_id)}`
            );
            if (response.status === 200) {
                let sortedData = sortArrayByKey(response?.data?.data, 'asc', 'name');
                setOrganizationUserList(sortedData);
                setModalDetails((prev) => ({ ...prev, user_ids: sortedData?.map(item => item?.id) }));
                setLoading((prev) => ({ ...prev, users: false }))
            } else {
                setLoading((prev) => ({ ...prev, users: false }))
            }
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, users: false }))
        }
    };

    const fetchData = async () => {
        try {
            const [
                leaveSettingResponse,
                workTypesResponse,
                leaveTypesResponse,
                specialRequestTypesResponse,
            ] = await Promise.all([
                currentUser?.organization_id
                    ? axiosInstance.get(
                        API.organisationLeaveSettings(currentUser.organization_id)
                    )
                    : null,
                axiosInstance.get(API.getOrganisationWorkTypes),
                axiosInstance.get(API.getLeaveTypes),
                axiosInstance.get(API.getLeaveSpecialRequests),
            ]);

            if (workTypesResponse.status === 200) {
                let workTypeData = workTypesResponse?.data.data;
                workTypeData?.sort((a, b) =>
                    a?.name?.localeCompare(b?.name)
                );
                setConstantType((prev) => ({
                    ...prev,
                    work_type_settings: workTypeData?.map(data => ({ ...data, isChecked: false })),
                }));
            } else {
                showAlert(workTypesResponse.data?.message, "error");
            }

            if (leaveTypesResponse.status === 200) {
                setConstantType((prev) => ({
                    ...prev,
                    leaveTypes: leaveTypesResponse.data.data,
                }));
            } else {
                showAlert(leaveTypesResponse.data?.message, "error");
            }

            if (specialRequestTypesResponse.status === 200) {
                setConstantType((prev) => ({
                    ...prev,
                    specialRequestTypes: specialRequestTypesResponse.data.data,
                }));
            } else {
                showAlert(specialRequestTypesResponse.data?.message, "error");
            }

            if (leaveSettingResponse && leaveSettingResponse.status === 200) {
                setInitialData(leaveSettingResponse.data.data);
                // setFormData(leaveSettingResponse.data.data);
                handleCreateDataWorkType(
                    leaveSettingResponse.data.data,
                    workTypesResponse.data.data,
                    leaveTypesResponse.data.data,
                    specialRequestTypesResponse.data.data
                );
            } else if (leaveSettingResponse) {
                showAlert(leaveSettingResponse?.response?.data?.message, "error");
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        }
    };

    const handleCreateDataWorkType = (
        initialData,
        workType,
        leaveType,
        specialRequest
    ) => {
        let constantWorkType = [];
        let errorObject = [];
        const updatedFormData = {
            ...initialData,
            leave_frequency: initialData?.leave_frequency || "monthly",
            isLeaveSettings: initialData?.isLeaveSettings || false,
            allow_special_credits: initialData?.allow_special_credits || false,
            work_type_settings: workType?.map((workTypeData, index) => {
                let getInitialWorkTypeData = initialData?.work_type_settings?.find((value) => value?.name?.toLowerCase() === workTypeData?.name?.toLowerCase());
                const leaves = [];
                const specialRequests = [];
                if (getInitialWorkTypeData) {
                    constantWorkType.push({ id: workTypeData?.id, name: workTypeData?.name, isChecked: true });
                    getInitialWorkTypeData?.leave_type_settings?.filter((leave, index) => !!leave?.leave_type_id)?.forEach((leave) => {
                        leaves.push({
                            ...leave,
                            monthly_days: initialData?.leave_frequency === "monthly" ? leave?.monthly_days * 12 : leave?.monthly_days,
                            is_visible: true,
                            unique_id: generateUniqueId(),
                            is_changed: false,
                        });
                    });

                    getInitialWorkTypeData?.special_requests?.filter((leave, index) => !isNaN(leave?.allotment))?.forEach((req) => {
                        specialRequests.push({
                            ...req,
                            is_visible: true,
                            unique_id: generateUniqueId(),
                            is_changed: false,
                        });
                    });

                    errorObject.push({
                        id: getInitialWorkTypeData?.id,
                        name: getInitialWorkTypeData?.name,
                        max_monthly_leave_for_dd: !!getInitialWorkTypeData?.max_monthly_leave_for_dd ? "" : "Required",
                        leave_type_settings: leaves?.map((value, index, array) => {
                            return {
                                id: value?.id,
                                leave_type_id: !!value?.leave_type_id || (index !== 0 && array.length - 1) ? "" : "Required",
                                monthly_days: !!value?.monthly_days || (index !== 0 && array.length - 1) ? "" : "Required",
                                advance_days: value?.advance_days >= 0 || (index !== 0 && array.length - 1) ? "" : "Required",
                                max_leave_encashment: value?.max_leave_encashment >= 0 || (index !== 0 && array.length - 1) ? "" : "Required",
                                is_visible: true,
                                unique_id: generateUniqueId(),
                            }
                        }),
                        special_requests: specialRequests?.map((value, index, array) => {
                            return {
                                id: value?.id,
                                name: !!value?.name || (index !== 0 && array.length - 1) ? "" : array?.length === 1 ? "" : "Required",
                                allotment: !isNaN(value?.allotment) || (index !== 0 && array.length - 1) ? "" : array?.length === 1 ? "" : "Required",
                                is_visible: true,
                                unique_id: generateUniqueId(),
                            }
                        }),
                    })
                    if (leaves?.length < leaveType?.length && leaves?.length > 0) {
                        leaves.push({
                            ...initialLeaveTypeData,
                            is_visible: true,
                            unique_id: generateUniqueId(),
                            is_changed: false,
                        });
                    } else if (leaves?.length === 0) {
                        leaves.push({
                            ...initialLeaveTypeData,
                            is_visible: true,
                            unique_id: generateUniqueId(),
                            is_changed: false,
                        });
                    }
                    if (specialRequests?.length < specialRequest?.length && specialRequests?.length > 0) {
                        specialRequests.push({
                            id: null,
                            name: "",
                            allotment: "",
                            is_visible: true,
                            unique_id: generateUniqueId(),
                            is_initial: true,
                            is_changed: false,
                        });
                    } else if (specialRequests?.length === 0) {
                        specialRequests.push({
                            id: null,
                            name: "",
                            allotment: "",
                            is_visible: true,
                            unique_id: generateUniqueId(),
                            is_initial: true,
                            is_changed: false,
                        });
                    }

                    let finalWorkTypeData = {
                        ...getInitialWorkTypeData,
                        leave_type_settings: leaves,
                        special_requests: sortArrayByKey(specialRequests, "asc", "id"),
                        activities: sortArrayByKey(getInitialWorkTypeData?.activities, "asc", "title"),
                    }
                    return finalWorkTypeData;
                }
                else {
                    constantWorkType.push({ id: null, name: workTypeData?.name, isChecked: false });
                    errorObject.push({
                        id: null,
                        name: workTypeData?.name,
                        max_monthly_leave_for_dd: null,
                        leave_type_settings: [{
                            id: null,
                            leave_type_id: null,
                            monthly_days: null,
                            advance_days: null,
                            max_leave_encashment: null,
                            is_visible: true,
                            unique_id: generateUniqueId(),
                        }],
                        special_requests: [{
                            id: null,
                            name: null,
                            allotment: null,
                            is_visible: true,
                            unique_id: generateUniqueId(),
                        }]
                    })
                    return {
                        id: null,
                        name: workTypeData?.name,
                        max_monthly_leave_for_dd: null,
                        leave_type_settings: [{
                            ...initialLeaveTypeData,
                            is_visible: true,
                            unique_id: generateUniqueId(),
                            is_changed: false,
                        }],
                        special_requests: [{
                            id: null,
                            name: null,
                            allotment: null,
                            is_visible: true,
                            unique_id: generateUniqueId(),
                            is_changed: false,
                        }]
                    }
                }
            }),
        };
        errorObject.push({
            "max_absent_allowed": "",
        });
        errorObject.push({
            "renewal_month": "",
        });
        errorObject.push({
            "work_type_settings": "",
        });
        setErrors(errorObject);
        setFormData(updatedFormData);
        setConstantType((prev) => ({ ...prev, work_type_settings: constantWorkType }));
    };

    const handleChangeTab = (event, newValue) => {
        let selectedWorkType = constantType.work_type_settings.find((type, index) => type?.name.toLowerCase() === event.target.innerText.toLowerCase());
        setTabValue(newValue);
        setCurrentOpenTabWorkType(selectedWorkType);
    };

    const handleChangeCheckBox = (event, checked, tabType) => {
        setConstantType((prev) => {
            const updatedWorkTypes = [...prev.work_type_settings];
            const findIndex = updatedWorkTypes.findIndex(
                (tab) => tab.name === tabType
            );

            if (findIndex !== -1) {
                updatedWorkTypes[findIndex] = {
                    ...updatedWorkTypes[findIndex],
                    isChecked: checked,
                };
            }
            return { ...prev, work_type_settings: updatedWorkTypes };
        });
    };

    const handleChange = (e, value) => {
        let name = e.target.name;

        setFormData((prev) => ({
            ...prev,
            [name]: value || e.target.value,
        }));
    };

    const handleCancel = () => {
        fetchData();
    }

    const handleChangeUserCheckbox = (e) => {
        setModalDetails((prev) => ({
            ...prev,
            isApplyForSelectedUser: e.target.checked,
            errorMsg: "",
        }));
    }

    const handleSelectAllUser = (e) => {
        if (e.target.checked) {
            setModalDetails((prev) => ({
                ...prev,
                isSelectAllUser: e.target.checked,
                user_ids: organizationUserList?.map(item => item?.id),
                errorMsg: "",
            }));
        } else {
            setModalDetails((prev) => ({
                ...prev,
                isSelectAllUser: e.target.checked,
                user_ids: [],
                errorMsg: "Please select at least one user."
            }));
        }
    }

    const handleUserCheckboxCheck = (id) => {
        let isAlready = modalDetails?.user_ids?.includes(id);
        if (!isAlready) {
            if (!!modalDetails?.errorMsg) {
                setModalDetails((prev) => ({ ...prev, errorMsg: "" }));
            }
            setModalDetails((prev) => ({
                ...prev,
                user_ids: [...prev?.user_ids, id]
            }));
        } else {
            setModalDetails((prev) => ({
                ...prev,
                user_ids: prev?.user_ids?.filter((value) => value !== id),
                errorMsg: prev?.user_ids?.filter((value) => value !== id)?.length > 0 ? "" : "Please select at least one user.",
                isSelectAllUser: false,
            }));
        }
    }

    const handleSubmitLeaveSetting = async (type = "yes", action = null) => {
        try {
            setLoading((prev) => ({ ...prev, formSubmitting: true, noButtonLoading: type === "no" }));
            let updatedErrors = [...errors];
            let errorsTab = [];
            let globalError = [];

            // validate max_absent_allowed and renewal_month
            let maxAbsentAllowedIndex = errors?.findIndex((value) => Object.keys(value).includes("max_absent_allowed"));
            let renewalMonthIndex = errors?.findIndex((value) => Object.keys(value).includes("renewal_month"));
            let workTypeSettingsIndex = errors?.findIndex((value) => Object.keys(value).includes("work_type_settings"));
            if (!formData?.max_absent_allowed && formData?.max_absent_allowed !== 0) {
                updatedErrors[maxAbsentAllowedIndex]["max_absent_allowed"] = "Required";
                globalError.push('max_absent_allowed')
            } else {
                updatedErrors[maxAbsentAllowedIndex]["max_absent_allowed"] = "";
            }
            if (!formData?.renewal_month) {
                updatedErrors[renewalMonthIndex]["renewal_month"] = "Required";
                globalError.push('renewal_month')
            } else {
                updatedErrors[renewalMonthIndex]["renewal_month"] = "";
            }
            let isChecked = constantType?.work_type_settings?.some(item => item?.isChecked);
            if (isChecked) {
                updatedErrors[workTypeSettingsIndex]["work_type_settings"] = "";
                // validate tab data
                constantType?.work_type_settings?.forEach((value, parentIndex) => {
                    if (value?.isChecked === true) {
                        let isErrorInTab = false;
                        let workTypeIndex = formData?.work_type_settings?.findIndex((_value) => _value?.name === value?.name);
                        let workTypeData = formData?.work_type_settings[workTypeIndex];
                        if (!workTypeData["max_monthly_leave_for_dd"] && workTypeData["max_monthly_leave_for_dd"] !== 0) {
                            updatedErrors[workTypeIndex]["max_monthly_leave_for_dd"] = "Required";
                            isErrorInTab = true;
                        }
                        let errorsForLeaveTypeSettings = workTypeData?.leave_type_settings?.map((leave, leaveIndex) => {
                            let error = {};
                            if (leaveIndex === 0) {
                                error = getMissingKeys(leave, formData?.leave_frequency);
                            } else {
                                let isShowError = Object.values(getMissingKeys(leave, formData?.leave_frequency)).filter((value) => !!value).length !== ['leave_type_id', 'monthly_days', 'advance_days', 'max_leave_encashment'].length;
                                error = isShowError ? getMissingKeys(leave, formData?.leave_frequency) : {
                                    'leave_type_id': "",
                                    'monthly_days': "",
                                    'advance_days': "",
                                    'max_leave_encashment': "",
                                };
                            }
                            return error;
                        });
                        let filteredErrors2 = [];
                        let isErrorSet = false;
                        let isErrorSet2 = false;
                        if (!!formData?.allow_special_credits) {
                            let errorsForSpecialRequests = workTypeData?.special_requests?.map((leave, leaveIndex, array) => {
                                let error = {};
                                if (leaveIndex === 0) {
                                    error = getMissingKeys(leave, formData?.leave_frequency);
                                } else {
                                    let isShowError = Object.values(getMissingKeys(leave, formData?.leave_frequency)).filter((value) => !!value).length !== ['name', 'allotment'].length;
                                    let object = isShowError ? getMissingKeys(leave, formData?.leave_frequency) : {
                                        'name': "",
                                        'allotment': "",
                                    };
                                    error = object;
                                }
                                return error;
                            });

                            let blankLength = workTypeData?.special_requests?.filter((value) => !value?.id && !!isNaN(value?.allotment) && !value?.name).length
                            let isDeletedLength = workTypeData?.special_requests?.filter((value) => value?.is_deleted).length

                            if (errorsForSpecialRequests?.length === 2) {
                                let isAnyValueIsDeleted = workTypeData?.special_requests.some((value) => value?.is_deleted);
                                if (isAnyValueIsDeleted) {
                                    isErrorSet = true;
                                }
                            }

                            if (workTypeData?.special_requests?.length === (blankLength + isDeletedLength)) {
                                isErrorSet2 = true;
                            }

                            filteredErrors2 = errorsForSpecialRequests?.filter((value) => !!Object.values(value)?.filter((value) => !!value)?.length);
                            updatedErrors[workTypeIndex]["special_requests"] = (isErrorSet || isErrorSet2) ? [...errorsForSpecialRequests.slice(0, errorsForSpecialRequests?.length - 1), { ...errorsForSpecialRequests[errorsForSpecialRequests?.length - 1], name: "Required", allotment: "Required" }] : errorsForSpecialRequests;
                        }

                        let filteredErrors = errorsForLeaveTypeSettings?.filter((value) => !!Object.values(value)?.filter((value) => !!value)?.length);
                        updatedErrors[workTypeIndex]["leave_type_settings"] = errorsForLeaveTypeSettings;

                        if (isErrorInTab || Boolean(filteredErrors?.length) || Boolean(filteredErrors2?.length) || isErrorSet || isErrorSet2) {
                            errorsTab.push(parentIndex)
                            isErrorInTab = false;
                            isErrorSet = false;
                            isErrorSet2 = false;
                        }
                    }
                });
                setErrors(updatedErrors);
            } else {
                updatedErrors[workTypeSettingsIndex]["work_type_settings"] = "Required";
                globalError.push('work_type_settings')
            }

            if (!errorsTab?.length && !globalError?.length) {
                let body = {
                    id: formData?.id,
                    name: formData?.name,
                    leave_frequency: formData?.leave_frequency,
                    max_absent_allowed: formData?.max_absent_allowed,
                    renewal_month: formData?.renewal_month,
                    allow_special_credits: formData?.allow_special_credits,
                    work_type_settings:
                        constantType?.work_type_settings?.map((work_type) => {
                            let workTypeData = formData?.work_type_settings?.find((value) => value?.name === work_type?.name);
                            let getLeavesTypeSettings = () => {
                                let deletedLeave;
                                let initialWorkTypeData = initialData?.work_type_settings?.find((value) => value?.name === work_type?.name)?.leave_type_settings;
                                let array = workTypeData?.leave_type_settings?.filter(leave => (!!leave?.leave_type_id && !!leave?.monthly_days && (!!leave?.advance_days || leave?.advance_days === 0) && (!!leave?.max_leave_encashment || leave?.max_leave_encashment === 0)))?.map((leave) => {
                                    deletedLeave = initialWorkTypeData?.filter(initialObj => !workTypeData?.leave_type_settings?.some(updatedObj => updatedObj?.id === initialObj?.id));
                                    let object = {
                                        id: leave?.id || null,
                                        leave_type_id: leave?.leave_type_id,
                                        monthly_days: leave?.monthly_days,
                                        advance_days: leave?.advance_days,
                                        max_leave_encashment: leave?.max_leave_encashment,
                                        is_changed: leave?.is_changed,
                                        org_year_id: org_year_id,
                                    }
                                    if (leave?.is_deleted) {
                                        object['is_deleted'] = true;
                                    }
                                    return object;
                                });
                                if (!!deletedLeave && Object.keys(deletedLeave)) {
                                    deletedLeave.forEach((value) => {
                                        array.push({ ...value, is_deleted: true, });
                                    })
                                }
                                return array;
                            };
                            let getSpecialRequests = () => {
                                let array = workTypeData?.special_requests?.map((leave) => {
                                    let object = {
                                        id: leave?.id || null,
                                        name: leave?.name,
                                        allotment: !isNaN(leave?.allotment) ? leave?.allotment : null,
                                        is_changed: leave?.is_changed,
                                        org_year_id: org_year_id,
                                    }
                                    if ((leave?.id && formData?.allow_special_credits === false) || (leave?.id && !work_type?.isChecked)) {
                                        object['is_deleted'] = true;
                                    }
                                    if (leave?.is_deleted) {
                                        object['is_deleted'] = true;
                                    }
                                    return object;
                                });
                                let updatedArray = array?.filter(value => !isNaN(value?.allotment) && !!value?.name);
                                return updatedArray;
                            }
                            let object = {
                                name: workTypeData?.name,
                                max_monthly_leave_for_dd: workTypeData?.max_monthly_leave_for_dd,
                                leave_type_settings: getLeavesTypeSettings(),
                                special_requests: getSpecialRequests(),
                            }
                            if (workTypeData?.id) {
                                object['id'] = workTypeData?.id;
                            } else {
                                object['id'] = null;
                            }
                            if (!work_type?.isChecked) {
                                object['is_deleted'] = true;
                            }
                            if (object?.id === null && object?.is_deleted === true)
                                return null;
                            else
                                return object;
                        }).filter(value => !!value),
                }
                try {
                    let response;
                    if (action === "update-with-users") {
                        let payload = {
                            "user_ids": modalDetails?.user_ids,
                            "organization_id": body?.id,
                            "leave_frequency": body?.leave_frequency,
                            "allow_special_credits": body?.allow_special_credits,
                            "work_type_settings": body?.work_type_settings?.map((value) => ({
                                "id": value?.id,
                                "name": value?.name,
                                "max_monthly_leave_for_dd": value?.max_monthly_leave_for_dd,
                                "leave_type_settings": value?.leave_type_settings?.map((leave) => {
                                    let object = {
                                        id: leave?.id || null,
                                        leave_type_id: leave?.leave_type_id,
                                        monthly_days: leave?.monthly_days,
                                        advance_days: leave?.advance_days,
                                        max_leave_encashment: leave?.max_leave_encashment,
                                        is_changed: leave?.is_changed,
                                    }
                                    if (leave?.is_deleted) {
                                        object['is_deleted'] = true;
                                    }
                                    if (!object?.id && object?.is_changed) {
                                        delete object?.is_changed;
                                    }
                                    return object;
                                }),
                                "special_requests": value?.special_requests?.map((request) => {
                                    let obj = {
                                        "id": request?.id,
                                        "name": request?.name,
                                        "allotment": request?.allotment,
                                        "active": request?.active,
                                        "is_deleted": request?.is_deleted,
                                        "is_changed": request?.is_changed,
                                    };
                                    if (!obj?.id && obj?.is_changed) {
                                        delete obj?.is_changed;
                                    }
                                    return obj;
                                }),
                            }))
                        }
                        await axiosInstance.put(
                            API.organisationLeaveSettings(currentUser?.organization_id),
                            body
                        );
                        response = await axiosInstance.put(
                            API.organisationUsersLeaveSettings,
                            payload
                        );
                    } else {
                        response = await axiosInstance.put(
                            API.organisationLeaveSettings(currentUser?.organization_id),
                            body
                        );
                    }
                    if (response.status === 200) {
                        showAlert(response.data?.message, "success");
                        setModalDetails(initialModalDetails);
                        fetchData();
                    }
                    setLoading((prev) => ({ ...prev, formSubmitting: false, noButtonLoading: false, }));
                } catch (error) {
                    showAlert("Something went wrong.", "error");
                    setLoading((prev) => ({ ...prev, formSubmitting: false, noButtonLoading: false, }));
                    setModalDetails(initialModalDetails);
                    fetchData();
                }
            } else {
                setTabValue(globalError?.length > 0 ? 0 : errorsTab[0]);
            }
            setLoading((prev) => ({ ...prev, formSubmitting: false, noButtonLoading: false, }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, formSubmitting: false, noButtonLoading: false, }));
        }
    };

    const isValidateFormData = () => {
        setLoading((prev) => ({ ...prev, formSubmitting: true, }));
        let updatedErrors = [...errors];
        let errorsTab = [];
        let globalError = [];

        // validate max_absent_allowed and renewal_month
        let maxAbsentAllowedIndex = errors?.findIndex((value) => Object.keys(value).includes("max_absent_allowed"));
        let renewalMonthIndex = errors?.findIndex((value) => Object.keys(value).includes("renewal_month"));
        let workTypeSettingsIndex = errors?.findIndex((value) => Object.keys(value).includes("work_type_settings"));
        if (!formData?.max_absent_allowed && formData?.max_absent_allowed !== 0) {
            updatedErrors[maxAbsentAllowedIndex]["max_absent_allowed"] = "Required";
            globalError.push('max_absent_allowed')
        } else {
            updatedErrors[maxAbsentAllowedIndex]["max_absent_allowed"] = "";
        }
        if (!formData?.renewal_month) {
            updatedErrors[renewalMonthIndex]["renewal_month"] = "Required";
            globalError.push('renewal_month')
        } else {
            updatedErrors[renewalMonthIndex]["renewal_month"] = "";
        }
        let isChecked = constantType?.work_type_settings?.some(item => item?.isChecked);
        if (isChecked) {
            updatedErrors[workTypeSettingsIndex]["work_type_settings"] = "";
            // validate tab data
            constantType?.work_type_settings?.forEach((value, parentIndex) => {
                if (value?.isChecked === true) {
                    let isErrorInTab = false;
                    let workTypeIndex = formData?.work_type_settings?.findIndex((_value) => _value?.name === value?.name);
                    let workTypeData = formData?.work_type_settings[workTypeIndex];
                    if (!workTypeData["max_monthly_leave_for_dd"] && workTypeData["max_monthly_leave_for_dd"] !== 0) {
                        updatedErrors[workTypeIndex]["max_monthly_leave_for_dd"] = "Required";
                        isErrorInTab = true;
                    }
                    let errorsForLeaveTypeSettings = workTypeData?.leave_type_settings?.map((leave, leaveIndex) => {
                        let error = {};
                        if (leaveIndex === 0) {
                            error = getMissingKeys(leave, formData?.leave_frequency);
                        } else {
                            let isShowError = Object.values(getMissingKeys(leave, formData?.leave_frequency)).filter((value) => !!value).length !== ['leave_type_id', 'monthly_days', 'advance_days', 'max_leave_encashment'].length;
                            error = isShowError ? getMissingKeys(leave, formData?.leave_frequency) : {
                                'leave_type_id': "",
                                'monthly_days': "",
                                'advance_days': "",
                                'max_leave_encashment': "",
                            };
                        }
                        return error;
                    });
                    let filteredErrors2 = [];
                    let isErrorSet = false;
                    let isErrorSet2 = false;
                    if (!!formData?.allow_special_credits) {
                        let errorsForSpecialRequests = workTypeData?.special_requests?.map((leave, leaveIndex, array) => {
                            let error = {};
                            if (leaveIndex === 0) {
                                error = getMissingKeys(leave, formData?.leave_frequency);
                            } else {
                                let isShowError = Object.values(getMissingKeys(leave, formData?.leave_frequency)).filter((value) => !!value).length !== ['name', 'allotment'].length;
                                let object = isShowError ? getMissingKeys(leave, formData?.leave_frequency) : {
                                    'name': "",
                                    'allotment': "",
                                };
                                error = object;
                            }
                            return error;
                        });

                        let blankLength = workTypeData?.special_requests?.filter((value) => !value?.id && !!isNaN(value?.allotment) && !value?.name).length
                        let isDeletedLength = workTypeData?.special_requests?.filter((value) => value?.is_deleted).length

                        if (errorsForSpecialRequests?.length === 2) {
                            let isAnyValueIsDeleted = workTypeData?.special_requests.some((value) => value?.is_deleted);
                            if (isAnyValueIsDeleted) {
                                isErrorSet = true;
                            }
                        }

                        if (workTypeData?.special_requests?.length === (blankLength + isDeletedLength)) {
                            isErrorSet2 = true;
                        }

                        filteredErrors2 = errorsForSpecialRequests?.filter((value) => !!Object.values(value)?.filter((value) => !!value)?.length);
                        updatedErrors[workTypeIndex]["special_requests"] = (isErrorSet || isErrorSet2) ? [...errorsForSpecialRequests.slice(0, errorsForSpecialRequests?.length - 1), { ...errorsForSpecialRequests[errorsForSpecialRequests?.length - 1], name: "Required", allotment: "Required" }] : errorsForSpecialRequests;
                    }

                    let filteredErrors = errorsForLeaveTypeSettings?.filter((value) => !!Object.values(value)?.filter((value) => !!value)?.length);
                    updatedErrors[workTypeIndex]["leave_type_settings"] = errorsForLeaveTypeSettings;

                    if (isErrorInTab || Boolean(filteredErrors?.length) || Boolean(filteredErrors2?.length) || isErrorSet || isErrorSet2) {
                        errorsTab.push(parentIndex)
                        isErrorInTab = false;
                        isErrorSet = false;
                        isErrorSet2 = false;
                    }
                }
            });
            setErrors(updatedErrors);
        } else {
            updatedErrors[workTypeSettingsIndex]["work_type_settings"] = "Required";
            globalError.push('work_type_settings')
        }
        if (!errorsTab?.length && !globalError?.length) {
            setLoading((prev) => ({ ...prev, formSubmitting: false, }));
            return false;
        } else {
            setLoading((prev) => ({ ...prev, formSubmitting: false, }));
            return true;
        }
    }

    const handleOpenModal = () => {
        if (!isValidateFormData()) {
            setModalDetails((prev) => ({
                ...prev,
                isShow: true,
            }))
        }
    };

    const onBack = () => {
        setModalDetails((prev) => ({ ...prev, errorMsg: "", isShowUsersList: false, user_ids: [], isApplyForSelectedUser: false, }));
    };

    const onApprove = () => {
        if (!!modalDetails?.isApplyForSelectedUser && !modalDetails?.isShowUsersList) {
            getUserSettingData();
            setModalDetails((prev) => ({ ...prev, errorMsg: "", isShowUsersList: true }));
        } else if (!!modalDetails?.isApplyForSelectedUser && modalDetails?.isShowUsersList && modalDetails?.user_ids?.length <= 0) {
            setModalDetails((prev) => ({ ...prev, errorMsg: "Please select at least one user." }))
        } else {
            handleSubmitLeaveSetting("yes", "update-with-users")
        }
    };

    const onClose = () => {
        if (modalDetails?.isShowUsersList) {
            setModalDetails(initialModalDetails);
        } else {
            handleSubmitLeaveSetting("no");
        }
    };

    const findIndexWithUid = (array, unique_id) => {
        return array.findIndex((data) => data?.unique_id === unique_id);
    };

    const findIndexWorkType = formData?.work_type_settings?.findIndex(
        (type, index) => type?.name === constantType[index]?.name
    );
    const workTypeData = formData?.work_type_settings?.[findIndexWorkType];

    const handleReleaseFrequencyChange = (e) => {
        handleChange(e);
        const { value } = e.target;
        const updatedErrors = [...errors];

        updatedErrors[findIndexWorkType]?.leave_type_settings?.forEach(
            (leaveTypeData, index, array) => {
                let leaveIndex = findIndexWithUid(array, leaveTypeData?.unique_id);
                const monthlyDays =
                    workTypeData["leave_type_settings"][leaveIndex]["monthly_days"];
                if (value === "monthly") {
                    updatedErrors[findIndexWorkType]["leave_type_settings"][leaveIndex][
                        "monthly_days"
                    ] = Boolean(monthlyDays)
                            ? isValidDays(monthlyDays)
                                ? ""
                                : "Please enter valid days which is divisible by 12 or 6"
                            : "";
                } else {
                    updatedErrors[findIndexWorkType]["leave_type_settings"][leaveIndex][
                        "monthly_days"
                    ] = "";
                }
            }
        );
        setErrors(updatedErrors);
    };

    let maxAbsentAllowedIndex = errors?.findIndex((value) =>
        Object.keys(value).includes("max_absent_allowed")
    );

    let renewalMonthIndex = errors?.findIndex((value) =>
        Object.keys(value).includes("renewal_month")
    );

    const handleMaxAbsentChange = (e) => {
        handleChange(e, parseInt(e.target.value));
        const updatedErrors = [...errors];
        const maxAbsentError = isNaN(parseInt(e.target.value)) || parseInt(e.target.value) < 0 ? "Required" : "";
        updatedErrors[maxAbsentAllowedIndex]["max_absent_allowed"] = maxAbsentError;
        setErrors(updatedErrors);
    };

    const handleRenewalMonthChange = (e) => {
        let name = e.target.name;
        setFormData((prev) => ({
            ...prev,
            [name]: e.target.value,
        }));
        const updatedErrors = [...errors];
        const renewalMonthError = !e.target.value ? "Required" : "";
        updatedErrors[renewalMonthIndex]["renewal_month"] = renewalMonthError;
        setErrors(updatedErrors);
    }

    const handleChangeCreditCheckBox = (e) => {
        // Functional update for immutability
        setFormData((prevFormData) => {
            let getSpecialRequests = (workTypeSetting) => {
                let updatedLeaves = workTypeSetting?.special_requests?.map(value => {
                    if (e.target.checked === false) {
                        if (!!value?.id) {
                            return { ...value, is_deleted: true };
                        }
                        else {
                            return null;
                        }
                    }
                    return value;
                })
                let filteredData = updatedLeaves?.filter((value, index, array) => !!value);
                let lastData = filteredData[filteredData?.length - 1];
                if (filteredData?.length === 0) {
                    filteredData.push({
                        id: null,
                        allotment: null,
                        name: null,
                        is_visible: true,
                        unique_id: generateUniqueId()
                    })
                } else if (filteredData?.length >= 2 && !isNaN(lastData?.allotment) && !!lastData?.name) {
                    filteredData.push({
                        id: null,
                        allotment: null,
                        name: null,
                        is_visible: true,
                        unique_id: generateUniqueId()
                    })
                }
                return filteredData;
            };

            return {
                ...prevFormData,
                allow_special_credits: e.target.checked,
                work_type_settings: prevFormData?.work_type_settings?.map((workTypeSetting) => ({
                    ...workTypeSetting,
                    special_requests: getSpecialRequests(workTypeSetting),
                }))
            }
        });
    };

    const handleOpenChangeActivity = () => {
        setIsOpen(true);
    }

    const handleModalClose = () => {
        setIsOpen(false);
        setIsNewActivityOpen(false);
    }

    const handleChooseActivity = async () => {
        try {
            if (allSelectedActivity?.length) {
                setLoading((prev) => ({ ...prev, updateActivity: true }));
                let payload = {
                    id: currentWorkTypeData?.id,
                    activities_id: allSelectedActivity?.map((activityData) => activityData?.id)
                }
                const response = await axiosInstance.put(
                    API.updateWorkTypeActivity, payload
                );
                if (response?.status === 200) {
                    toast.success(response?.data?.message);
                    setAllActivity((prev) => {
                        return prev?.map((data) => {
                            if ((allSelectedActivity?.map((activityData) => activityData?.id))?.includes(data?.id)) {
                                return { ...data, org_work_type_ids: [...data?.org_work_type_ids, currentWorkTypeData?.id] }
                            }
                            return data
                        })
                    })
                    setFormData((prev) => ({
                        ...prev,
                        work_type_settings: prev?.work_type_settings?.map((data, index) => {
                            if (index === tabValue) {
                                return { ...data, 'activities': [...data?.activities, ...allSelectedActivity] }
                            }
                            return data;
                        })
                    }))
                    setAllSelectedActivity([])
                }
                setLoading((prev) => ({ ...prev, updateActivity: false }));
                setIsOpen(false);
            }
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, updateActivity: false }));
        }
    }

    return (loading.pageLoading ?
        <CircularLoader /> :
        <Box display="flex" flexDirection="column" sx={{ width: '100%' }} p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} overflow="auto">
            <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
                <Stack spacing={4} mb={4}>
                    <Grid container sx={{ maxWidth: '850px' }}>
                        <Grid Item mr={3} flex={1}>
                            <Input
                                id="name-type"
                                select
                                type="select"
                                label="Select Yearly Renewal Month"
                                name="renewal_month"
                                variant="standard"
                                sx={{ width: "100%" }}
                                onChange={(e) => handleRenewalMonthChange(e)}
                                onBlur={(e) => handleRenewalMonthChange(e)}
                                value={formData?.renewal_month || ""}
                                error={!!errors?.[renewalMonthIndex]?.['renewal_month']}
                                helperText={errors?.[renewalMonthIndex]?.['renewal_month']}
                                required={true}
                                disabled={true}
                            >
                                {months?.map(
                                    (month, index) => (
                                        <MenuItem
                                            key={index}
                                            value={month}
                                        >
                                            {month}
                                        </MenuItem>
                                    )
                                )}
                            </Input>
                        </Grid>
                        <Grid item flex={1}>
                            <Input
                                id="max-absent"
                                name="max_absent_allowed"
                                label="Max Absent (%)"
                                variant="standard"
                                type="number"
                                isDays={true}
                                inputProps={{ min: 0, max: 100 }}
                                min={0}
                                max={100}
                                sx={{ width: "100%" }}
                                // InputLabelProps={{
                                //     shrink: !!(formData?.max_absent_allowed) || formData?.max_absent_allowed === 0,
                                // }}
                                onChange={(e) => handleMaxAbsentChange(e)}
                                onBlur={(e) => handleMaxAbsentChange(e)}
                                value={formData?.max_absent_allowed}
                                error={!!errors?.[maxAbsentAllowedIndex]?.["max_absent_allowed"]}
                                helperText={errors?.[maxAbsentAllowedIndex]?.["max_absent_allowed"]}
                            />
                            <Box>
                                <HtmlTooltip
                                    arrow
                                    title={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '13px !important' }} textAlign={"center"}>% of team allowed to be Absent on any given day</Typography>
                                        </React.Fragment>
                                    }
                                >
                                    <Typography
                                        display="inline-block"
                                        color="primary"
                                        fontSize={12}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        Help
                                    </Typography>
                                </HtmlTooltip>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Typography color="text.dark" fontSize={14} mb={1} sx={{ lineHeight: '24px' }}>
                            Leave Availability Frequency
                        </Typography>
                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="leave_frequency"
                                defaultValue="monthly"
                                value={formData?.leave_frequency}
                                onChange={handleReleaseFrequencyChange}
                            >
                                <FormControlLabel
                                    value="monthly"
                                    control={<Radio />}
                                    label="Monthly"
                                    sx={{ color: "dark.800" }}
                                    disabled={true}
                                />
                                <FormControlLabel
                                    value="yearly"
                                    control={<Radio />}
                                    label="Annually"
                                    sx={{ color: "dark.800" }}
                                    disabled={true}
                                />
                            </RadioGroup>
                            <FormHelperText error={false} sx={{ p: 0, m: 0 }}></FormHelperText>
                        </FormControl>
                        <Box>
                            <Typography color="dark.600" fontSize={12}>
                                Set frequency of adding leaves into the leave balance.
                            </Typography>
                            <Typography color="dark.600" fontSize={12}>
                                With <b>Monthly</b> option, candidate will receive{" "}
                                <Tooltip title="monthly" arrow>
                                    <Typography
                                        display="inline-block"
                                        fontSize={12}
                                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                                    >
                                        monthly
                                    </Typography>
                                </Tooltip>{" "}
                                leaves at the beginning of every month throughout the year based on
                                Yearly Leave set below.
                            </Typography>
                            <Typography color="dark.600" fontSize={12}>
                                With <b>Annually</b> option, candidate will receive all the yearly
                                leaves at the beginning of the Year.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid>
                        <Grid display="flex" alignItems="center" mb={1}>
                            <Typography color="text.dark" fontSize={14} mr={1} sx={{ lineHeight: '24px' }}>
                                Special Credits
                            </Typography>
                            <i style={{ width: '18px', height: '18px' }}><HelpOutlineIcon sx={{ fontSize: '18px', color: 'secondary.main' }} /></i>
                        </Grid>
                        <FormControlLabel
                            id='allow_special_credits'
                            name='allow_special_credits'
                            label={`Enable Special credits feature`}
                            labelPlacement="end"
                            control={<Checkbox checked={formData?.allow_special_credits} />}
                            onChange={(e) =>
                                handleChangeCreditCheckBox(e)
                            }
                        />
                    </Grid>
                </Stack>
                <Stack spacing={1} sx={{ flexBasis: "100%" }}>
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={tabValue}
                                onChange={handleChangeTab}
                                aria-label="basic tabs example"
                            >
                                {constantType.work_type_settings.map(
                                    (work_type_settings, index) => (
                                        <Tab label={work_type_settings.name} key={index} {...a11yProps(index)} />
                                    )
                                )}
                            </Tabs>
                        </Box>
                        {constantType.work_type_settings.map((work_type_settings, index) => (
                            <CustomTabPanel value={tabValue} index={index} key={index}>
                                <Box mx={-0.5}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox checked={work_type_settings?.isChecked} />}
                                        label={`Yes, I want to set ${work_type_settings.name} Leave Settings for this organisation.`}
                                        labelPlacement="end"
                                        onChange={(e, checked) =>
                                            handleChangeCheckBox(e, checked, work_type_settings.name)
                                        }
                                        sx={{
                                            marginBottom: '16px',
                                            '.MuiCheckbox-sizeMedium': {
                                                minWidth: '42px'
                                            }
                                        }}
                                    />
                                    {work_type_settings?.isChecked ? (
                                        <LeaveTypeForm
                                            work_type_settings={work_type_settings}
                                            formData={formData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            setFormData={setFormData}
                                            constantType={constantType}
                                            handleOpenChangeActivity={handleOpenChangeActivity}
                                            currentWorkTypeActivity={currentWorkTypeActivity}
                                            setAllActivity={setAllActivity}
                                        />
                                    ) : null}
                                </Box>
                            </CustomTabPanel>
                        ))}
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                            flexBasis="100%"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                sx={{
                                    boxShadow: "0",
                                    color: "white",
                                    fontSize: "14px",
                                    paddingTop: '6px',
                                    paddingBottom: '6px',
                                    textTransform: "none",
                                    "&:hover": { boxShadow: "0" },
                                }}
                                type="button"
                                onClick={handleOpenModal}
                            // isButtonLoading={loading?.formSubmitting}
                            // disabled={!isValidData(errors)}
                            >
                                Update
                            </Button>
                            <Button
                                variant="contained"
                                color="lightGray"
                                size="medium"
                                sx={{
                                    boxShadow: "0",
                                    color: "#3F5C76",
                                    fontSize: "14px",
                                    textTransform: "none",
                                    marginLeft: "20px",
                                    padding: '6px',
                                    "&:hover": { boxShadow: "0" },
                                }}
                                type="button"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Stack>
            </Box>
            <Modal
                open={modalDetails?.isShow}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    '> div': {
                        overflow: 'hidden',
                        '> div': {
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                        }
                    }
                }}
            >
                <Box sx={[style, {
                    minWidth: modalDetails?.isShowUsersList ? "30%" : "auto",
                    minHeight: modalDetails?.isShowUsersList ? "25%" : "auto",
                    maxHeight: modalDetails?.isShowUsersList ? "80%" : "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "auto !important",
                }]}>
                    <Box >
                        <Grid Item py={2} px={3} borderBottom="1px solid #E2E4EC" sx={{
                            position: "sticky",
                            bottom: 0,
                            left: 0,
                            background: "white",
                        }}>
                            <Typography variant="h6">Apply Leave Settings</Typography>
                        </Grid>
                        <Grid Item py={2} px={3} flex={1} overflow={"auto"} width={576}>
                            <Box>
                                {!modalDetails?.isShowUsersList ?
                                    <FormControlLabel
                                        id='isApplyForSelectedUser'
                                        name='isApplyForSelectedUser'
                                        label={`Apply changes to Users?`}
                                        labelPlacement="end"
                                        control={<Checkbox checked={modalDetails?.isApplyForSelectedUser} />}
                                        disabled={modalDetails?.isShowUsersList}
                                        onChange={(e) => handleChangeUserCheckbox(e)}
                                    /> : null}
                                {!!modalDetails?.errorMsg ?
                                    <Typography sx={{
                                        fontSize: "12px",
                                        color: "red",
                                    }}>
                                        {modalDetails?.errorMsg}
                                    </Typography> : null}
                            </Box>
                            {!!modalDetails?.isShowUsersList ?
                                <Box>
                                    {!!loading?.users ? <CircularLoader /> : null}
                                    <FormControlLabel
                                        id='isSelectAllUser'
                                        name='isSelectAllUser'
                                        label={`Select all users`}
                                        labelPlacement="end"
                                        control={<Checkbox checked={modalDetails?.isSelectAllUser} />}
                                        onChange={(e) => handleSelectAllUser(e)}
                                    />
                                    {organizationUserList?.length > 0 ?
                                        organizationUserList?.map((userData, index) => (
                                            <List sx={{ p: 0, cursor: "pointer", overflow: "auto" }} key={index} onClick={() => handleUserCheckboxCheck(userData?.id)}>
                                                <ListItem sx={{
                                                    padding: '8px 12px',
                                                    '&:hover': { background: 'rgba(0,0,0,0.04)' },
                                                    '&:hover .MuiIconButton-root': {
                                                        opacity: '1',
                                                    }
                                                }}>
                                                    <Grid sx={{
                                                        display: "flex",
                                                        gap: "5px",
                                                        alignItems: "center",
                                                    }}>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={<Checkbox checked={modalDetails?.user_ids?.includes(userData?.id)} />}
                                                                onChange={(e) => handleUserCheckboxCheck(userData?.id)}
                                                            />
                                                        </Grid>
                                                        <Grid sx={{
                                                            display: "flex",
                                                            gap: "5px",
                                                            alignItems: "center",
                                                        }}>
                                                            <BackgroundLetterAvatars
                                                                user={userData}
                                                                src={userData?.profile_img ? userData?.profile_img : "/static/images/avatar/1.jpg"}
                                                                sx={{ width: 30, height: 30, mr: 1, fontSize: '75%' }} />
                                                            <Typography color="secondary" fontSize={14} fontWeight={500} sx={{ lineHeight: '22px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} flex={1}>{getFullName(userData)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            </List>)) :
                                        <>
                                            {!loading?.users ? <div>No users found.</div> : null}
                                        </>}
                                </Box>
                                : null}
                        </Grid>
                    </Box>
                    <Grid
                        Item
                        py={2}
                        px={3}
                        sx={{
                            borderTop: "1px solid #E2E4EC",
                            display: "flex",
                            justifyContent: "space-between",
                            position: "sticky",
                            bottom: 0,
                            left: 0,
                            background: "white",
                            flexDirection: 'row !important',
                            overflow: 'visible !important',
                        }}
                    >
                        {modalDetails?.isShowUsersList ?
                            <Stack spacing={1} direction="row">
                                <Button
                                    variant="text"
                                    size="medium"
                                    fontWeight="500"
                                    // sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                    onClick={onBack}
                                    isButtonLoading={false}
                                    disabled={loading?.formSubmitting || loading?.noButtonLoading || loading?.users}
                                >
                                    Previous
                                </Button>
                            </Stack> : null}
                        <Stack spacing={1} direction="row">
                            <Button
                                variant="contained"
                                size="medium"
                                fontWeight="500"
                                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                onClick={onApprove}
                                isButtonLoading={loading?.noButtonLoading ? false : modalDetails?.isShowUsersList ? loading?.formSubmitting : loading?.users ? loading?.users : loading?.formSubmitting}
                                disabled={loading?.formSubmitting || loading?.noButtonLoading || loading?.users || !!modalDetails?.errorMsg}
                            >
                                {modalDetails?.isShowUsersList ? "Update" : "Continue"}
                            </Button>
                            <Button
                                variant="text"
                                color="secondary"
                                sx={{ textTransform: "none" }}
                                size="medium"
                                fontWeight="500"
                                onClick={onClose}
                                disabled={loading?.formSubmitting || loading?.noButtonLoading || loading?.users}
                                isButtonLoading={loading?.noButtonLoading}
                            >
                                {modalDetails?.isShowUsersList ? "Cancel" : "No"}
                            </Button>
                        </Stack>
                    </Grid>
                </Box>
            </Modal>

            <Modal
                sx={{
                    height: '90%',
                    maxWidth: "90%",
                    maxHeight: "90%",
                    margin: "auto",
                    borderRadius: '10px',
                    boxSizing: 'border-box',
                }}
                open={isOpen}
                onClose={() => handleModalClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[style, {
                    width: 'auto',
                    minWidth: "250px",
                    minHeight: "250px",
                }]}>
                    <ChooseWorkTypeActivity
                        setInputActivityValue={setInputActivityValue}
                        otherWorkTypeActivity={otherWorkTypeActivity}
                        setIsNewActivityOpen={setIsNewActivityOpen}
                        handleModalClose={handleModalClose}
                        handleChooseActivity={handleChooseActivity}
                        setAllSelectedActivity={setAllSelectedActivity}
                        currentWorkTypeData={currentWorkTypeData}
                        loading={loading} />
                </Box>
            </Modal>
            <BootstrapDialog
                open={isNewActivityOpen}
                // onClose={() => setIsNewActivityOpen(false)}
                onClose={() => handleModalClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogContent>
                    <Box>
                        <CreateNewActivity
                            currentUser={currentUser}
                            inputActivityValue={inputActivityValue}
                            currentWorkTypeData={currentWorkTypeData}
                            workTypeData={formData?.work_type_settings}
                            // handleModalClose={() => setIsNewActivityOpen(false)}
                            handleModalClose={() => handleModalClose()}
                            getAllLeaveSettingData={getAllLeaveSettingData} />
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </Box>
    )
}

export default AdministratorLeaves