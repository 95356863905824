/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import React from 'react';
import { Button, Chip, FormControl, Grid, IconButton, InputLabel, List, ListItem, Menu, MenuItem, Select, Stack, Tooltip, Typography, tooltipClasses } from '@mui/material';
// import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ICONS from '../../../constants/icons';
import { styled } from '@mui/styles';
import useAuthentication from '../../../hook/useAuthentication';
import API from "../../../axios/api";
import axiosInstance from "../../../axios";
import CircularLoader from '../../../components/CircularLoader';
import { getFullName, titleCase } from '../../../utils';
import SearchBoxInput from '../../../components/SearchBoxInput';
import useDebounce from '../../../hook/useDebounce';
import URLS from '../../../routes/urls';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../hook/useQuery';
import { usePageTitle } from '../../../hook/useTitle';
import BackgroundLetterAvatars from '../../../components/BackgroundLetterAvatars';
import UserRoleChip from '../../../components/UserRoleChip';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#3F5C76',
        padding: '16px'
    },
}));

const Users = () => {
    const navigate = useNavigate();
    const { getCurrentUser } = useAuthentication();
    const { setPageTitle } = usePageTitle();
    const { getQueryParams, setQueryParams } = useQuery();
    const currentUser = getCurrentUser();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [expandedRow, setExpandedRow] = useState([])
    const [userRolesData, setUserRolesData] = useState([]);
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: true,
        userRoleLoading: true
    });
    const Group_Filter = {
        'status': 'Status',
        'role': 'Role',
        'workType': 'Work Type'
    }
    let groupBy = getQueryParams("groupBy") && Object.keys(Group_Filter)?.includes(getQueryParams("groupBy")) ? getQueryParams("groupBy") : Object.keys(Group_Filter)[1];
    let role = getQueryParams("role") || "All";
    const [userData, setUserData] = useState({});
    const [userDataCount, setUserDataCount] = useState(0);
    const [searchString, setSearchString] = useState("");
    const debouncedSearchTerm = useDebounce(searchString);
    const [pageBeenRendered, setPageBeenRendered] = useState(false);
    const [openElem, setOpenElem] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    setPageTitle("Users");

    const [params, setParams] = useState({
        page: 0,
        limit: 10,
        sortOrder: "asc",
        sortField: "",
        group: groupBy,
        filter: role === "All" ? "" : role,
        search: null
    });

    useEffect(() => {
        const getUserRolesData = async () => {
            try {
                setLoading((prev) => ({ ...prev, userRoleLoading: true }));
                const response = await axiosInstance.get(
                    API.organisationUserRoles(currentUser?.organization_id)
                );
                if (response.status === 200) {
                    setUserRolesData([{ id: (response?.data?.data?.length + 1), name: 'All', canDelete: true }, ...response?.data?.data]);
                }
                setLoading((prev) => ({ ...prev, userRoleLoading: false }));
            } catch (error) {
                console.error(error);
            }
        };
        if (currentUser?.organization_id)
            getUserRolesData();
    }, []);

    useEffect(() => {
        setQueryParams("groupBy", groupBy);
        setQueryParams("role", role);
    }, [])

    useEffect(() => {
        setUserDataCount(0);
        getAllUserListByOrgId();
    }, [params]);

    useEffect(() => {
        if (pageBeenRendered) {
            setParams((prev) => ({
                ...prev,
                search: debouncedSearchTerm,
            }));
        }
        setPageBeenRendered(true)
    }, [debouncedSearchTerm]);

    const handleClick = (elem) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenElem(elem);
    };

    const getAllUserListByOrgId = async () => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));
            let queryParamsPageIncrease = { ...params, page: params?.page + 1 };
            const response = await axiosInstance.post(API.getAllUsersDataByOrgId(currentUser?.organization_id), queryParamsPageIncrease);
            if (response.status === 200) {
                if (response.data?.results) {
                    setDataAccordingToFilters(response.data?.results)
                    setUserDataCount(response.data?.totalData)
                } else {
                    setUserData({})
                }
            } else {
                setUserData({})
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        }
    };

    const sortDataForDefaultView = (data) => {
        return data?.sort((a, b) => {
            const roleA = a?.user_role?.toUpperCase();
            const roleB = b?.user_role?.toUpperCase();
            if (roleA < roleB)
                return -1;
            if (roleA > roleB)
                return 1;
            return 0;
        })
    }

    const setDataAccordingToFilters = (data) => {
        let keyToFilter = (groupBy === 'role') ? 'user_role' : groupBy === 'workType' ? 'work_type' : 'active';
        if (params?.sortField === '') {
            data = sortDataForDefaultView([...data]);
        }
        let filteredData = data?.reduce((result, item) => {
            const _key = keyToFilter === 'active' ? item[keyToFilter] ? titleCase(keyToFilter) : 'Inactive' : titleCase(item[keyToFilter]);
            if (!result[_key]) {
                result[_key] = [];
            }
            result[_key].push(item);
            return result;
        }, {});
        setExpandedRow(Object.keys(filteredData));
        setUserData(filteredData)
    }

    const handleChangePage = (event, newPage) => {
        setParams((prev) => ({
            ...prev,
            page: newPage,
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setParams((prev) => ({
            ...prev,
            limit: event.target.value,
            page: 0,
        }));
    };

    const handleFilterChange = (event) => {
        setQueryParams(event?.target?.name === "group" ? "groupBy" : "role", event?.target?.value)
        setParams((prev) => ({
            ...prev,
            [event?.target?.name]: event?.target?.value === "All" ? '' : event?.target?.value
        }))
        setExpandedRow([]);
    }

    const handleRowClick = (filterName) => {
        setExpandedRow((prev) => prev.includes(filterName) ? prev?.filter(name => name !== filterName) : [...prev, filterName])
    }

    const handleSearch = (value) => {
        setSearchString(value);
    };

    const createSortHandler = (property) => {
        const isAsc = (orderBy === property) ? (order === 'asc') ? true : false : true;
        setOrder(isAsc ? 'desc' : 'asc');
        setParams((prev) => ({ ...prev, sortOrder: isAsc ? 'desc' : 'asc', sortField: property }))
        setOrderBy(property);
    }

    const handleLeaveCount = (userDetail) => {
        let total_leave = userDetail?.userLeaveTypeData?.reduce((total, leave) => {
            return total + leave?.leave_used
        }, 0);
        return `${total_leave || 0}/${(userDetail?.total_leave_allocation || 0)}`;
    }

    const handleRemainingLeaveCount = (userLeaveData) => {
        let remainingLeave = userLeaveData?.reduce((result, item) => {
            const _key = item?.leave_type;
            if (!result[_key]) {
                result[_key] = [];
            }
            result[_key] = `${item?.leave_used || 0}/${(item?.total_leave_allocation) || 0} Days`;
            return result;
        }, {});
        return remainingLeave;
    }

    const handleClearFilter = () => {
        if (params?.search?.length || params?.sortField !== "" || params?.group !== 'role' || params?.filter !== "") {
            setParams(prev => ({
                ...prev,
                page: 0,
                sortOrder: "asc",
                sortField: "",
                group: "role",
                filter: "",
                search: null
            }))
        }
        setQueryParams("groupBy", "role")
        setQueryParams("role", "All")
        setSearchString('')
    }

    const handleViewUser = (userDetail, tabValue = 2) => {
        handleClose();
        navigate(`${URLS.UserDetails}/${userDetail?.id}?tabValue=${tabValue}`)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenElem(null);
    };

    return (
        <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
            <Box px={2} py={1} borderBottom="1px solid #E0E0E0" bgcolor="white" position="sticky" top={0} zIndex={1}>
                <Typography variant='h2' color="secondary" fontSize={18} fontWeight={600} sx={{ lineHeight: "27px", letterSpacing: '0.15px' }}>Users</Typography>
            </Box>
            <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} sx={{ background: '#ffffff' }} flex={1} overflow="auto">
                {(loading?.userRoleLoading) ?
                    <CircularLoader height="500px" /> :
                    <Box borderRadius={0.5} border="1px solid #E0E0E0" overflow="hidden">
                        <Grid container p={{ xs: 2, xl: 3 }} display="flex" justifyContent="space-between" borderBottom="1px solid #E0E0E0">
                            <Grid Item display="flex">
                                <Stack direction="row" spacing={2}>
                                    <Grid Item width={{ xs: 180, xl: 230 }}>
                                        <SearchBoxInput
                                            label="Search"
                                            variant="standard"
                                            placeholder="Name,email, etc..."
                                            handleSearch={handleSearch}
                                            value={searchString}
                                            isUseDebounce={false}
                                        />
                                    </Grid>
                                    <Grid Item width={{ xs: '150px', xl: '180px' }}>
                                        <FormControl variant="standard" sx={{ width: '100%' }}>
                                            <InputLabel id="demo-simple-select-standard-label">Group By</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={groupBy || ''}
                                                label="Group By"
                                                name='group'
                                                onChange={(e) => handleFilterChange(e)}
                                            >
                                                {Object.keys(Group_Filter)?.map((key, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={key}
                                                    >
                                                        {Group_Filter[key]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid Item width={{ xs: '150px', xl: '180px' }}>
                                        <FormControl variant="standard" sx={{ width: '100%' }}>
                                            <InputLabel id="demo-simple-select-standard-label">Role</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                label="Role"
                                                name='filter'
                                                value={role || ''}
                                                onChange={(e) => handleFilterChange(e)}
                                            >
                                                {userRolesData?.map(
                                                    ({ name }, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={name}
                                                        >
                                                            {titleCase(name)}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Button variant='text' color='primary'
                                        size="small"
                                        sx={{ marginTop: '16px !important', textTransform: 'none' }}
                                        onClick={() => handleClearFilter()}>Clear Filters</Button>
                                </Stack>
                            </Grid>
                            <Grid Item display="flex" justifyContent="flex-end" alignItems="center">
                                <Stack direction="row" spacing={{ xs: 1.5, lg: 2 }}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size="small"
                                        sx={{ color: 'white', textTransform: 'none', boxShadow: 'none !important' }}
                                        onClick={() => navigate(URLS.CreateUser)}
                                    >

                                        Add User
                                    </Button>
                                    {/* <IconButton color='secondary' size="small" borderRadius={0}>
                                        <SettingsRoundedIcon sx={{ fontSize: '24px' }} />
                                    </IconButton> */}
                                </Stack>
                            </Grid>
                        </Grid>
                        <Paper sx={{ width: '100%', borderRadius: '0px', boxShadow: 'none', }}>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                >
                                    <TableHead>
                                        <TableRow sx={{
                                            whiteSpace: 'nowrap',
                                            'th': {
                                                bgcolor: "bgColor", padding: "8px"
                                            }
                                        }}>
                                            <TableCell sx={{ width: '110px', maxWidth: '110px', minWidth: '110px' }}
                                                sortDirection={orderBy === 'user_role' ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === 'user_role'}
                                                    direction={orderBy === 'user_role' ? order : 'asc'}
                                                    onClick={() => !loading.pageLoading && createSortHandler('user_role')}
                                                >
                                                    {titleCase(params?.group)}
                                                    {orderBy === 'user_role' ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {params?.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ width: '100px', maxWidth: '100px', minWidth: '100px', paddingRight: '0px !important' }}>
                                                <TableSortLabel
                                                    active={orderBy === 'id'}
                                                    direction={orderBy === 'id' ? params?.sortOrder : 'asc'}
                                                    onClick={() => !loading.pageLoading && createSortHandler('id')}
                                                >
                                                    Emp ID
                                                    {orderBy === 'id' ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {params?.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'name'}
                                                    direction={orderBy === 'name' ? params?.sortOrder : 'asc'}
                                                    onClick={() => !loading.pageLoading && createSortHandler('name')}
                                                >
                                                    User
                                                    {orderBy === 'name' ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {params?.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ width: '140px', maxWidth: '140px', minWidth: '140px' }}>
                                                Leaves Taken
                                            </TableCell>
                                            <TableCell sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>
                                                User Type
                                            </TableCell>
                                            {groupBy !== "workType" ? <TableCell sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>
                                                Work Type
                                            </TableCell> : null}
                                            {groupBy !== "role" ? <TableCell sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>
                                                User Role
                                            </TableCell> : null}
                                            {/* <TableCell sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>
                                                Is Admin
                                            </TableCell> */}
                                            {/* <TableCell sx={{ width: '140px', maxWidth: '140px', minWidth: '140px' }}>
                                                Projects
                                            </TableCell> */}
                                            {groupBy !== "status" ? <TableCell sx={{ width: '110px', maxWidth: '110px', minWidth: '110px' }}>
                                                Status
                                            </TableCell> : null}
                                            <TableCell sx={{ textAlign: 'center', width: '48px', maxWidth: '48px', minWidth: '48px' }}>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loading.pageLoading ?
                                            <CircularLoader variant="table" rows={params.limit} cols={8} /> :
                                            Object.entries(userData)?.length ? (Object.entries(userData)?.map((user, index) => {
                                                let isOpen = expandedRow?.includes(user?.[0]);
                                                return (
                                                    <React.Fragment key={index}>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => handleRowClick(user?.[0])}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                align='left'
                                                                color='secondary'
                                                                fontSize="14"
                                                                fontWeight='500'
                                                                sx={{ padding: '8px 16px' }}
                                                                colSpan={10}
                                                            >
                                                                <Grid Item display='flex' alignItems='center' color="secondary.main" fontSize={14} fontWeight={500} sx={{ lineHeight: '22px' }}>
                                                                    {expandedRow?.includes(user[0]) ? <ExpandMoreIcon sx={{ fontSize: '18px', mr: 2 }} /> : <ChevronRightIcon sx={{ fontSize: '18px', mr: 2 }} />}
                                                                    {`${user?.[0]} (${user?.[1]?.length})`}
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                        {isOpen ? user?.[1]?.map((userDetail, index) => (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={index}
                                                                sx={{ 'td': { cursor: "pointer", color: 'rgba(0, 0, 0, 0.87)', fontSize: '14px', fontWeight: '400', lineHeight: '20px', letterSpacing: '0.17px', padding: '13px 8px' } }}
                                                            // onClick={() => navigate(`${URLS.UserDetails}/${userDetail?.id}`)}
                                                            >
                                                                <TableCell onClick={() => handleViewUser(userDetail)} sx={{ width: '110px', maxWidth: '110px', minWidth: '110px' }}></TableCell>
                                                                <TableCell onClick={() => handleViewUser(userDetail)} sx={{ width: '100px', maxWidth: '100px', minWidth: '100px', paddingRight: '0px !important' }}>{userDetail?.id}</TableCell>
                                                                <TableCell onClick={() => handleViewUser(userDetail, 0)}>
                                                                    <Grid Item display="flex" alignItems="center">
                                                                        <BackgroundLetterAvatars
                                                                            user={userDetail}
                                                                            sx={{ width: "32px", height: "32px", fontSize: '90%' }}
                                                                        />
                                                                        <Grid Item ml={1}>
                                                                            <Typography variant='body1' fontSize={15} fontWeight={500} color="dark.800" sx={{ lineHeight: '24px', letterSpacing: '0.17px' }}> {getFullName(userDetail)}</Typography>
                                                                            <Typography variant='body1' fontSize={12} fontWeight={400} color="main.900" sx={{ lineHeight: '15px' }}>{userDetail?.email}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </TableCell>
                                                                <TableCell onClick={() => handleViewUser(userDetail, 2)} sx={{ width: '140px', maxWidth: '140px', minWidth: '140px' }}>
                                                                    <HtmlTooltip
                                                                        title={
                                                                            <React.Fragment>
                                                                                <Typography variant='body1' fontWeight={500} color="white" mb={2}>Remaining Leaves</Typography>
                                                                                <List sx={{
                                                                                    p: 0,
                                                                                    minWidth: '238px',
                                                                                    'li': { p: 0, display: 'flex', justifyContent: 'space-between', marginBottom: '2px' }
                                                                                }}>
                                                                                    {Object.entries(handleRemainingLeaveCount(userDetail?.userLeaveTypeData))?.length ? Object.entries(handleRemainingLeaveCount(userDetail?.userLeaveTypeData))?.map((leaveDetail, index) => (
                                                                                        <ListItem key={index}>
                                                                                            <Typography variant='body2' fontSize={13} fontWeight={500} pr={1} sx={{ lineHeight: '19px' }}>{leaveDetail?.[0]}</Typography>
                                                                                            <Typography variant='body2' fontSize={13} fontWeight={500} sx={{ lineHeight: '19px' }}>{leaveDetail?.[1]}</Typography>
                                                                                        </ListItem>
                                                                                    )) : <ListItem>No leaves Data Found</ListItem>}
                                                                                </List>
                                                                            </React.Fragment>
                                                                        }
                                                                    >
                                                                        <Typography fontSize={14} fontWeight={400} color="dark.800" display={"inline-block"}
                                                                            sx={{
                                                                                lineHeight: '20px',
                                                                                letterSpacing: '0.17px',
                                                                                "&:hover": {
                                                                                    color: "primary.main",
                                                                                },
                                                                            }}> {handleLeaveCount(userDetail)}</Typography>
                                                                    </HtmlTooltip>
                                                                </TableCell>
                                                                <TableCell onClick={() => handleViewUser(userDetail)} sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>{titleCase(userDetail?.user_type) || "-"}</TableCell>
                                                                {groupBy !== "workType" ? <TableCell onClick={() => handleViewUser(userDetail)} sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>{titleCase(userDetail?.work_type) || "-"}</TableCell> : null}
                                                                {groupBy !== "role" ? <TableCell onClick={() => handleViewUser(userDetail)} sx={{
                                                                    width: '160px', maxWidth: '160px', minWidth: '160px',
                                                                    '.MuiChip-filled': {
                                                                        padding: '3px 10px 3px 0px',
                                                                        height: 'auto',
                                                                        '.MuiChip-avatarSmall': {
                                                                            width: '24px',
                                                                            height: '24px'
                                                                        }
                                                                    }
                                                                }}>
                                                                    <UserRoleChip label={titleCase(userDetail?.user_role) || "-"} />
                                                                </TableCell> : null}
                                                                {/* <TableCell onClick={() => handleViewUser(userDetail)} sx={{ width: '160px', maxWidth: '160px', minWidth: '160px' }}>
                                                                    <Switch
                                                                        name="is_admin"
                                                                        id="is_admin"
                                                                        size="small"
                                                                        checked={userDetail?.is_admin}
                                                                        disabled={true}
                                                                    />
                                                                </TableCell> */}
                                                                {/* <TableCell onClick={() => handleViewUser(userDetail, 1)} sx={{ width: '140px', maxWidth: '140px', minWidth: '140px' }}>
                                                                    <Button variant='text'
                                                                        color='primary'
                                                                        size="small" fontSize={13} fontWeight={500} p={0}
                                                                        sx={{
                                                                            textTransform: 'none',
                                                                        }}>6 Projects</Button>
                                                                </TableCell> */}
                                                                {groupBy !== "status" ? <TableCell onClick={() => handleViewUser(userDetail)} sx={{ width: '110px', maxWidth: '110px', minWidth: '110px' }}>
                                                                    {userDetail?.active ?
                                                                        <Chip label="Active" color='secondary' size="small" variant='filled'></Chip>
                                                                        :
                                                                        <Chip label="Inactive" size="small" variant='filled'></Chip>}
                                                                </TableCell> : null}
                                                                <TableCell sx={{ textAlign: 'center', width: '48px', maxWidth: '48px', minWidth: '48px', paddingLeft: '0px !important', paddingRight: '0px !important', }}>
                                                                    <IconButton
                                                                        aria-label="more"
                                                                        aria-haspopup="true"
                                                                        onClick={handleClick(userDetail)}
                                                                        sx={{
                                                                            height: "30px",
                                                                            width: "30px",
                                                                            padding: "0px",
                                                                            borderRadius: "4px",
                                                                        }}
                                                                        id="basic-button"
                                                                        aria-controls={open ? 'basic-menu' : ""}
                                                                        aria-expanded={open ? 'true' : "false"}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                height: "20px",
                                                                                width: "20px",
                                                                                display: "flex",
                                                                            }}
                                                                        >
                                                                            {ICONS.DotsHorizontal}
                                                                        </i>
                                                                    </IconButton>
                                                                    <Menu
                                                                        id="basic-menu"
                                                                        anchorEl={anchorEl}
                                                                        open={open}
                                                                        onClose={handleClose}
                                                                        MenuListProps={{
                                                                            'aria-labelledby': 'basic-button',
                                                                        }}
                                                                        sx={{
                                                                            filter: 'none',
                                                                        }}
                                                                    >
                                                                        <MenuItem onClick={() => handleViewUser(openElem)}>View</MenuItem>
                                                                    </Menu>
                                                                </TableCell>
                                                            </TableRow>
                                                        )) : null}
                                                    </React.Fragment>
                                                )
                                            })) : (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={8}
                                                        align="center"
                                                        sx={{ padding: "12px 16px;" }}
                                                    >
                                                        <Typography
                                                            color={"secondary"}
                                                            display={"flex"}
                                                            alignItems={"center"}
                                                            justifyContent={"center"}
                                                            fontSize={13}
                                                            sx={{ opacity: "0.5", height: "236px" }}
                                                        >
                                                            No records found.
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {userDataCount ? <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={userDataCount}
                                rowsPerPage={params.limit}
                                page={params.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            /> : null}
                        </Paper>
                    </Box>}
            </Box>
        </Box>
    );
};

export default Users;