const API = {
  login: "/auth/login", // POST Method  
  changePassword: "/auth/change-password", // POST Method
  logout: (userId) => `/auth/logout/${userId}`, // POST Method
  forgotPassword: "/auth/forgot-password", // POST Method
  resetPassword: "/auth/reset-password", // POST Method,
  getUserDataViaToken: `/auth/user-details`, // GET Method
  updateUserProfile: (id) => `/user/update-userProfileAndName/${id}`, // POST Method
  createOrganization: "/super-admin/organization", // POST Method
  getOrganization: (id) => `/super-admin/organization/${id}`, // GET Method
  getAllOrganization: "/super-admin/organization", // GET Method
  checkOrganisationUniqueName: "/organization/unique", // GET Method
  organisationBasicInfo: (id) => `/organization/basic-info/${id}`, // GET/PUT Method
  organisationLeaveSettings: (id) => `/organization/leave-settings/${id}`, // GET/PUT Method
  organisationUsersLeaveSettings: `/organization/update-user-settings`, // GET/PUT Method
  getOrganisationWorkTypes: `/organization/work-types`, // GET Method
  getWorkTypesByOrganisationId: (id) => `/organization/work-types/${id}`, // GET Method
  getUserTypes: `organization/user-types`, // GET Method
  addUser: `/user`, // POST Method
  fetchUserDetails: (userId) => `/user/${userId}`, // GET Method
  getLeaveTypes: `/organization/leave-types`, // GET Method
  getUserLeaveDetailsById: (id, org_year_id) => `/user/leave-details/${id}/${org_year_id}`, // GET Method
  updateUserLeaveDetailsById: (id, org_year_id) => `/user/leave-details/${id}/${org_year_id}`, // PUT Method
  getLeaveSpecialRequests: `organization/special-requests`, // GET Method
  getUserLeaveTypeData: (id) => `/leaves/user-leave-type/${id}`, // GET Method
  getUserSpecialCredits: (id) => `/user/special-credits/${id}`, // GET Method
  organisationHoliday: (id) => `organization/holiday/${id}`, // GET/PUT Method
  organisationUserSetting: (id) => `organization/users/${id}`, // GET/PUT Method
  organisationUserWithInformationAuthority: `/user/reporting-users`, // GET Method
  organisationUserRoles: (id) => `/organization/user-roles/${id}`, // GET/PUT Method
  organizationLeaveTypes: (id) => `/organization/org-leave-details/${id}`, // GET/PUT Method
  organisationUsersListByRoles: (orgId, workTypeId) => `/user/user-role/${orgId}/${workTypeId}`, // GET Method
  skipStepForOnBoarding: (orgId, status) => `/organization/skip-process/${orgId}/${status}`, // PUT Method
  changeOrganisationStatus: (orgId, status) =>
    `/super-admin/organization/${orgId}/status/${status}`, // PUT Method
  getAllLeavesForUser: (id) => `/leaves/list/${id}`, // POST Method
  getOthersLeaves: (id) => `/leaves/others-leave/${id}`, // GET Method
  getOneUserLeaves: (otherUserId, userId, status) => `/leaves/others-leave/${otherUserId}/user/${userId}/${status}`,// GET Method
  getUserWfhApplicationList: (id) => `/wfh/wfh-app/${id}`, // GET Method
  getOtWfhApplicationList: (id) => `/wfh/others/${id}`, // GET Method
  getOneUserWfhList: (otherUserId, userId, status) => `/wfh/others/${otherUserId}/user/${userId}/${status}`,// GET Method
  applyLeaveSummary: (id) => `/leaves/${id}`, // POST method
  applyLeaveSummaryWithoutCredit: (id) => `/leaves/spcd-disable/${id}`, // POST method
  updateLeaveStatus: (userId, leaveId) => `/leaves/approve/${userId}/${leaveId}`, // PUT method
  updateWfhApplicationStatus: (userId, leaveId) => `/wfh/approve/${userId}/${leaveId}`, // PUT method
  createLeaveApplication: (id) => `/leaves/add/${id}`, // POST Method
  createWFHApplication: (id) => `/wfh/add/${id}`, // POST Method
  getLeaveById: (userId, leaveId) => `/leaves/${userId}/${leaveId}`, // GET Method
  getWfhApplicationById: (userId, leaveId) => `/wfh/${userId}/${leaveId}`, // GET Method
  getWfhDisabledDatesById: (userId) => `/wfh/dates/${userId}`, // GET Method
  updateLeaveById: (userId, leaveId) => `/leaves/${userId}/${leaveId}`, // PUT Method
  deleteLeaveAttachment: () => `/leaves/attachment`, // PUT Method
  deleteWfhRequestAttachment: `/wfh/attachment`, // PUT Method
  updateWfhApplicationById: (userId, leaveId) => `/wfh/${userId}/${leaveId}`, // PUT Method
  deleteLeaveById: (userId, leaveId) => `/leaves/${userId}/${leaveId}`, // Delete Method
  deleteWfhApplicationById: (userId, leaveId) => `/wfh/${userId}/${leaveId}`, // Delete Method
  getLeaveBalance: (userId) => `/leaves/leave-balance/${userId}`, // GET Method
  getSpecialCredits: (userId) => `/wfh/${userId}`, // GET Method
  getHolidayYears: (orgId) => `/holiday/${orgId}`, // GET Method
  createOrgYear: (orgId) => `/holiday/year/${orgId}`, // GET Method
  getAllUsersDataByOrgId: (id) => `/user/organization/${id}`, // POST Method
  updateHolidaySetting: (org_id, year_id) => `/holiday/${org_id}/${year_id}`, // PUT Method
  getHolidayDatesBySettings: (org_id, year_id) => `/holiday/${org_id}/${year_id}`, // POST Method
  getAllUserHolidays: (orgId, dateId) => `/holiday/${orgId}/${dateId}`, // GET Method
  getAllHolidaysYear: (orgId) => `/holiday/${orgId}`, // GET Method
  deleteHoliday: (holidayType, id) => `/holiday/${holidayType}/${id}`, // DELETE Method
  isEmailUnique: (email, userId) => userId ? `/user/unique/${email}/${userId}` : `/user/unique/${email}`, // GET method
  getUserTimeTracker: `/user-tracking/calculation`, // GET method
  getUserSummary: `/user-tracking/summary`, // GET method
  addOverTime: "/user-tracking/missing-time", // POST Method
  getUserActivityReport: `/user-tracking/summary-report`, // GET method
  checkOutTimeTracker: `/user-tracking/checkout`, // POST method
  getAllHolidayTitle: `/office-holiday/`, // Get Method
  getInOutReport: `/user-tracking/in-out/reports`, // POST Method
  getByIdHolidayTitle: (id) => `/office-holiday/${id}`, // Get Method
  createOfficeHolidayTitle: `/office-holiday/`, // POST Method
  updateOfficeHolidayTitle: (id) => `/office-holiday/${id}`, // PUT Method
  deleteOfficeHolidayTitle: (id) => `/office-holiday/${id}`, // DELETE Method
  readNotification: (id) => `/notifications/mark-read/${id}`, // PUT Method
  getAllNotifications: (userId, limit = 15, offSet = 0) => `/notifications/all/${userId}/${limit}/${offSet}`, // PUT Method
  getNightMode: `/user-tracking/daily-tracker`, // GET Method
  updateNightMode: (id, value) => `/user-tracking/${id}/night-mode/${value}`, // PUT Method
  getAllActivity: (id) => `/activity/getAllActivity/${id}`, // GET method
  getAllActivityByWorkType: (workTypeId) => `/activity/work-type/${workTypeId}`, // GET method
  getActivityById: (id) => `/activity/findOneActivity/${id}`, // GET method
  deleteActivityById: (id) => `/activity/deleteActivity/${id}`, // DELETE method
  createActivity: `/activity/create-activity`, // POST method
  updateActivity: `/activity/updateActivityRecord`, // PUT method
  updateWorkTypeActivity: `/organization/activities`, // PUT method
  checkActivityUniqueName: "/activity/unique", // GET Method
  getLeaveReportList: "/leaves/leave-report", // POST method
  getRepoteesListByUserId: `/user/reporting-auth`, // GET Method
  getUserLeaveReportById: (userId, id) => `/user/leave-report/${userId}/${id}`, // GET method  
};

export default API;
