/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axiosInstance from '../../../axios';
import API from '../../../axios/api';
import TableRowSkeleton from '../../../components/TableRowSkeleton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { convertDateFromFormat, durationToWord, getFormattedDate, getLastDuration, getPaginatedArray, sortArrayByKey, timestampToTime, titleCase } from '../../../utils';
import moment from 'moment';
import { DateObject } from 'react-multi-date-picker';
import DateRangePicker from '../../../components/DateRangePicker';
import { useSelector } from 'react-redux';
import EmptyTable from '../../../components/EmptyTable';

const UserSummary = ({ setActionsCount }) => {
    const { user: currentUser } = useSelector((state) => state?.user);
    const [expandedRow, setExpandedRow] = useState([])
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState({
        summary: false,
        activity: false,
    });
    const [summary, setSummary] = useState([]);
    const [sortType, setSortType] = useState("desc");
    const [orderBy, setOrderBy] = useState("date");
    const [allActivities, setAllActivities] = useState([]);
    let initialDateRangeChange = {
        startDate: moment(new Date(new DateObject().toFirstOfMonth())).format("DD/MM/YYYY"),
        endDate: moment(new Date(new DateObject().toDate())).format("DD/MM/YYYY"),
        isChange: false,
    };
    const [isDateRangeChanged, setIsDateRangeChanged] = useState(initialDateRangeChange);
    const [queryParams, setQueryParams] = useState({
        userData: {
            id: currentUser?.id
        },
        startDate: new DateObject().toFirstOfMonth(),
        endDate: new DateObject().toDate(),
        dateRange: [new DateObject().toFirstOfMonth(), new DateObject().toDate()],
        page: 0,
        perPage: 10,
    });

    const handleChangeDatePicker = (dateRange) => {
        const newStartDate = new Date(dateRange[0]).toISOString();
        const newEndDate = new Date(dateRange[1] || dateRange[0]).toISOString();
        const hasDateChanged = isDateRangeChanged?.startDate !== moment(newStartDate).format("DD/MM/YYYY") || isDateRangeChanged?.endDate !== moment(newEndDate).format("DD/MM/YYYY");

        setIsDateRangeChanged((prev) => ({ ...prev, isChange: hasDateChanged }));

        setQueryParams(prev => ({
            ...prev,
            startDate: newStartDate,
            endDate: newEndDate,
            dateRange,
        }));
    }

    const handleDatePickerClose = () => {
        if (isDateRangeChanged.isChange) {
            getSummaryReport();
        }
    }

    const isWorkingType = (activity) => {
        let isWorkingActivity = allActivities?.find(value => value?.title?.toLowerCase() === activity?.toLowerCase())?.types === "working";
        let isWorkLog = activity?.toLowerCase() === "work log";
        return isWorkingActivity || isWorkLog;
    }

    const handleChangePage = (event, newPage) => {
        setQueryParams((prev) => ({
            ...prev,
            page: newPage,
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setQueryParams((prev) => ({
            ...prev,
            perPage: parseInt(event.target.value, 10),
            page: 0,
        }));
    };

    const getAllActivity = async () => {
        try {
            setIsLoading(prev => ({ ...prev, activity: true }));
            let response = await axiosInstance.get(API.getAllActivity(currentUser?.organization_id));
            if (response.status === 200) {
                setAllActivities(response?.data?.data?.map(value => ({ types: value?.types, title: value?.title })));
                setIsLoading(prev => ({ ...prev, activity: false }));
            } else {
                setAllActivities([]);
                setIsLoading(prev => ({ ...prev, activity: false }));
            }
        }
        catch (error) {
            console.error(error);
            setIsLoading(prev => ({ ...prev, activity: false }));
        }
    }

    const getSummaryReport = async () => {
        try {
            setIsLoading(prev => ({ ...prev, summary: true }));
            let body = {
                ...queryParams,
                startDate: convertDateFromFormat(new Date(queryParams?.startDate)),
                endDate: convertDateFromFormat(new Date(queryParams?.endDate)),
            };
            delete body.dateRange;
            delete body.page;
            delete body.perPage;
            setIsDateRangeChanged({
                isChange: false,
                startDate: moment(new Date(queryParams?.startDate).toISOString()).format("DD/MM/YYYY"),
                endDate: moment(new Date(queryParams?.endDate).toISOString()).format("DD/MM/YYYY")
            });
            const response = await axiosInstance.post(API.getUserSummary, body);
            if (response.status === 200) {
                setSummary(response?.data?.data || []);
                setActionsCount(response?.data?.activity || []);
                setTotalCount(response?.data?.data?.length);
                setIsLoading(prev => ({ ...prev, summary: false }));
            } else {
                setSummary([])
                setIsLoading(prev => ({ ...prev, summary: false }));
            }
        } catch (error) {
            console.error(error);
            setIsLoading(prev => ({ ...prev, summary: false }))

            setExpandedRow([]);
            setSummary([]);
        }
    }

    const handleRowClick = (filterName) => {
        setExpandedRow((prev) => prev.includes(filterName) ? prev?.filter(name => name !== filterName) : [...prev, filterName])
    }

    const handleSortClick = (sort_by) => {
        setOrderBy(sort_by);
        setSortType((prev) => (prev === "asc" ? "desc" : "asc"));
    };

    const isShowActivity = (activity, index, allActivities) => {
        let title = activity?.activity?.toLowerCase();
        let isSameDay = moment(activity?.createdAt).format("DD/MM/YYYY") === moment(new Date()).format("DD/MM/YYYY");
        let isHideRow = isSameDay && title === "work log" && activity?.duration === "-" && allActivities?.length - 1 === index;
        // let isHideRow = isSameDay && title === "work log" && activity?.duration === "-" && allActivities?.length - 1 === index && activity?.log_type !== 'login';
        return !isHideRow;
    }

    let sortedData = sortArrayByKey(summary, "desc", "date");
    const visibleRows = sortArrayByKey(getPaginatedArray(sortedData, queryParams?.page, queryParams?.perPage), sortType, orderBy);

    useEffect(() => {
        getSummaryReport();
        getAllActivity();
    }, []);

    return (
        <Box my={4} width="100%">
            <Typography color="secondary.main" fontSize={18} fontWeight={600} lineHeight={"27px"} letterSpacing={"0.15px"} mb={3}>
                EOD Report
            </Typography>
            <Grid item mb={3} sx={{
                width: { xs: 300, lg: 374 },
                'div': {
                    maxWidth: '100%',
                    width: '100%',
                }
            }}>
                <DateRangePicker
                    dateRange={queryParams?.dateRange}
                    onChange={(dateRange) => handleChangeDatePicker(dateRange)}
                    onClose={handleDatePickerClose}
                    width={370}
                />
            </Grid>
            <Paper sx={{ width: '100%', borderRadius: '3px', boxShadow: 'none', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <TableHead>
                            <TableRow sx={{
                                whiteSpace: 'nowrap',
                                'th': {
                                    background: "#F5F8FA", padding: "8px 16px"
                                }
                            }}>
                                <TableCell sx={{
                                    minWidth: { xs: 150, sm: '24%' },
                                    maxWidth: { xs: 150, sm: '24%' },
                                    width: { xs: 150, sm: '24%' },
                                }}>
                                    <TableSortLabel
                                        active={orderBy === "date"}
                                        direction={orderBy === "date" ? sortType : "asc"}
                                        onClick={() => handleSortClick("date")}
                                    >
                                        Date
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{
                                    minWidth: { xs: 170, lg: 200 },
                                    maxWidth: { xs: 170, lg: 200 },
                                    width: { xs: 170, lg: 200 },
                                }}>{expandedRow?.length > 0 ? "Activity" : ""}</TableCell>
                                <TableCell sx={{
                                    minWidth: { xs: 170, sm: '22%' },
                                    maxWidth: { xs: 170, sm: '22%' },
                                    width: { xs: 170, sm: '22%' },
                                }}>{expandedRow?.length > 0 ? "Timing" : ""}</TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === "working_hrs"}
                                        direction={orderBy === "working_hrs" ? sortType : "asc"}
                                        onClick={() => handleSortClick("working_hrs")}
                                    >
                                        Duration (HH:MM)
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading?.summary ?
                                <TableRowSkeleton cols={4} rows={5} /> :
                                !!visibleRows?.length ? visibleRows?.map((report, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            sx={{
                                                cursor: 'pointer',
                                                'td': {
                                                    color: "dark.800",
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    lineHeight: '20px',
                                                    letterSpacing: '0.17px',
                                                    padding: '12px 16px',
                                                    borderTop: '3px solid #e0e0e0'
                                                },
                                                "&:first-child": {
                                                    'td': {
                                                        borderTopWidth: '0px',
                                                    }
                                                }
                                            }}
                                            onClick={() => handleRowClick(index)}
                                        >
                                            <TableCell
                                                // component="td"
                                                align='left'
                                                colSpan={3}
                                            >
                                                <Grid item display='flex' alignItems='center' >
                                                    {expandedRow?.includes(index) ? <ExpandMoreIcon sx={{ fontSize: '18px', mr: 1, color: "secondary.main" }} /> : <ChevronRightIcon sx={{ fontSize: '18px', mr: 1, color: "secondary.main" }} />}
                                                    {report?.date} ({moment(report?.date, "DD/MM/YYYY").format("dddd")})
                                                </Grid>
                                            </TableCell>
                                            <TableCell>{report?.user_activities?.length === 1 ? "00:00" : durationToWord(report?.working_hrs)}</TableCell>
                                            {/* <TableCell>{durationToWord(report?.totalWorking?.working_hrs)}</TableCell> */}
                                            <TableCell></TableCell>
                                        </TableRow>
                                        {expandedRow?.includes(index) ? <React.Fragment>
                                            {report?.user_activities?.length > 1 ?
                                                report?.user_activities?.map((activity, childIndex, array) => {
                                                    let isShow = isShowActivity(activity, childIndex, array);
                                                    return (
                                                        activity?.log_type !== "logout" ? <TableRow hover key={childIndex}
                                                            sx={{
                                                                'td': {
                                                                    color: "dark.800",
                                                                    fontSize: '14px',
                                                                    fontWeight: '400',
                                                                    lineHeight: '20px',
                                                                    letterSpacing: '0.17px',
                                                                    padding: '12px 16px'
                                                                }
                                                            }}
                                                        >
                                                            {isShow ? <TableCell></TableCell> : null}
                                                            {isShow ? <TableCell>{titleCase(activity?.activity)}</TableCell> : null}
                                                            {isShow ? (activity?.log_type === "logout" ?
                                                                <TableCell>{timestampToTime(activity?.createdAt)}</TableCell>
                                                                :
                                                                <TableCell>{timestampToTime(activity?.createdAt)} - {timestampToTime(array[childIndex + 1]?.createdAt)}</TableCell>
                                                            ) : null}
                                                            {isShow ? <TableCell>{activity?.log_type === "logout" ? "" : durationToWord(activity?.duration)}</TableCell> : null}
                                                            {isShow ? <TableCell sx={{ padding: '12px 0px', width: '40px', maxWidth: '40px', minWidth: '40px' }}>
                                                                {isWorkingType(activity?.activity) ?
                                                                    <Box sx={{
                                                                        width: "8px",
                                                                        height: "8px",
                                                                        borderRadius: "50px",
                                                                        backgroundColor: "primary.main"
                                                                    }}> </Box>
                                                                    : null}
                                                            </TableCell> : null}
                                                        </TableRow> : null
                                                    )
                                                }) : <EmptyTable message='No activities for today' />}
                                            {report?.user_activities?.length > 1 ?
                                                <TableRow hover
                                                    sx={{
                                                        'td': {
                                                            color: "dark.800",
                                                            fontSize: '14px',
                                                            fontWeight: '700',
                                                            lineHeight: '20px',
                                                            letterSpacing: '0.17px',
                                                            padding: '12px 16px'
                                                        }
                                                    }}
                                                >
                                                    <TableCell></TableCell>
                                                    <TableCell sx={{ color: '#3F5C76 !important' }}>Total Working Hours</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell sx={{ color: '#3F5C76 !important' }}>{durationToWord(report?.working_hrs)}</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow> : null}
                                            {report?.user_activities?.length > 1 ?
                                                <TableRow hover
                                                    sx={{
                                                        'td': {
                                                            color: "dark.800",
                                                            fontSize: '14px',
                                                            fontWeight: '700',
                                                            lineHeight: '20px',
                                                            letterSpacing: '0.17px',
                                                            padding: '12px 16px'
                                                        }
                                                    }}
                                                >
                                                    <TableCell></TableCell>
                                                    <TableCell>Total Duration</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell>{durationToWord(report?.total)}</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow> : null}
                                            <React.Fragment />
                                        </React.Fragment>
                                            : null}
                                    </React.Fragment>
                                )) : <EmptyTable message='No data found.' />}
                        </TableBody>
                    </Table>
                </TableContainer>
                {totalCount ? <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={queryParams.perPage}
                    page={queryParams.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> : null}
            </Paper>
        </Box>
    )
}

export default UserSummary;