/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import Button from "../../../components/Button";
import { ON_BOARDING_STEPS } from "../../../constants/default-values";
import URLS from "../../../routes/urls";
import { useNavigate } from "react-router-dom";
import API from "../../../axios/api";
import axiosInstance from "../../../axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import {
  areDatesEqual,
  generateUniqueId,
  getFormattedDate,
  getYearObject,
  isDashedYearBasedOnMonth,
  titleCase,
} from "../../../utils";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SingleDatePicker from "../../../components/SingleDatePicker";
import { useAlert } from "../../../hook/useAlert";
import CircularLoader from "../../../components/CircularLoader";
import AutocompleteWithAddOption from "../../../components/AutocompleteWithAddOption";
import { usePageTitle } from "../../../hook/useTitle";
import { setUserOnBoardingStep } from "../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";

let initialFestivalData = {
  unique_id: generateUniqueId(),
  date: "",
  name: "",
};
const HolidaySettingStep = forwardRef(({ setOnBoardingStep, currentUser, isEditField, setIsEditField, handleShowSaveModal }, ref) => {
  const navigate = useNavigate();
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const { setPageTitle } = usePageTitle();
  const [festivalAddData, setFestivalAddData] = useState(initialFestivalData);
  const [holidayYears, setHolidayYears] = useState([]);
  const [selectedHolidayData, setSelectedHolidayData] = useState({});
  const [holidaySettingData, setHolidaySettingData] = useState({
    holidaySundayDates: [],
    holidaySaturdayDates: [],
  });
  const [holidayTitles, setHolidayTitles] = useState([]);
  const [isLoading, setIsLoading] = useState({
    pageLoading: true,
    holidayCountLoading: true,
  });
  const [activeYear, setActiveYear] = useState({});

  setPageTitle("Define your Holidays");

  const getAllHolidayYearsData = async (org_id) => {
    try {
      const config = {
        params: {
          renewalMonth: true
        },
      };
      const response = await axiosInstance.get(API.getHolidayYears(org_id), config);
      if (response.status === 200) {
        let sortedYear = response.data?.result?.sort((a, b) =>
          a?.year?.toString()?.localeCompare(b?.year?.toString())
        );
        setHolidayYears(sortedYear);
        let activeYear = sortedYear?.find(yearData => yearData?.active);
        setActiveYear(activeYear);
        formik.setValues({
          ...formik.values,
          year: activeYear?.year,
          setting_start_date: activeYear?.setting_start_date,
          setting_end_date: activeYear?.setting_end_date,
        });
        let firstDayDate = new Date(activeYear?.setting_start_date);
        setFestivalAddData((prev) => ({ ...prev, date: firstDayDate }));
        getHolidayData(currentUser?.organization_id, sortedYear[0]?.id);
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  const getHolidayData = async (org_id, id) => {
    try {
      const response = await axiosInstance.get(
        API.getAllUserHolidays(org_id, id)
      );
      if (response.status === 200) {
        setSelectedHolidayData(response.data?.data);
        setIsLoading((prev) => ({ ...prev, pageLoading: false }));
      } else {
        setIsLoading((prev) => ({ ...prev, pageLoading: false }));
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  useEffect(() => {
    getAllHolidayYearsData(currentUser?.organization_id);
  }, [currentUser?.organization_id]);

  let initialValues = {
    year: selectedHolidayData?.year || null,
    saturday_setting_status: selectedHolidayData?.saturday_setting !== "false",
    sunday_setting_status: selectedHolidayData?.sunday_setting !== "false",
    saturday_setting: selectedHolidayData?.saturday_setting || "false",
    sunday_setting: selectedHolidayData?.sunday_setting || "false",
    festival_holiday: selectedHolidayData?.festival_holidays || [],
    custom_holiday: selectedHolidayData?.custom_holidays || [],
    setting_start_date: activeYear?.setting_start_date,
    setting_end_date: activeYear?.setting_end_date,
  };

  const validationSchema = Yup.object({
    year: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      let { redirectStep } = values;
      let body = {
        ...values,
        from_organization: true,
        festival_holiday: values?.festival_holiday?.filter((value) => {
          if (!!value?.is_deleted && !value?.id)
            return false;
          else {
            return true;
          }
        })?.map((value) => {
          let updatedValue = { date: value?.date, name: value?.name };
          if (value?.id) {
            updatedValue["id"] = value?.id;
          }
          if (value?.is_deleted) {
            updatedValue["is_deleted"] = value?.is_deleted;
          }
          return updatedValue;
        }),
      };
      let activeYearIndex = holidayYears?.findIndex(value => value?.active)
      let nextYearIndex = activeYearIndex + 1;
      let nextYear = holidayYears[nextYearIndex]?.year;
      if (activeYearIndex >= 0 && !!nextYear) {
        body.next_year = nextYear
      }

      delete body.sunday_setting_status;
      delete body.saturday_setting_status;

      let yearId = getYearData(values?.year)?.id;
      const response = await axiosInstance.put(
        API.updateHolidaySetting(currentUser?.organization_id, yearId),
        body
      );
      if (response.status === 200) {
        showAlert(response?.data.message);
        navigate(`${URLS.onBoarding}/${redirectStep}`, {
          replace: true,
        });
        setOnBoardingStep(redirectStep);
        dispatch(setUserOnBoardingStep(redirectStep));
      } else {
        showAlert(response?.data.message, "error");
      }
    },
  });

  useImperativeHandle(ref, () => ({
    handleSubmitHolidaySettings: () => handleSubmitHolidaySettings(ON_BOARDING_STEPS.step2)
  }));

  const handleSubmitHolidaySettings = (redirectStep) => {
    formik.setValues({
      ...formik.values,
      redirectStep,
    })
    formik.handleSubmit();
    return true;
  };

  const handlePreviousStep = () => {
    if (isEditField) {
      handleShowSaveModal();
    } else {
      navigate(`${URLS.onBoarding}/${ON_BOARDING_STEPS.step2}`, {
        replace: true,
      });
      setOnBoardingStep(ON_BOARDING_STEPS.step2);
    }
  };

  const handleChangeHolidaySetting = (data) => {
    let ignoreDates = [
      ...data?.holidaySundayDates,
      ...data?.holidaySaturdayDates,
    ]?.map((value) => getFormattedDate(value));
    const filteredArray = formik.values?.festival_holiday.map((obj) => {
      const objDate = obj.id
        ? moment(obj.date, "YYYY-MM-DD").format("DD/MM/YYYY")
        : moment(obj.date).format("DD/MM/YYYY");
      let isMatch = ignoreDates.some((value) => value === objDate);
      if (isMatch) {
        if (obj?.id) return { ...obj, is_deleted: true };
        else return null;
      } else {
        return obj;
      }
    });
    formik.setValues({
      ...formik.values,
      festival_holiday: filteredArray?.filter((value) => !!value),
    });
  };

  const handleSkipStep = async () => {
    try {
      const response = await axiosInstance.put(
        API.skipStepForOnBoarding(currentUser?.organization_id, "holiday_setting"));
      if (response.status === 200) {
        navigate(`${URLS.onBoarding}/${ON_BOARDING_STEPS.step4}`, {
          replace: true,
        });
        setOnBoardingStep(ON_BOARDING_STEPS.step4);
        dispatch(setUserOnBoardingStep(ON_BOARDING_STEPS.step4));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFestivalAddDelete = (type, holidayData) => {
    if (type === "delete") {
      let updatedList = [...formik?.values?.festival_holiday]?.map((value) => {
        if (!!value?.unique_id && value?.unique_id === holidayData?.unique_id) {
          return { ...value, is_deleted: true };
        } else if (!!holidayData?.id && value?.id === holidayData?.id) {
          return { ...value, is_deleted: true };
        } else {
          return value;
        }
      });
      formik.setValues({
        ...formik.values,
        festival_holiday: updatedList,
      });
    } else {
      const allDatesData = formik.values?.festival_holiday?.filter((value) => !value?.is_deleted);
      const isAlreadyInclude = allDatesData?.some((value) => areDatesEqual(value?.date, holidayData?.date));
      const isAlreadyIncludeFestival = allDatesData?.some((value) => value?.name?.toLowerCase() === festivalAddData?.name?.toLowerCase());
      if (isAlreadyInclude) {
        showAlert("Holiday date already exist", "error")
      } else if (isAlreadyIncludeFestival) {
        showAlert("Holiday title already exist", "error")
      } else {
        const holidayArray = [
          ...formik.values?.festival_holiday,
          {
            ...holidayData,
            date: getFormattedDate(holidayData?.date, "YYYY-MM-DD"),
            unique_id: generateUniqueId()
          },
        ];
        formik.setValues({ ...formik.values, festival_holiday: holidayArray });
        setFestivalAddData({ ...initialFestivalData, date: "" });
      }
    }
  };

  let getYearData = (value) => {
    return holidayYears?.find((holiday) => holiday?.year === value);
  };

  const handleChangeHolidayYear = (event) => {
    setIsEditField(true);
    let selectedData = holidayYears?.find(
      (holiday) => holiday?.id === event.target.value
    );
    formik.setValues({
      ...formik.values,
      year: selectedData?.year,
      setting_start_date: selectedData.setting_start_date,
      setting_end_date: selectedData?.setting_end_date,
    });
    let year = selectedData?.year;
    let firstDayDate = new Date();
    firstDayDate.setFullYear(year);
    setFestivalAddData((prev) => ({ ...prev, date: firstDayDate }));
    getHolidayData(currentUser?.organization_id, selectedData?.id);
  };

  const getHolidayListBySettings = useMemo(async () => {
    try {
      setIsLoading((prev) => ({ ...prev, holidayCountLoading: true }));
      let year = getYearData(formik.values?.year);
      if (!isLoading?.pageLoading) {
        let body = {
          saturday_setting: formik.values?.saturday_setting,
          sunday_setting: formik.values?.sunday_setting,
          setting_start_date: year?.setting_start_date,
          setting_end_date: year?.setting_end_date,
        };
        let response = await axiosInstance.post(
          API.getHolidayDatesBySettings(currentUser?.organization_id, year?.id),
          body
        );
        if (response.status === 200) {
          let { holidaySaturdayDates, holidaySundayDates } = response.data;
          handleChangeHolidaySetting(response.data);
          setHolidaySettingData({ holidaySaturdayDates, holidaySundayDates });
          setIsLoading((prev) => ({ ...prev, holidayCountLoading: false }));
        } else {
          setIsLoading((prev) => ({ ...prev, holidayCountLoading: false }));
        }
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, holidayCountLoading: false }));
    }
  }, [
    currentUser?.organization_id,
    formik.values?.saturday_setting,
    formik.values?.sunday_setting,
    formik.values?.year,
  ]);

  let getIgnoreDates = () => {
    let array = [
      ...formik.values?.festival_holiday?.filter((value) => !value?.is_deleted)?.map((value) => value?.date),
    ];
    if (formik.values.sunday_setting !== "false") {
      array.push(...holidaySettingData?.holidaySundayDates);
    }
    if (formik.values.saturday_setting !== "false") {
      array.push(...holidaySettingData?.holidaySaturdayDates);
    }
    return Array.from(new Set(array?.map((date) => getFormattedDate(date))));
  };

  return (
    <Box display="flex" flexDirection="column" width={"100%"}>
      {isLoading?.pageLoading ? (
        <CircularLoader />
      ) : (
        <Box>
          <Stack spacing={4} sx={{ flexBasis: "100%" }}>
            <Typography color="secondary.800" fontSize={20}>
              Create a Organisation (Step 3 of 5)
            </Typography>
            <Grid container>
              <Typography
                color="secondary"
                fontSize={{ xs: 28, lg: 32 }}
                lineHeight={{ xs: "38px", lg: "44px" }}
                mb={2}
              >
                Define your Holidays
              </Typography>
              <Typography variant="body2" color="dark.800" width="100%">
                All the National holidays and week days. Leave and salary
                calculation will affect based on this holiday setup.
              </Typography>
              <Grid Item mt={2}>
                <Grid Item display="flex">
                  <Grid Item width={230} mr={3}>
                    <Typography
                      id="demo-simple-select-standard-label"
                      fontSize={12}
                      fontWeight={400}
                      color="dark.600"
                      sx={{
                        width: "100%",
                        letterSpacing: "0.15px",
                        marginTop: "-3px",
                        marginBottom: "6px",
                      }}
                    >
                      Yearly Renewal Month
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={400}
                      color="dark.800"
                      sx={{ lineHeight: "24px", letterSpacing: "0.15px" }}
                    >
                      {selectedHolidayData?.renewal_month}
                    </Typography>
                  </Grid>
                  <Grid Item width={{ xs: 275, md: 325 }}>
                    <FormControl
                      variant="standard"
                      sx={{ maxWidth: "100%", width: '100%' }}
                    >
                      <InputLabel
                        id="demo-simple-select-standard-label"
                        sx={{ color: "dark.600" }}
                      >
                        Select Year
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        name="year"
                        value={getYearData(formik.values.year)?.id || ""}
                        onChange={(event) => {
                          handleChangeHolidayYear(event);
                        }}
                        onBlur={(e) => formik.handleBlur(e)}
                        error={formik.touched.year && Boolean(formik.errors.year)}
                        label="Select Year"
                        disabled={true}
                      >
                        {holidayYears?.map((yearData, index) => (
                          <MenuItem value={yearData?.id} key={index}>
                            {getYearObject(yearData)?.yearDashedFormat}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.year ? (
                        <FormHelperText sx={{ color: "red" }}>
                          {formik.errors.year}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>

                <Typography
                  mt={2}
                  color="secondary"
                  fontWeight={400}
                  fontSize={12}
                  sx={{ lineHeight: "12px", letterSpacing: "0.15px" }}
                >
                  Organisational Year{" "}
                  <b style={{ fontSize: "15px" }}>
                    {
                      isDashedYearBasedOnMonth(
                        selectedHolidayData?.renewal_month,
                        selectedHolidayData?.year
                      )?.yearDashedFormat
                    }
                  </b>{" "}
                  (
                  {moment(formik?.values?.setting_start_date)?.format(
                    "Do MMMM YYYY"
                  )}{" "}
                  -{" "}
                  {moment(formik?.values?.setting_end_date)?.format(
                    "Do MMMM YYYY"
                  )}
                  )
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid>
              <Grid Item>
                <Typography
                  color="dark.800"
                  mb={1}
                  fontSize={20}
                  fontWeight={400}
                  sx={{ lineHeight: "30px", letterSpacing: "0.15px" }}
                >
                  Office Holidays for{" "}
                  <b>
                    {
                      isDashedYearBasedOnMonth(
                        selectedHolidayData?.renewal_month,
                        formik.values?.year
                      )?.yearDashedFormat
                    }
                  </b>
                </Typography>
              </Grid>
              <Grid container>
                <Grid Item minWidth={120} maxWidth={120}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="saturday_setting_status"
                        checked={formik.values.saturday_setting_status}
                        value={formik.values.saturday_setting_status}
                        onChange={(e) => {
                          setIsEditField(true);
                          formik.setFieldValue(
                            "saturday_setting_status",
                            e.target.checked
                          );
                          if (!e.target.checked) {
                            formik.setFieldValue("saturday_setting", "false");
                          } else {
                            formik.setFieldValue("saturday_setting", "all");
                          }
                        }}
                      />
                    }
                    label="Saturday"
                    sx={{
                      ".MuiCheckbox-sizeMedium": {
                        minWidth: "42px",
                      },
                    }}
                  />
                </Grid>
                {Boolean(formik.values.saturday_setting_status) ? (
                  <Grid Item flex={1}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontSize: 14,
                          color: "rgba(38, 38, 38, 0.87)",
                          fontWeight: 400,
                        },
                        ".MuiFormControlLabel-root:not(:last-child)": {
                          marginRight: 3,
                        },
                      }}
                      name="saturday_setting"
                      value={formik.values.saturday_setting}
                      onChange={(event) => { formik.handleChange(event); setIsEditField(true); }}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio size="small" />}
                        label="All Saturday"
                      />
                      <FormControlLabel
                        value="first_third"
                        control={<Radio size="small" />}
                        label="1st, 3rd and 5th Saturday"
                      />
                      <FormControlLabel
                        value="second_fourth"
                        control={<Radio size="small" />}
                        label="2nd and 4rd Saturday"
                      />
                      <FormControlLabel
                        value="alternate"
                        control={<Radio size="small" />}
                        label="Alternate"
                      />
                    </RadioGroup>
                  </Grid>
                ) : null}
                {Boolean(formik.values.saturday_setting) &&
                  ["all", "first_third", "second_fourth", 'alternate'].includes(
                    formik.values.saturday_setting
                  ) ? (
                  <Grid>
                    <Typography
                      color="primary"
                      fontSize={12}
                      fontWeight={500}
                      mt={1.5}
                      sx={{ lineHeight: "18px", letterSpacing: "0.15px" }}
                    >
                      {holidaySettingData?.holidaySaturdayDates?.length || 0}{" "}
                      Saturday
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
              <Grid container>
                <Grid Item minWidth={120} maxWidth={120}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="sunday_setting_status"
                        checked={formik.values.sunday_setting_status}
                        value={formik.values.sunday_setting_status}
                        onChange={(e) => {
                          setIsEditField(true);
                          formik.setFieldValue(
                            "sunday_setting_status",
                            e.target.checked
                          );
                          if (!e.target.checked) {
                            formik.setFieldValue("sunday_setting", "false");
                          } else {
                            formik.setFieldValue("sunday_setting", "all");
                          }
                        }}
                      />
                    }
                    label="Sunday"
                    sx={{
                      ".MuiCheckbox-sizeMedium": {
                        minWidth: "42px",
                      },
                    }}
                  />
                </Grid>
                {Boolean(formik.values.sunday_setting_status) ? (
                  <Grid Item flex={1}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontSize: 14,
                          color: "rgba(38, 38, 38, 0.87)",
                          fontWeight: 400,
                        },
                        ".MuiFormControlLabel-root:not(:last-child)": {
                          marginRight: 3,
                        },
                      }}
                      name="sunday_setting"
                      value={formik.values.sunday_setting}
                      onChange={(event) => { formik.handleChange(event); setIsEditField(true); }}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio size="small" />}
                        label="All Sunday"
                      />
                      <FormControlLabel
                        value="first_third"
                        control={<Radio size="small" />}
                        label="1st, 3rd and 5th Sunday"
                      />
                      <FormControlLabel
                        value="second_fourth"
                        control={<Radio size="small" />}
                        label="2nd and 4rd Sunday"
                      />
                      <FormControlLabel
                        value="alternate"
                        control={<Radio size="small" />}
                        label="Alternate"
                      />
                    </RadioGroup>
                  </Grid>
                ) : null}
                {Boolean(formik.values.sunday_setting) &&
                  ["all", "first_third", "second_fourth", 'alternate'].includes(
                    formik.values.sunday_setting
                  ) ? (
                  <Grid>
                    <Typography
                      color="primary"
                      fontSize={12}
                      fontWeight={500}
                      mt={1.5}
                      sx={{ lineHeight: "18px", letterSpacing: "0.15px" }}
                    >
                      {holidaySettingData?.holidaySundayDates?.length || 0}{" "}
                      Sunday
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>

            </Grid>
            <Divider />
            <Grid>
              <Grid Item>
                <Typography
                  color="dark.800"
                  mb={1}
                  fontSize={20}
                  fontWeight={400}
                  sx={{ lineHeight: "30px", letterSpacing: "0.15px" }}
                >
                  Festival Holidays for{" "}
                  <b>
                    {
                      isDashedYearBasedOnMonth(
                        selectedHolidayData?.renewal_month,
                        formik.values?.year
                      )?.yearDashedFormat
                    }
                  </b>
                </Typography>
              </Grid>
            </Grid>
            <Grid width={650} sx={{ maxWidth: "100%" }}>
              <TableContainer sx={{ overflow: 'visible' }}>
                <Table>
                  <TableBody
                    sx={{
                      td: {
                        padding: "5px 4px",
                        color: "dark.800",
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                        border: "none",
                      },
                      "td:first-child": {
                        paddingLeft: 0,
                      },
                      "td:last-child": {
                        padding: 0,
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          width: "276px",
                          minWidth: "276px",
                          maxWidth: "276px",
                          paddingRight: '16px !important',
                          paddingBottom: '20px !important',
                        }}
                      >
                        <Grid
                          Item
                          flex={1}
                          sx={{
                            input: {
                              width: "100%",
                              height: 30,
                              border: "none !important",
                              boxShadow: "none !important",
                              borderRadius: "0px",
                              paddingBottom: "6px",
                              paddingLeft: "28px",
                              borderBottom:
                                "1px solid rgba(0, 0, 0, 0.42) !important",
                              margin: "0",
                              position: "relative",
                              background: "transparent",
                              color: "dark.800",
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            },
                            "input:hover": {
                              borderBottomColor: "#333333 !important",
                            },
                            "input:focus": {
                              borderBottomColor: "#047FE0 !important",
                            },
                            ".rmdp-container": {
                              minWidth: "100px",
                            },
                          }}
                        >
                          <Typography
                            id="demo-simple-select-standard-label"
                            fontSize={12}
                            fontWeight={400}
                            color="dark.600"
                            sx={{
                              width: "100%",
                              letterSpacing: "0.15px",
                              marginTop: "-3px",
                              marginBottom: "2px",
                            }}
                          >
                            Holiday Date
                          </Typography>
                          <div style={{ height: 1 }}>
                            <CalendarTodayIcon
                              sx={{
                                fontSize: 20,
                                color: "#3F5C76",
                                marginBottom: "-9px",
                                marginLeft: "2px",
                              }}
                            />
                          </div>
                          <SingleDatePicker
                            renewal_month={selectedHolidayData?.renewal_month}
                            year={selectedHolidayData?.year}
                            value={festivalAddData?.date || ""}
                            ignoreDates={getIgnoreDates()}
                            onChange={(value) => {
                              setIsEditField(true);
                              setFestivalAddData((prev) => ({
                                ...prev,
                                date: new Date(value),
                              }));
                            }}
                            isMultiple={false}
                            isOnlyAllowCurrentYearDates={true}
                            minDate={moment(formik?.values?.setting_start_date).toDate()}
                            maxDate={moment(formik?.values?.setting_end_date).toDate()}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell sx={{
                        minWidth: "261px !important",
                        maxWidth: "261px !important",
                        padding: "0px 16px 16px 16px !important"
                      }}>
                        <AutocompleteWithAddOption
                          id="standard-basic"
                          label="Festival / Occasion"
                          variant="standard"
                          sx={{
                            width: "100%",
                            "input": {
                              textTransform: 'capitalize',
                            }
                          }}
                          disabledItems={formik?.values?.festival_holiday?.filter((value) => !value?.is_deleted)}
                          value={festivalAddData.name}
                          onChange={(event, newValue) => {
                            setIsEditField(true);
                            setFestivalAddData((prev) => ({
                              ...prev,
                              name: titleCase(newValue),
                            }))
                          }}
                          setHolidayTitles={setHolidayTitles}
                          holidayTitles={holidayTitles}
                        />
                      </TableCell>
                      <TableCell sx={{
                        padding: "0px 0px 10px 16px !important",
                        width: '80px',
                        maxWidth: '80px',
                        minWidth: '80px',
                        textAlign: 'center',
                      }} >
                        <Button
                          variant="contained"
                          color="secondary"
                          size="medium"
                          sx={{
                            boxShadow: "none",
                            marginTop: "6px",
                            textTransform: "none",
                          }}
                          onClick={() => handleFestivalAddDelete("add", festivalAddData)}
                          disabled={!(festivalAddData?.date && festivalAddData?.name?.length)}
                        >
                          Add
                        </Button>
                      </TableCell>
                    </TableRow>
                    {formik.values?.festival_holiday
                      ?.filter((value) => !value?.is_deleted)
                      ?.map((holiday, index) => (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              padding: '0px 16px 0px 0px !important'
                            }}
                          >
                            <Box sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: 2,
                            }}>
                              <Typography color="dark.800" fontSize={14} fontWeight={500} sx={{ lineHeight: '24px', letterSpacing: '0.15px' }}>
                                {index + 1}. {"  "}
                                {moment(holiday.date).format("DD/MM/YYYY")}
                              </Typography>
                              <Typography color="dark.800" fontSize={14} fontWeight={500} sx={{ lineHeight: '24px', letterSpacing: '0.15px' }}>
                                {moment(holiday.date).format("dddd")}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell sx={{
                            padding: "0px 16px !important"
                          }}>
                            - {holiday.name}
                          </TableCell>
                          <TableCell sx={{
                            padding: "0px 0px 0px 16px !important",
                            width: '80px',
                            maxWidth: '80px',
                            minWidth: '80px',
                            textAlign: 'center',
                          }}>
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() =>
                                handleFestivalAddDelete("delete", holiday)
                              }
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Stack>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={4}
          >
            <Button
              variant="text"
              color="secondary"
              sx={{ fontSize: "15px", textTransform: "none" }}
              onClick={handlePreviousStep}
            >
              Previous
            </Button>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              flexBasis="100%"
            >
              <Button
                variant="text"
                color="secondary"
                sx={{ fontSize: "15px", textTransform: "none" }}
                onClick={handleSkipStep}
              >
                Skip
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  boxShadow: "0",
                  color: "white",
                  fontSize: "15px",
                  textTransform: "none",
                  marginLeft: "20px",
                  "&:hover": { boxShadow: "0" },
                }}
                onClick={() => {
                  formik.setValues({
                    ...formik.values,
                    redirectStep: ON_BOARDING_STEPS.step4,
                  })
                  formik.handleSubmit();
                }}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
});

export default HolidaySettingStep;
