/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Chip,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ICONS from "../../../../constants/icons";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { styled } from "@mui/styles";
import CustomModal from "../../../../components/CustomModal";
import TextEditor from "../../../../components/TextEditor";
import FileDropZone from "../../../../components/FileDropZone";
import { useAlert } from "../../../../hook/useAlert";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import CircularLoader from "../../../../components/CircularLoader";
import { LEAVE_DURATION_TYPE, LEAVE_SNAP_COLORS, LEAVE_STATUS } from "../../../../constants/default-values";
import moment from "moment";
import { getApplicationNumber, getApplyDateTime, getDayName, getFormattedDate, getFullName, getLeaveDateArray, getLeaveStatus, showInformationAuthority, sortArrayByKey, titleCase } from "../../../../utils";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import { useNavigate, useParams } from "react-router-dom";
import EmptyPage from "../../../../components/EmptyPage";
import { usePageTitle } from "../../../../hook/useTitle";
import BackgroundLetterAvatars from "../../../../components/BackgroundLetterAvatars";
import UserRoleChip from "../../../../components/UserRoleChip";
import useAuthentication from "../../../../hook/useAuthentication";
import URLS from "../../../../routes/urls";
import Breadcrumb from "../../../../components/Breadcrumb";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "16px",
    borderRadius: "5px",
    background: "#3F5C76",
  },
}));

const OthersLeaveDetails = () => {
  const showAlert = useAlert();
  const navigate = useNavigate();
  const { getCurrentUser } = useAuthentication();
  const currentUser = getCurrentUser();
  const params = useParams();
  const { setPageTitle } = usePageTitle();
  const initialModalDetails = {
    isShow: false,
    type: null,
    comment: "",
  };
  const [modalDetails, setModalDetails] = useState(initialModalDetails);
  const [leaveDetails, setLeaveDetails] = useState(null);
  const [leaveSnapshot, setLeaveSnapshot] = useState(null);
  const [isLoading, setIsLoading] = useState({
    leaveDetails: true,
    modalButtonLoading: false
  })

  setPageTitle("Other's Leave Details");

  useEffect(() => {
    if (currentUser?.id) {
      if (Number(currentUser?.id) !== Number(params?.userId)) {
        getUserSettingData(params?.userId, params?.appId);
      } else {
        navigate(URLS.AccessDenied)
      }
    }
  }, [params])

  const getTotalCreditUsedCount = (leave_array) => {
    let totalCreditUsed = 0;
    leave_array?.forEach((leave) => {
      totalCreditUsed += leave?.credit_used || 0;
    });
    return totalCreditUsed || 0;
  };

  const handleOpen = (type) => {
    setModalDetails({
      isShow: true,
      type,
    });
  };

  const getApplyDays = (leave_days) => {
    let count = leave_days?.reduce((previousValue, currentValue, currentIndex, array) => {
      let paidCount = currentValue?.paid || 0;
      let unPaidCount = currentValue?.unpaid || 0
      previousValue.paidCount = previousValue.paidCount + paidCount;
      previousValue.unPaidCount = previousValue.unPaidCount + unPaidCount;
      previousValue.totalCount = previousValue.paidCount + previousValue.unPaidCount
      return previousValue;
    }, { paidCount: 0, unPaidCount: 0, totalCount: 0, });
    return count;
  }

  const handleSubmitLeaveApplication = async (status, comment) => {
    try {
      setIsLoading((prev) => ({ ...prev, modalButtonLoading: true }))
      let payload = {
        "processed_description": comment,
        "status": leaveDetails?.status,
        "action": status
      };

      const response = await axiosInstance.put(
        API.updateLeaveStatus(params?.userId, params?.appId),
        payload,
      );
      if (response.status === 200) {
        showAlert(response?.data.message);
        setIsLoading((prev) => ({ ...prev, modalButtonLoading: false }))
        navigate(`${URLS.Leaves}?view=list&tabValue=0&groupBy=Status&status=active`);
        // navigate(-1);
      }
      else {
        showAlert(response?.response?.data.message, "error");
        setIsLoading((prev) => ({ ...prev, modalButtonLoading: false }))
        // navigate(-1);
      }
    } catch (error) {
      console.error("Error:", error);
      showAlert(error?.response?.data.message, "error");
      setIsLoading((prev) => ({ ...prev, modalButtonLoading: false }))
      // navigate(-1);
    }
  };

  const handleApprove = () => {
    let status = modalDetails?.type;
    if (!!modalDetails?.comment && modalDetails?.comment?.trim()?.length) {
      handleSubmitLeaveApplication(status, modalDetails?.comment)
      setModalDetails(initialModalDetails);
    } else {
      setModalDetails((prev) => ({ ...prev, error: "Required" }));
    }
  };

  const handleCancel = () => {
    setModalDetails(initialModalDetails);
  };

  const getUserSettingData = async (user_id, id) => {
    try {
      const response = await axiosInstance.get(API.getLeaveById(user_id, id));
      const response2 = await axiosInstance.get(API.getLeaveBalance(user_id));
      if (response2.status === 200) {
        setLeaveSnapshot(response2?.data?.result);
      }
      if (response.status === 200) {
        setLeaveDetails(response?.data?.result || {})
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      } else {
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
    }
  };

  const handleClickBack = () => {
    navigate(URLS.Leaves);
  }

  const getRemainingCreditsData = () => {
    return leaveSnapshot?.find((data) => Object.keys(data)?.includes('specialCredits'))
  }

  const getMatchData = (leaveDetail) => {
    let usedSpecialRequest = [...leaveDetail?.specialRequest];
    const isExistRule = (creditListData) => {
      return usedSpecialRequest?.includes(creditListData?.id)
    }

    return (
      <>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="white" mb="0">
                {/* {leaveDetail?.match === 'fully' ? 'Match' : leaveDetail?.match === "true" ?
                  `${leaveDetail?.specialRequest?.length} credit will be used` : 'Request Status'} */}
                Request Status
              </Typography>
              <List
                sx={{
                  padding: 0,
                  // marginLeft: '25px',
                  li: {
                    listStyle: "decimal",
                    display: "list-item",
                    paddingLeft: '5px',
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '17px',
                    paddingBottom: '0px',
                    marginBottom: '0px'
                  },
                  'li:last-child': {
                    marginBottom: '0px'
                  }
                }}
              >
                {leaveDetail?.match === 'fully' ?
                  getRemainingCreditsData()?.specialCreditsList?.length ?
                    getRemainingCreditsData()?.specialCreditsList?.filter(data => data?.leave_credit)?.map((creditListData) => (
                      <ListItem sx={{ listStyle: 'none !important', paddingLeft: '0 !important', 'svg': { display: 'block', } }}>
                        <i style={{ background: '#ffffff', height: '18px', width: '18px', borderRadius: '50%', marginRight: '10px', display: 'inline-block', verticalAlign: 'Top' }}>
                          {ICONS.CheckIcon}
                        </i>{titleCase(creditListData?.name)}</ListItem>
                    )) : null : getRemainingCreditsData()?.specialCreditsList?.filter(data => data?.leave_credit)?.map((creditListData) => (
                      <ListItem sx={{ listStyle: 'none !important', paddingLeft: '0 !important', 'svg': { display: 'block', } }}>
                        <i style={{ background: '#ffffff', height: '18px', width: '18px', borderRadius: '50%', marginRight: '10px', display: 'inline-block', verticalAlign: 'Top' }}>
                          {isExistRule(creditListData) ? ICONS.CrossIcon : ICONS.CheckIcon}
                        </i>{titleCase(creditListData?.name)}</ListItem>
                    ))}
              </List>
            </React.Fragment>
          }
        >
          <i
            style={{
              height: 20,
              width: 20,
              display: "inline-block",
              verticalAlign: "middle",
            }}
          >
            {leaveDetail?.match === 'fully' ? <CheckCircleRoundedIcon
              color="primary"
              sx={{ fontSize: 20 }}
            /> : <ErrorOutlineRoundedIcon
              color={leaveDetail?.match === "true" ? "primary" : "error"}
              sx={{ fontSize: 20 }}
            />}
          </i>
        </HtmlTooltip>
      </>
    )
  }

  const checkApplicationEnable = (leaveData) => {
    const minLeaveDate = leaveData?.leave_days.reduce((min, leave) => {
      const leaveDate = new Date(leave.leave_date);
      return leaveDate < min ? leaveDate : min;
    }, new Date(leaveData?.leave_days[0].leave_date));

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const result = today <= minLeaveDate;
    return result;
  }

  const isShowButton = (leaveData) => {
    let isAdmin = currentUser?.is_admin;
    if (isAdmin) {
      return true
    } else {
      return checkApplicationEnable(leaveData)
    }
  }

  return (
    isLoading?.leaveDetails ?
      <CircularLoader height="600px" /> :
      !leaveDetails ?
        <EmptyPage
          title="No leaves application available"
          buttonStyle={{ color: "white" }}
          buttonColor="secondary"
          onClick={() => navigate(-1)}
        /> :
        <Grid width="100%" display={"flex"} flexDirection={"column"}>
          <Breadcrumb isBack={true} title={"Other’s Leaves"}
            pageTitle={'Leave Detail'}
            isPathShow={false}
            onBackClick={() => handleClickBack()} />

          <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} width="100%" flex={1} overflow="auto">
            <Box pb={2} mb={2} sx={{ borderBottom: "1px solid #E2E4EC" }}>
              <Grid container justifyContent="space-between" flexWrap="nowrap">
                <Grid Item display="flex" alignItems="center" pr={2.5} overflow="hidden">
                  <BackgroundLetterAvatars
                    user={leaveDetails?.userData}
                    src={leaveDetails?.userData?.profile_img || "image.png"}
                    sx={{ width: { xs: 54, xl: 64 }, height: { xs: 54, xl: 64 }, fontSize: '120%' }}
                  />
                  <Box ml={2} display="flex" flexWrap="wrap" overflow="hidden">
                    <Box display="flex" alignItems={"center"}>
                      <Typography
                        variant="body1"
                        color="secondary"
                        fontSize={18}
                        fontWeight={500}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        sx={{
                          lineHeight: "27px",
                          marginBottom: "0px",
                          display: "block",
                          width: "100%",
                        }}
                      >
                        {getFullName(leaveDetails?.userData)}
                      </Typography>
                      {leaveDetails?.userData?.is_dedicated_developer ?
                        <Chip
                          label="Dedicated"
                          color="secondary"
                          size="small"
                          variant="outlined"
                          sx={{
                            ml: 1,
                          }}
                        /> : null}
                    </Box>
                    <Typography
                      color="secondary"
                      fontSize={12}
                      fontWeight={500}
                      display="block"
                      sx={{ lineHeight: "18px", width: "100%", my: 0.5, }}
                    >
                      {leaveDetails?.userData?.email || ""}
                    </Typography>
                    <Box>
                      <UserRoleChip label={titleCase(leaveDetails?.userData?.user_role) || "-"} />
                    </Box>
                  </Box>
                </Grid>
                <Grid Item>
                  <Stack
                    spacing={1}
                    direction="row"
                    width="100%"
                    justifyContent="flex-end"
                  >
                    {leaveSnapshot?.map((snap, index) => {
                      if (snap?.allow_special_credits) {
                        return null;
                      }
                      else {
                        return (
                          <>
                            {Object.keys(snap)?.length && snap?.allow_special_credits !== false ?
                              <Grid Item p={{ xs: 1, xl: 2 }} sx={{ borderRadius: "8px", minWidth: '110px' }} bgcolor={LEAVE_SNAP_COLORS[index]}>
                                <Typography
                                  variant="caption"
                                  color="secondary"
                                  fontWeight={500}
                                  display="block"
                                  mb={0.5}
                                >
                                  {snap?.leave_type}
                                </Typography>
                                <Typography
                                  fontSize={{ xs: 16, xl: 18 }}
                                  fontWeight={600}
                                  color="secondary"
                                  display="block"
                                >
                                  {snap?.remaining_days ?? 0} Days
                                </Typography>
                              </Grid> : null}
                          </>
                        );
                      }
                    })}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box mb={3}>
              <Stack spacing={2}>
                <Grid container justifyContent="space-between" flexWrap="wrap">
                  <Grid Item overflow="hidden" flex={1}>
                    <div style={{ marginBottom: "7px" }}>
                      <Typography
                        color="dark.800"
                        variant="title"
                        fontSize={16}
                        fontWeight={500}
                        sx={{
                          marginRight: "6px",
                          lineHeight: "24px",
                          letterSpacing: "0.17px",
                          display: "inline-block",
                        }}
                      >
                        {getApplicationNumber(leaveDetails?.application_num)}{' '}{leaveDetails?.leave_type || ""}
                      </Typography>
                      <Typography
                        color="dark.800"
                        fontSize={14}
                        fontWeight={400}
                        sx={{
                          lineHeight: "24px",
                          letterSpacing: "0.17px",
                          display: "inline-block",
                        }}
                      >
                        (Applied on {getApplyDateTime(leaveDetails?.createdAt || leaveDetails?.apply_date)})
                      </Typography>
                    </div>
                    <Grid display="flex" overflow="hidden" whiteSpace="nowrap" sx={{ flexWrap: 'nowrap' }}>
                      {getLeaveStatus(leaveDetails?.status, "chip")}
                      <Typography
                        color="dark.800"
                        fontSize={14}
                        fontWeight={400}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        sx={{
                          display: "inline-block",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                          alignItems: 'center'
                        }}
                      >
                        {leaveDetails?.status === "pending" ? "Waiting for approval..." :
                          leaveDetails?.processed_description ? <Box display={"flex"} gap={"5px"} alignItems={"center"}>
                            <CommentRoundedIcon sx={{ fontSize: '18px' }} />
                            <Typography color={"gray"} overflow="hidden" textOverflow="ellipsis" fontSize={14} fontWeight={400} sx={{ lineHeight: '24px', letterSpacing: '0.15px' }}>{" "}{titleCase(leaveDetails?.processed_description)}</Typography>
                          </Box> : null
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid Item justifyContent="flex-end" minWidth="265px" sx={{ textAlign: 'right' }}>
                    {leaveDetails?.status !== "pending" ? <div style={{ marginBottom: "6px" }}>
                      <Typography
                        color="dark.800"
                        variant="title"
                        fontSize={14}
                        fontWeight={500}
                        sx={{
                          marginRight: "6px",
                          lineHeight: "24px",
                          letterSpacing: "0.17px",
                          display: "inline-block",
                        }}
                      >
                        {leaveDetails?.status === "approved" ? "Approved" : "Rejected"} by:
                      </Typography>
                      <Typography
                        color="dark.800"
                        fontSize={14}
                        fontWeight={400}
                        sx={{
                          lineHeight: "24px",
                          letterSpacing: "0.17px",
                          display: "inline-block",
                        }}
                      >
                        {leaveDetails?.processed_by?.map(user=>user?.name)?.join(",") || "-"}
                      </Typography>
                    </div> : null}
                    <div>
                      <Typography
                        color="dark.800"
                        variant="title"
                        fontSize={16}
                        fontWeight={500}
                        sx={{
                          marginRight: "6px",
                          lineHeight: "24px",
                          letterSpacing: "0.17px",
                          display: "inline-block",
                        }}
                      >
                        Informed to:
                      </Typography>
                      <Typography
                        color="dark.800"
                        fontSize={14}
                        fontWeight={400}
                        sx={{
                          lineHeight: "20px",
                          letterSpacing: "0.17px",
                          display: "inline-block",
                        }}
                      >
                        {showInformationAuthority(leaveDetails?.informed_authorities)}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Box>
                  <TableContainer
                    component={Paper}
                    sx={{ border: "1px solid #E0E0E0", boxShadow: "none" }}
                  >
                    <Table sx={{ minWidth: 560 }} aria-label="simple table">
                      <TableHead sx={{ background: "rgba(0, 0, 0, 0.01)" }}>
                        <TableRow
                          sx={{
                            th: {
                              color: "dark.800",
                              fontWeight: 500,
                              fontSize: 14,
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                              padding: "6px 16px",
                              whiteSpace: "nowrap",
                            },
                          }}
                        >
                          <TableCell>Date</TableCell>
                          <TableCell>Day</TableCell>
                          <TableCell>Duration</TableCell>
                          {leaveDetails?.status === LEAVE_STATUS.Approved ? <TableCell width={160} align="center">
                            <span style={{ width: '38px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>Paid</span>|
                            <span style={{ width: '50px', display: 'inline-block', marginLeft: '4px' }}>Unpaid</span>
                          </TableCell> : null}
                          {leaveDetails?.allow_special_credits ?
                            <>
                              <TableCell width={120}>
                                Match
                                <HtmlTooltip
                                  title={
                                    <React.Fragment>
                                      <Typography color="white" mb="0">
                                        Match
                                      </Typography>
                                      <List
                                        sx={{
                                          padding: 0,
                                          marginLeft: '15px',
                                          li: {
                                            listStyle: "decimal",
                                            display: "list-item",
                                            paddingLeft: '5px',
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            lineHeight: '17px',
                                            paddingBottom: '0px',
                                            marginBottom: '8px'
                                          },
                                          'li:last-child': {
                                            marginBottom: '4px'
                                          }
                                        }}
                                      >
                                        <ListItem>Applying Leave exceeding minimum days required before applying particular leave.</ListItem>
                                        <ListItem>Over lapping max absent allowed for organization rule.</ListItem>
                                        <ListItem>Exceeding max club leave allowed.</ListItem>
                                        <ListItem>Unpaid leaves</ListItem>
                                      </List>
                                    </React.Fragment>
                                  }
                                >
                                  <i
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                      marginLeft: "3px",
                                      color: "#3F5C76",
                                    }}
                                  >
                                    {ICONS.Info}
                                  </i>
                                </HtmlTooltip>
                              </TableCell>
                              <TableCell width={110}>Credits</TableCell>
                            </> : null
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody
                      // sx={{
                      //   "tr:last-child td": {
                      //     border: 0,
                      //   },
                      // }}
                      >
                        {sortArrayByKey(leaveDetails?.leave_days, 'asc', 'leave_date')?.map((leave, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              td: {
                                color: "dark.800",
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                                padding: "7px 16px",
                                border: 0,
                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                              },
                            }}
                          >
                            <TableCell>{getFormattedDate(leave?.leave_date)}</TableCell>
                            <TableCell>{getDayName(getFormattedDate(leave?.leave_date))}</TableCell>
                            <TableCell>{leave?.leave_duration !== "full" ? leave?.leave_duration === "first_half" ? `1st Half (Come at ${moment(leave?.in_time, 'HH:mm:ss').format('h:mm A')})` : `2nd Half (Leave at ${moment(leave?.out_time, 'HH:mm:ss').format('h:mm A')})` : LEAVE_DURATION_TYPE[leave?.leave_duration]}</TableCell>
                            {leaveDetails?.status === LEAVE_STATUS.Approved ? <TableCell width={160} align="center">
                              {leaveDetails?.not_in_current_year ? null : <Grid display="flex" justifyContent="center">
                                <Typography sx={{ width: '50px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>{leave?.paid || 0} |</Typography>
                                <Typography
                                  color="error.main"
                                  fontWeight={700}
                                  sx={{ width: '50px', display: 'inline-block', textAlign: 'left', marginLeft: '4px' }}
                                >
                                  {leave?.unpaid || 0}
                                </Typography>
                              </Grid>}
                            </TableCell> : null}
                            {leaveDetails?.allow_special_credits ? <TableCell width={120}>
                              {leaveDetails?.not_in_current_year ? null : getMatchData(leave)}
                            </TableCell>
                              : null}
                            {leaveDetails?.allow_special_credits ?
                              <TableCell
                                width={110}
                                color="dark.800"
                                fontWeight={400}
                                fontSize={14}
                                lineHeight="24px"
                                letterSpacing="0.15px"
                              >
                                {leaveDetails?.not_in_current_year ? null : leave?.credit_used > 0 ? leave?.credit_used : null}
                              </TableCell> : null}
                          </TableRow>
                        ))}
                        {leaveDetails?.not_in_current_year ? null :
                          <TableRow
                            sx={{
                              td: {
                                padding: "10px 16px",
                                border: 0,
                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                              },
                            }}
                          >
                            <TableCell colSpan={3}></TableCell>
                            {leaveDetails?.status === LEAVE_STATUS.Approved ? <TableCell width={160} align="center">
                              <Grid display="flex" justifyContent="center">
                                <Typography sx={{ width: '50px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>{getApplyDays(leaveDetails?.leave_days)?.paidCount || 0} |</Typography>
                                <Typography
                                  color="error.main"
                                  fontWeight={700}
                                  sx={{ width: '50px', display: 'inline-block', textAlign: 'left', marginLeft: "4px" }}
                                >
                                  {getApplyDays(leaveDetails?.leave_days)?.unPaidCount || 0}
                                </Typography>
                              </Grid>
                            </TableCell> : null}
                            {leaveDetails?.allow_special_credits ?
                              <>
                                <TableCell></TableCell>
                                <TableCell>
                                  <Typography
                                    color="error.main"
                                    fontWeight={600}
                                    fontSize={14}
                                    sx={{ marginLeft: "0px", lineHeight: '24px' }}
                                  >
                                    {getTotalCreditUsedCount(leaveDetails?.leave_days)}{" "}
                                    Credit Applied
                                  </Typography>
                                </TableCell>
                              </> : null}
                          </TableRow>}
                      </TableBody>
                      {leaveDetails?.not_in_current_year ? null :
                        <TableFooter>
                          <TableRow sx={{
                            'td': {
                              padding: 0,
                              borderBottom: 'none',
                            }
                          }}>
                            <TableCell colSpan={3}></TableCell>
                            {leaveDetails?.status === LEAVE_STATUS.Approved ? <TableCell>
                              <Grid
                                Item
                                width={160}
                                sx={{
                                  background: "#DEF3FF",
                                  padding: "10px 16px",
                                  margin: "auto",
                                }}
                              >
                                <Typography
                                  color="dark.800"
                                  variant="body2"
                                  lineHeight="24px"
                                  letterSpacing="0.17px"
                                  fontWeight={600}
                                >
                                  Remaining {leaveDetails?.leave_type?.split(" ")?.map(word => word[0])?.join("")}:{" "}
                                  {leaveDetails?.remaining_days || 0}
                                </Typography>
                              </Grid>
                            </TableCell> : null}
                            <TableCell></TableCell>
                            {leaveDetails?.allow_special_credits ?
                              <TableCell>
                                <Grid
                                  Item
                                  width={200}
                                  sx={{ background: "#FFF6DF", padding: "10px 16px" }}
                                >
                                  <Typography
                                    color="dark.800"
                                    variant="body2"
                                    lineHeight="24px"
                                    letterSpacing="0.17px"
                                    fontWeight={600}
                                  >
                                    Remaining Credits: {leaveDetails?.remaining_credits || 0}
                                  </Typography>
                                </Grid>
                              </TableCell> : null}
                          </TableRow>
                        </TableFooter>}
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>
            </Box>
            <Box mb={2}>
              <Typography
                variant="body1"
                fontWeight={500}
                sx={{ marginBottom: "4px", letterSpacing: "0.17px" }}
              >
                Reason:
              </Typography>
              {!!leaveDetails?.description && leaveDetails?.description !== "<p><br></p>" ?
                <div
                  style={{
                    padding: "16px",
                    borderRadius: "3px",
                    background: "#F7F7F7",
                  }}
                  className="reason-text-view"
                >
                  <TextEditor
                    value={leaveDetails?.description}
                    readOnly={true}
                    onChange={() => { }}
                  />
                </div> :
                <EmptyPage height={92} isButtonShow={false} title={"No Reason"} />
              }
            </Box>
            <Box mb={2}>
              <Typography
                variant="body1"
                fontWeight={500}
                sx={{ marginBottom: "4px", letterSpacing: "0.17px" }}
              >
                Attachments:
              </Typography>
              {!!leaveDetails?.attachment_references?.length ?
                <div
                  style={{
                    padding: "16px",
                    borderRadius: "3px",
                    background: "#F7F7F7",
                  }}
                >
                  <FileDropZone
                    readOnly={true}
                    fileURLs={leaveDetails?.attachment_references}
                  />
                </div> :
                <EmptyPage height={92} isButtonShow={false} title={"No Attachments"} />
              }
            </Box>
            <Box mt={2}>
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                {leaveDetails?.status === LEAVE_STATUS.Pending && isShowButton(leaveDetails) ?
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                      onClick={() => handleOpen(LEAVE_STATUS.Approved)}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="medium"
                      sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                      onClick={() => handleOpen(LEAVE_STATUS.Rejected)}
                    >
                      Reject
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="medium"
                      sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                      onClick={() => handleOpen(LEAVE_STATUS.Cancelled)}
                    >
                      Cancel Leave
                    </Button>
                  </>
                  : null
                }
                {([LEAVE_STATUS.Approved, LEAVE_STATUS.Rejected]?.includes(leaveDetails?.status) && isShowButton(leaveDetails)) ?
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                    onClick={() => handleOpen(LEAVE_STATUS.Cancelled)}
                  >
                    Cancel Leave
                  </Button>
                  : null}
              </Stack>
            </Box>
            <CustomModal
              actionType={modalDetails.type}
              title={modalDetails.type === LEAVE_STATUS.Approved ? "Confirm Approval" : modalDetails.type === LEAVE_STATUS.Rejected ? "Confirm Rejection" : modalDetails.type === LEAVE_STATUS.Cancelled ? "Confirm Cancellation" : ""}
              leaveType={leaveDetails?.leave_type}
              // date={getLeaveDateArray(leaveDetails?.leave_days || [], 'leave_date')?.map((date) => moment(date, 'DD/MM/YYYY').format("Do MMMM (dddd)"))}
              date={getLeaveDateArray(leaveDetails?.leave_days || [], 'leave_date')?.map((date) => moment(date).format("Do MMMM (dddd)"))}
              userData={{
                profileImage: leaveDetails?.userData?.profile_img,
                userName: getFullName(leaveDetails?.userData),
                role: titleCase(leaveDetails?.userData?.user_role),
                is_dedicated_developer: leaveDetails?.userData?.is_dedicated_developer,
              }}
              applyDays={getApplyDays(leaveDetails?.leave_days)?.totalCount}
              comment={modalDetails?.comment}
              commentError={modalDetails?.error}
              handleComment={(e) => {
                setModalDetails((prev) => ({ ...prev, comment: e.target.value, error: e.target.value ? "" : "Required" }))
              }}
              onApprove={handleApprove}
              onCancel={handleCancel}
              isButtonLoading={isLoading?.modalButtonLoading}
              submitButtonContent={modalDetails.type === LEAVE_STATUS.Cancelled ? 'Cancel Leave' : ''}
            />
          </Box>
        </Grid>
  )
}

export default OthersLeaveDetails