/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    Grid,
    List,
    ListItem,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { styled } from "@mui/styles";
import moment from "moment";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../../axios";
import API from "../../../../../axios/api";
import { LEAVE_DURATION_TYPE, LEAVE_STATUS } from "../../../../../constants/default-values";
import CircularLoader from "../../../../../components/CircularLoader";
import ICONS from "../../../../../constants/icons";
import { getApplicationNumber, getApplyDateTime, getDayName, getFormattedDate, getLeaveDateArray, getLeaveStatus, showInformationAuthority, sortArrayByKey, titleCase } from "../../../../../utils";
import TextEditor from "../../../../../components/TextEditor";
import URLS from "../../../../../routes/urls";
import EmptyPage from "../../../../../components/EmptyPage";
import { usePageTitle } from "../../../../../hook/useTitle";
import useAuthentication from "../../../../../hook/useAuthentication";
import CustomModal from "../../../../../components/CustomModal";
import { useAlert } from "../../../../../hook/useAlert";
import Breadcrumb from "../../../../../components/Breadcrumb";
import FileDropZone from "../../../../../components/FileDropZone";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: "16px",
        borderRadius: "5px",
        background: "#3F5C76",
    },
}));

const initialModalDetails = {
    isShow: false,
    type: null,
    comment: "",
    leaveDetails: null,
    isLoading: false,
};

const ViewMyLeaveApplication = () => {
    const navigate = useNavigate();
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const params = useParams();
    const showAlert = useAlert();
    const { setPageTitle } = usePageTitle();
    const [leaveDetails, setLeaveDetails] = useState(null);
    const [isLoading, setIsLoading] = useState({
        leaveDetails: true,
    });
    const [leaveSnapshot, setLeaveSnapshot] = useState(null);
    const [modalDetails, setModalDetails] = useState(initialModalDetails);

    setPageTitle("View Leave");

    useEffect(() => {
        if (Number(currentUser?.id) === Number(params?.userId)) {
            getUserLeaveBalance();
            getUserSettingData(params?.userId, params?.appId);
        } else {
            navigate(URLS.AccessDenied)
        }
    }, [params])

    const getTotalCreditUsedCount = (leave_array) => {
        let totalCreditUsed = 0;
        leave_array?.forEach((leave) => {
            totalCreditUsed += leave?.credit_used;
        });
        return totalCreditUsed || 0;
    };

    const handelEditLeave = () => {
        navigate(`${URLS.EditMyLeaveApplication}/${params?.userId}/${params?.appId}`)
    }

    const getApplyDays = (leave_days) => {
        let count = leave_days?.reduce((previousValue, currentValue, currentIndex, array) => {
            let paidCount = currentValue?.paid;
            let unPaidCount = currentValue?.unpaid;
            previousValue.paidCount = previousValue.paidCount + paidCount;
            previousValue.unPaidCount = previousValue.unPaidCount + unPaidCount;
            previousValue.totalCount = previousValue.paidCount + previousValue.unPaidCount
            return previousValue;
        }, { paidCount: 0, unPaidCount: 0, totalCount: 0, });
        return count;
    }
    const handleBackClick = () => {
        navigate(-1);
    }

    const getUserSettingData = async (user_id, id) => {
        try {
            const response = await axiosInstance.get(API.getLeaveById(user_id, id));
            if (response.status === 200) {
                setLeaveDetails(response?.data?.result || {})
                setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
            } else {
                setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
            }
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
        }
    };

    const getUserLeaveBalance = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, spanLoading: true }))
            const response = await axiosInstance.get(API.getLeaveBalance(currentUser?.id));
            if (response.status === 200) {
                setLeaveSnapshot(response?.data?.result);
                setIsLoading((prev) => ({ ...prev, spanLoading: false }))
            } else {
                setIsLoading((prev) => ({ ...prev, spanLoading: false }))
            }
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, spanLoading: false }))
        }
    };

    const getRemainingCreditsData = () => {
        return leaveSnapshot?.find((data) => Object.keys(data)?.includes('specialCredits'))
    }

    const getMatchData = (leaveDetail) => {
        let usedSpecialRequest = [...leaveDetail?.specialRequest];
        const isExistRule = (creditListData) => {
            return usedSpecialRequest?.includes(creditListData?.id)
        }
        return (
            <>
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="white" mb="0">
                                {/* {leaveDetail?.match === 'fully' ? 'Match' : leaveDetail?.match === "true" ?
                                    `${leaveDetail?.specialRequest?.length} credit will be used` : 'Request Status'} */}
                                Request Status
                            </Typography>
                            <List
                                sx={{
                                    padding: 0,
                                    // marginLeft: '25px',
                                    li: {
                                        listStyle: "decimal",
                                        display: "list-item",
                                        paddingLeft: '5px',
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        lineHeight: '17px',
                                        paddingBottom: '0px',
                                        marginBottom: '0px'
                                    },
                                    'li:last-child': {
                                        marginBottom: '0px'
                                    }
                                }}
                            >
                                {leaveDetail?.match === 'fully' ?
                                    getRemainingCreditsData()?.specialCreditsList?.length ?
                                        getRemainingCreditsData()?.specialCreditsList?.filter(data => data?.leave_credit)?.map((creditListData) => (
                                            <ListItem sx={{ listStyle: 'none !important', paddingLeft: '0 !important', 'svg': { display: 'block', } }}>
                                                <i style={{ background: '#ffffff', height: '18px', width: '18px', borderRadius: '50%', marginRight: '10px', display: 'inline-block', verticalAlign: 'Top' }}>
                                                    {ICONS.CheckIcon}
                                                </i>{titleCase(creditListData?.name)}</ListItem>
                                        )) : null : getRemainingCreditsData()?.specialCreditsList?.filter(data => data?.leave_credit)?.map((creditListData) => (
                                            <ListItem sx={{ listStyle: 'none !important', paddingLeft: '0 !important', 'svg': { display: 'block', } }}>
                                                <i style={{ background: '#ffffff', height: '18px', width: '18px', borderRadius: '50%', marginRight: '10px', display: 'inline-block', verticalAlign: 'Top' }}>
                                                    {isExistRule(creditListData) ? ICONS.CrossIcon : ICONS.CheckIcon}
                                                </i>{titleCase(creditListData?.name)}</ListItem>
                                        ))}
                            </List>
                        </React.Fragment>
                    }
                >
                    <i
                        style={{
                            height: 20,
                            width: 20,
                            display: "inline-block",
                            verticalAlign: "middle",
                        }}
                    >
                        {leaveDetail?.match === 'fully' ? <CheckCircleRoundedIcon
                            color="primary"
                            sx={{ fontSize: 20 }}
                        /> : <ErrorOutlineRoundedIcon
                            color={leaveDetail?.match === "true" ? "primary" : "error"}
                            sx={{ fontSize: 20 }}
                        />}
                    </i>
                </HtmlTooltip>
            </>
        )
    }

    const handleCancelLeave = () => {
        handleCancelLeaveApplication('cancelled', modalDetails?.comment);
    }

    const handleCancelLeaveApplication = async (status, comment) => {
        try {
            if (comment?.trim()?.length) {
                setModalDetails((prev) => ({ ...prev, isLoading: true }));
                let payload = {
                    "processed_description": comment,
                    "status": modalDetails?.leaveDetails?.status,
                    "action": status
                };

                const response = await axiosInstance.put(
                    API.updateLeaveStatus(currentUser?.id, modalDetails?.leaveDetails?.id),
                    payload,
                );
                if (response.status === 200) {
                    showAlert(response?.data.message);
                    setModalDetails(initialModalDetails);
                    handleCloseModal();
                    navigate(`${URLS.Leaves}?view=list&tabValue=1&groupBy=Status&status=active`);
                }
                else {
                    showAlert(response?.response?.data.message, "error");
                }
                setModalDetails((prev) => ({ ...prev, isLoading: false }));
            } else {
                setModalDetails((prev) => ({ ...prev, error: "Required" }));
            }
        } catch (error) {
            showAlert(error?.response?.data.message);
            setModalDetails(initialModalDetails);
            setModalDetails((prev) => ({ ...prev, isLoading: false }));
        }
    };

    const handleDeleteLeave = async () => {
        try {
            setModalDetails((prev) => ({ ...prev, isLoading: true }));
            const formData = new FormData();
            formData.append("is_deleted", true);

            const response = await axiosInstance.delete(
                API.deleteLeaveById(currentUser?.id, modalDetails?.leaveDetails?.id),
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (response.status === 200) {
                showAlert(response?.data.message);
                setModalDetails(initialModalDetails);
                setModalDetails((prev) => ({ ...prev, isLoading: false }));
                navigate(`${URLS.Leaves}?view=list&tabValue=1&groupBy=Status&status=active`);
            } else {
                showAlert(response?.response?.data.message);
                setModalDetails(initialModalDetails);
                setModalDetails((prev) => ({ ...prev, isLoading: false }));
            }
        } catch (error) {
            console.error(error)
            showAlert(error?.response?.data.message);
            setModalDetails(initialModalDetails);
            setModalDetails((prev) => ({ ...prev, isLoading: false }));
        }
    }

    const handleCancelLeaveModal = (leave) => {
        setModalDetails({
            isShow: true,
            type: LEAVE_STATUS.Cancelled,
            leaveDetails: leave
        });
    };

    const handleCloseModal = () => {
        setModalDetails(initialModalDetails);
    }

    const checkApplicationEnable = (leaveData) => {
        const minLeaveDate = leaveData?.leave_days.reduce((min, leave) => {
            const leaveDate = new Date(leave.leave_date);
            return leaveDate < min ? leaveDate : min;
        }, new Date(leaveData?.leave_days[0].leave_date));

        const today = new Date();
        // today.setHours(0, 0, 0, 0);

        const result = today <= minLeaveDate;
        return result;
    }

    return (
        isLoading?.leaveDetails ?
            <CircularLoader height="600px" /> :
            <>{
                !leaveDetails ?
                    <EmptyPage
                        title="No leaves application available"
                        buttonStyle={{ color: "white" }}
                        buttonColor="secondary"
                        onClick={() => navigate(-1)}
                    />
                    :
                    <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
                        <Breadcrumb isBack={true} title={"My Leaves"}
                            pageTitle={'Leave Detail'}
                            isPathShow={false}
                            onBackClick={() => handleBackClick()} />
                        <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} width="100%" overflow="auto">
                            <Box mb={3}>
                                <Stack spacing={2}>
                                    <Grid container justifyContent="space-between" flexWrap="wrap">
                                        <Grid Item overflow="hidden" flex={1}>
                                            <div style={{ marginBottom: "7px" }}>
                                                <Typography
                                                    color="dark.800"
                                                    variant="title"
                                                    fontSize={16}
                                                    fontWeight={500}
                                                    sx={{
                                                        marginRight: "6px",
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.17px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    {getApplicationNumber(leaveDetails?.application_num)}{' '}{titleCase(leaveDetails?.leave_type) || ""}
                                                </Typography>
                                                <Typography
                                                    color="dark.800"
                                                    fontSize={14}
                                                    fontWeight={400}
                                                    sx={{
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.17px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    (Applied on {getApplyDateTime(leaveDetails?.createdAt || leaveDetails?.apply_date)})
                                                </Typography>
                                            </div>
                                            <Grid display="flex" overflow="hidden" whiteSpace="nowrap" sx={{ flexWrap: 'nowrap' }}>
                                                {getLeaveStatus(leaveDetails?.status, "chip")}
                                                <Typography
                                                    color="dark.800"
                                                    fontSize={14}
                                                    fontWeight={400}
                                                    whiteSpace="nowrap"
                                                    overflow="hidden"
                                                    sx={{
                                                        display: "inline-block",
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.15px",
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {leaveDetails?.status === "pending" ? "Waiting for approval..." :
                                                        leaveDetails?.processed_description ? <Box display={"flex"} gap={"5px"} alignItems={"center"}>
                                                            <CommentRoundedIcon sx={{ fontSize: '18px' }} />
                                                            <Typography color={"gray"} overflow="hidden" textOverflow="ellipsis" fontSize={14} fontWeight={400} sx={{ lineHeight: '24px', letterSpacing: '0.15px' }}>{" "}{titleCase(leaveDetails?.processed_description)}</Typography>
                                                        </Box> : null
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid Item justifyContent="flex-end" minWidth="265px" sx={{ textAlign: 'right' }}>
                                            {leaveDetails?.status !== "pending" ? <div style={{ marginBottom: "6px" }}>
                                                <Typography
                                                    color="dark.800"
                                                    variant="title"
                                                    fontSize={14}
                                                    fontWeight={500}
                                                    sx={{
                                                        marginRight: "6px",
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.17px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    {leaveDetails?.status === "approved" ? "Approved" : "Rejected"} by:
                                                </Typography>
                                                <Typography
                                                    color="dark.800"
                                                    fontSize={14}
                                                    fontWeight={400}
                                                    sx={{
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.17px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    {leaveDetails?.processed_by?.map(user => user?.name)?.join(",") || "-"}
                                                </Typography>
                                            </div> : null}
                                            <div>
                                                <Typography
                                                    color="dark.800"
                                                    variant="title"
                                                    fontSize={16}
                                                    fontWeight={500}
                                                    sx={{
                                                        marginRight: "6px",
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.17px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    Informed to:
                                                </Typography>
                                                <Typography
                                                    color="dark.800"
                                                    fontSize={14}
                                                    fontWeight={400}
                                                    sx={{
                                                        lineHeight: "20px",
                                                        letterSpacing: "0.17px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    {showInformationAuthority(leaveDetails?.informed_authorities)}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Box>
                                        <TableContainer
                                            component={Paper}
                                            sx={{ border: "1px solid #E0E0E0", boxShadow: "none" }}
                                        >
                                            <Table sx={{ minWidth: 560 }} aria-label="simple table">
                                                <TableHead sx={{ background: "rgba(0, 0, 0, 0.01)" }}>
                                                    <TableRow
                                                        sx={{
                                                            th: {
                                                                color: "dark.800",
                                                                fontWeight: 500,
                                                                fontSize: 14,
                                                                lineHeight: "24px",
                                                                letterSpacing: "0.15px",
                                                                padding: "6px 16px",
                                                                whiteSpace: "nowrap",
                                                            },
                                                        }}
                                                    >
                                                        <TableCell>Date</TableCell>
                                                        <TableCell>Day</TableCell>
                                                        <TableCell>Duration</TableCell>
                                                        {leaveDetails?.status !== LEAVE_STATUS.Approved ? null : <TableCell width={160} align="center">
                                                            <span style={{ width: '38px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>Paid</span>|
                                                            <span style={{ width: '50px', display: 'inline-block', marginLeft: '4px' }}>Unpaid</span>
                                                        </TableCell>}
                                                        {leaveDetails?.allow_special_credits ? <>
                                                            <TableCell width={120}>
                                                                Match
                                                                <HtmlTooltip
                                                                    title={
                                                                        <React.Fragment>
                                                                            <Typography fontSize={16} fontWeight={500} color="white" mb={1} lineHeight={"24px"} letterSpacing={"0.17px"}>
                                                                                Match
                                                                            </Typography>
                                                                            <Typography fontSize={12} fontWeight={500} color="white" lineHeight={"18px"} maxWidth={286}>
                                                                                When submitting your request, please be aware of the conditions that might use your credits for different situation.
                                                                            </Typography>
                                                                        </React.Fragment>
                                                                    }
                                                                >
                                                                    <i
                                                                        style={{
                                                                            width: "18px",
                                                                            height: "18px",
                                                                            display: "inline-block",
                                                                            verticalAlign: "middle",
                                                                            marginLeft: "3px",
                                                                            color: "#3F5C76",
                                                                        }}
                                                                    >
                                                                        {ICONS.Info}
                                                                    </i>
                                                                </HtmlTooltip>
                                                            </TableCell>
                                                            <TableCell width={110}>Credit</TableCell>
                                                        </> : null}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody
                                                // sx={{
                                                //   "tr:last-child td": {
                                                //     border: 0,
                                                //   },
                                                // }}
                                                >
                                                    {sortArrayByKey(leaveDetails?.leave_days, 'asc', 'leave_date')?.map((leave, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{
                                                                td: {
                                                                    color: "dark.800",
                                                                    fontWeight: 400,
                                                                    fontSize: 14,
                                                                    lineHeight: "24px",
                                                                    letterSpacing: "0.15px",
                                                                    padding: "7px 16px",
                                                                    border: 0,
                                                                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                                                },
                                                            }}
                                                        >
                                                            <TableCell>{getFormattedDate(leave?.leave_date)}</TableCell>
                                                            <TableCell>{getDayName(getFormattedDate(leave?.leave_date))}</TableCell>
                                                            <TableCell>{leave?.leave_duration !== "full" ? leave?.leave_duration === "first_half" ? `1st Half (Come at ${moment(leave?.in_time, 'HH:mm:ss').format('h:mm A')})` : `2nd Half (Leave at ${moment(leave?.out_time, 'HH:mm:ss').format('h:mm A')})` : LEAVE_DURATION_TYPE[leave?.leave_duration]}</TableCell>
                                                            {leaveDetails?.status !== LEAVE_STATUS.Approved ? null : <TableCell width={160} align="center">
                                                                {leaveDetails?.not_in_current_year ? null :
                                                                    <Grid display="flex" justifyContent="center">
                                                                        <Typography sx={{ width: '50px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>{leave?.paid || 0} |</Typography>
                                                                        <Typography
                                                                            color="error.main"
                                                                            fontWeight={700}
                                                                            sx={{ width: '50px', display: 'inline-block', textAlign: 'left', marginLeft: '4px' }}
                                                                        >
                                                                            {leave?.unpaid || 0}
                                                                        </Typography>
                                                                    </Grid>}
                                                            </TableCell>}
                                                            {leaveDetails?.allow_special_credits ? <TableCell width={120}>
                                                                {leaveDetails?.not_in_current_year ? null : getMatchData(leave)}
                                                            </TableCell>
                                                                : null}
                                                            {leaveDetails?.allow_special_credits ?
                                                                <TableCell
                                                                    width={110}
                                                                    color="dark.800"
                                                                    fontWeight={400}
                                                                    fontSize={14}
                                                                    lineHeight="24px"
                                                                    letterSpacing="0.15px"
                                                                >
                                                                    {leaveDetails?.not_in_current_year ? null : leave?.credit_used > 0 ? leave?.credit_used : null}
                                                                </TableCell>
                                                                : null}
                                                        </TableRow>
                                                    ))}
                                                    {leaveDetails?.not_in_current_year ? null :
                                                        <TableRow
                                                            sx={{
                                                                td: {
                                                                    padding: "10px 16px",
                                                                    border: 0,
                                                                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                                                },
                                                            }}
                                                        >
                                                            <TableCell colSpan={3}></TableCell>
                                                            {leaveDetails?.status !== LEAVE_STATUS.Approved ? null : <TableCell width={160} align="center">
                                                                <Grid display="flex" justifyContent="center">
                                                                    <Typography sx={{ width: '50px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>{getApplyDays(leaveDetails?.leave_days)?.paidCount || 0} |</Typography>
                                                                    <Typography
                                                                        color="error.main"
                                                                        fontWeight={700}
                                                                        sx={{ width: '50px', display: 'inline-block', textAlign: 'left', marginLeft: "4px" }}
                                                                    >
                                                                        {getApplyDays(leaveDetails?.leave_days)?.unPaidCount || 0}
                                                                    </Typography>
                                                                </Grid>
                                                            </TableCell>}
                                                            {leaveDetails?.allow_special_credits ?
                                                                <>
                                                                    <TableCell></TableCell>
                                                                    <TableCell>
                                                                        <Typography
                                                                            color="error.main"
                                                                            fontWeight={600}
                                                                            fontSize={14}
                                                                            sx={{ marginLeft: "0px", lineHeight: '24px' }}
                                                                        >
                                                                            {getTotalCreditUsedCount(leaveDetails?.leave_days)}{" "}
                                                                            Credit Applied
                                                                        </Typography>
                                                                    </TableCell>
                                                                </> : null}
                                                        </TableRow>}
                                                </TableBody>
                                                {leaveDetails?.not_in_current_year ? null :
                                                    <TableFooter>
                                                        <TableRow sx={{
                                                            'td': {
                                                                padding: 0,
                                                                borderBottom: 'none',
                                                            }
                                                        }}>
                                                            <TableCell colSpan={3}></TableCell>
                                                            {leaveDetails?.status !== LEAVE_STATUS.Approved ? null : <TableCell>
                                                                <Grid
                                                                    Item
                                                                    width={160}
                                                                    sx={{
                                                                        background: "#DEF3FF",
                                                                        padding: "10px 16px",
                                                                        margin: "auto",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        color="dark.800"
                                                                        variant="body2"
                                                                        lineHeight="24px"
                                                                        letterSpacing="0.17px"
                                                                        fontWeight={600}
                                                                    >
                                                                        Remaining {leaveDetails?.leave_type?.split(" ")?.map(word => word[0])?.join("")}:{" "}
                                                                        {leaveDetails?.remaining_days || 0}
                                                                    </Typography>
                                                                </Grid>
                                                            </TableCell>}
                                                            {leaveDetails?.allow_special_credits ? <>
                                                                <TableCell></TableCell>
                                                                <TableCell>
                                                                    <Grid
                                                                        Item
                                                                        width={200}
                                                                        sx={{ background: "#FFF6DF", padding: "10px 16px" }}
                                                                    >
                                                                        <Typography
                                                                            color="dark.800"
                                                                            variant="body2"
                                                                            lineHeight="24px"
                                                                            letterSpacing="0.17px"
                                                                            fontWeight={600}
                                                                        >
                                                                            Remaining Credits: {leaveDetails?.remaining_credits || 0}
                                                                        </Typography>
                                                                    </Grid>
                                                                </TableCell>
                                                            </> : null}
                                                        </TableRow>
                                                    </TableFooter>}
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Stack>
                            </Box>
                            <Box mb={2}>
                                <Typography
                                    variant="body1"
                                    fontWeight={500}
                                    sx={{ marginBottom: "4px", letterSpacing: "0.17px" }}
                                >
                                    Reason:
                                </Typography>
                                {!!leaveDetails?.description && leaveDetails?.description !== "<p><br></p>" ?
                                    <div
                                        style={{
                                            padding: "16px",
                                            borderRadius: "3px",
                                            background: "#F7F7F7",
                                        }}
                                        className="reason-text-view"
                                    >
                                        <TextEditor
                                            value={leaveDetails?.description}
                                            readOnly={true}
                                            onChange={() => { }}
                                        />
                                    </div> :
                                    <EmptyPage height={92} isButtonShow={false} title={"No Reason"} />
                                }
                            </Box>
                            <Box mb={2}>
                                <Typography
                                    variant="body1"
                                    fontWeight={500}
                                    sx={{ marginBottom: "4px", letterSpacing: "0.17px" }}
                                >
                                    Attachments:
                                </Typography>
                                {!!leaveDetails?.attachment_references?.length ?
                                    <div
                                        style={{
                                            padding: "16px",
                                            borderRadius: "3px",
                                            background: "#F7F7F7",
                                        }}
                                    >
                                        <FileDropZone
                                            readOnly={true}
                                            fileURLs={leaveDetails?.attachment_references}
                                        />
                                    </div> :
                                    <EmptyPage height={92} isButtonShow={false} title={"No Attachments"} />
                                }
                            </Box>
                            <Box mt={2}>
                                <Stack
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    {leaveDetails?.status === LEAVE_STATUS.Pending && checkApplicationEnable(leaveDetails) ?
                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                                onClick={handelEditLeave}
                                                disabled={!leaveDetails?.canDelete}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="medium"
                                                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                                onClick={() => handleCancelLeaveModal(leaveDetails)}
                                            >
                                                Cancel Leave
                                            </Button>
                                            {/* <Button
                                                variant="contained"
                                                size="medium"
                                                sx={{
                                                    bgcolor: 'text.primary', color: "white", textTransform: "none", boxShadow: 'none !important',
                                                    '&:hover': {
                                                        bgcolor: '#000000',
                                                    }
                                                }}
                                                onClick={() => handleDeleteLeaveModal(leaveDetails)}
                                            >
                                                Delete Leave
                                            </Button> */}
                                        </>
                                        : null
                                    }
                                    {([LEAVE_STATUS.Approved]?.includes(leaveDetails?.status) && checkApplicationEnable(leaveDetails)) ?
                                        <>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="medium"
                                                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                                onClick={() => handleCancelLeaveModal(leaveDetails)}
                                            >
                                                Cancel Leave
                                            </Button>
                                            {/* <Button
                                                variant="contained"
                                                size="medium"
                                                sx={{
                                                    bgcolor: 'text.primary', color: "white", textTransform: "none", boxShadow: 'none !important',
                                                    '&:hover': {
                                                        bgcolor: '#000000',
                                                    }
                                                }}
                                                onClick={() => handleDeleteLeaveModal(leaveDetails)}
                                            >
                                                Delete Leave
                                            </Button> */}
                                        </>
                                        : null}
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
            }

                {modalDetails?.type === LEAVE_STATUS.Cancelled ? <CustomModal
                    actionType={modalDetails?.type}
                    title={"Confirm Cancellation"}
                    leaveType={modalDetails?.leaveDetails?.leave_type}
                    date={getLeaveDateArray(modalDetails?.leaveDetails?.leave_days || [], 'leave_date')?.map((date) => moment(date, 'DD/MM/YYYY').format("Do MMMM (dddd)"))}
                    userData={{
                        profileImage: currentUser?.profile_img,
                        role: titleCase(currentUser?.user_role),
                        is_dedicated_developer: currentUser?.User?.is_dedicated_developer,
                        ...currentUser,
                    }}
                    applyDays={getApplyDays(modalDetails?.leaveDetails?.leave_days)?.totalCount ?? 0}
                    comment={modalDetails?.comment}
                    commentError={modalDetails?.error}
                    handleComment={(e) => {
                        setModalDetails((prev) => ({ ...prev, comment: e.target.value, error: e.target.value ? "" : "Required" }))
                    }}
                    onApprove={handleCancelLeave}
                    onCancel={handleCloseModal}
                    isButtonLoading={modalDetails?.isLoading}
                    submitButtonContent={'Cancel Leave'}
                /> : null}

                {modalDetails?.type === LEAVE_STATUS.Delete ? <CustomModal
                    actionType={modalDetails?.type}
                    title={"Confirm Deletion"}
                    leaveType={modalDetails?.leaveDetails?.leave_type}
                    date={getLeaveDateArray(modalDetails?.leaveDetails?.leave_days || [], 'leave_date')?.map((date) => moment(date, 'DD/MM/YYYY').format("Do MMMM (dddd)"))}
                    userData={{
                        profileImage: currentUser?.profile_img,
                        role: titleCase(currentUser?.user_role),
                        is_dedicated_developer: currentUser?.User?.is_dedicated_developer,
                        ...currentUser,
                    }}
                    applyDays={getApplyDays(modalDetails?.leaveDetails?.leave_days)?.totalCount ?? 0}
                    comment={modalDetails?.comment}
                    commentError={modalDetails?.error}
                    handleComment={(e) => {
                        setModalDetails((prev) => ({ ...prev, comment: e.target.value, error: e.target.value ? "" : "Required" }))
                    }}
                    onApprove={handleDeleteLeave}
                    onCancel={handleCloseModal}
                    isButtonLoading={modalDetails?.isLoading}
                    submitButtonContent={'Delete Leave'}
                /> : null}
            </>
    )
}

export default ViewMyLeaveApplication;