const URLS = {
  Login: "/login",
  AccessDenied: "/access-denied",
  TermsAndConditions: "/terms-and-conditions",
  PrivacyPolicy: "/privacy-policy",
  CAPrivacyNotice: "/ca-privacy-notice",
  AdminDashboard: "/admin/dashboard",
  AdminProfile: "/admin/profile",
  AddOrganisation: "/admin/add-organisation",
  EditOrganisation: "/admin/edit-organisation",
  ForgotPassword: "/forgot-password",
  ResetPassword: "/reset-password/:reset_password_token",
  onBoarding: "/on-borading",
  InCompleteOnBoarding: "/incomplete-onboard-process",
  Dashboard: "/dashboard",
  Projects: "/projects",
  Leaves: "/request/leaves",
  ApplyLeave: "/request/leaves/leave/apply",
  OtherLeaveDetails: "/request/leaves/other-leave",
  ViewMyLeaveApplication: "/request/leaves/my-leave/view",
  EditMyLeaveApplication: "/request/leaves/my-leave/edit",
  OtherWfhApplicationDetails: "/request/work-from-home/other-wfh-application",
  ViewMyWfhApplication: "/request/work-from-home/my-wfh-application/view",
  EditMyWfhApplication: "/request/work-from-home/my-wfh-application/edit",
  WorkFromHome: "/request/work-from-home",
  ApplyWorkFromHome: "/request/work-from-home/apply",
  Inventory: "/request/inventory",
  Users: "/users",
  Reports: "/reports",
  InOutReport: "/report/in-out-report",
  UserDetails: "/users/user-details",
  CreateUser: "/users/create-user",
  Holidays: "/holidays",
  Profile: "/profile",
  ChangePassword: "/profile/change-password",
  AdministratorOrganisation: "/settings/organisation",
  AdministratorLeaves: "/settings/leaves",
  AdministratorHolidays: "/settings/holidays",
  AdministratorHolidaysCreate: "/settings/holidays/create",
  AdministratorRoles: "/settings/roles",
  // AdministratorProjectRoles: "/settings/project-roles",
  AdministratorActivity: "/settings/activity",
  AdministratorCreateActivity: "/settings/activity/create",
  AdministratorUpdateActivity: "/settings/activity/update",
  AdministratorYears: "/settings/years",
  AllNotifications: "/all-notifications",
  Repotees: '/repotees'
};

export default URLS;
