import React from "react";
import Box from "@mui/material/Box";
import { Tab, Tabs, Typography } from "@mui/material";
import MyLeaves from "./MyLeaves";
import OthersLeave from "./OthersLeave";
import useQuery from "../../../../hook/useQuery";
import { GROUP_FILTER, OTHERS_LEAVE_FILTER } from "../../../../constants/default-values";
import { usePageTitle } from "../../../../hook/useTitle";
import LeaveReport from "./LeaveReport";
import secureLocalStorage from "react-secure-storage";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Leaves = () => {
  let { getQueryParams, setQueryParams, deleteQueryParams } = useQuery();
  const { setPageTitle } = usePageTitle();
  setPageTitle("Leave");
  let initialTabValue = parseInt(getQueryParams("tabValue")) || 0;
  let isReportee = secureLocalStorage.getItem("isReportee");

  const handleChange = (event, newValue) => {
    setQueryParams("view", "list");
    setQueryParams("tabValue", newValue);
    setQueryParams("groupBy", Object.values(GROUP_FILTER)[0]);
    setQueryParams("status", Object.keys(OTHERS_LEAVE_FILTER)[0]);
    deleteQueryParams("filterStatus");
    if (newValue === 2) {
      deleteQueryParams("groupBy");
      deleteQueryParams("status");
    }
  };

  return (
    <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
      <Box sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: 'white', position: 'sticky', top: 0, zIndex: 5 }}>
        <Tabs
          value={initialTabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Others’ Leaves" {...a11yProps(0)} />
          <Tab label="My Leaves" {...a11yProps(1)} />
          {isReportee ? <Tab label="Leave Report" {...a11yProps(2)} /> : null}
        </Tabs>
      </Box>
      <Box bgcolor="secondary"
        flex={1}
        overflow="hidden"
        sx={{
          "> div": {
            height: '100%',
          },

          ".tabspanel > .MuiBox-root": {
            padding: "0px",
            height: '100%',
            "> p": {
              height: '100%',
            }
          }
        }}
      >
        <CustomTabPanel value={initialTabValue} index={0} className="tabspanel">
          <OthersLeave />
        </CustomTabPanel>
        <CustomTabPanel value={initialTabValue} index={1} className="tabspanel">
          <MyLeaves />
        </CustomTabPanel>
        <CustomTabPanel value={initialTabValue} index={2} className="tabspanel">
          <LeaveReport />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default Leaves;
